@import "./src/styles/variables.scss";

.verads {
  &.control {
    display: flex;
    border-radius: 16px;
    padding: 24px;
    flex-direction: column;
    background-color: var(--color-200);
    width: 100%;
    height: 100%;

    @media (max-width: $mobileSmall) {
      padding: 16px;
    }
  }

  &__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    color: var(--color-main);
    font-size: 20px;
    line-height: 23px;

    @media (max-width: $mobileSmall) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  &__photo {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;

    @media (max-width: $mobileExtraSmall) {
      flex-direction: column;
    }
  }

  &__examplePhoto {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 12px;
    align-items: center;
    justify-content: center;
    background-color: var(--color-200);
    border-radius: 24px;
    height: 200px;
  }

  &__secure {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin-top: 30px;
  }

  &__ten {
    font-size: 22px;
    line-height: 130%;
    display: block;
    color: var(--color-main-reverse);
    font-family: "TT Ramillas";
    font-weight: 200;
  }
}

.test {
  background-color: var(--color-200);
  width: 100%;
  height: 100%;
  border-radius: 24px;
  border: 2px dashed;
}
