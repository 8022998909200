@import "./src/styles/variables.scss";
.create-page__form.tarifs {
  display: flex;
  flex-direction: column;
  gap: 28px;

  .button_outline--green-accent {
    width: 200px;
  }
}
.form-tarifs {
  
  &__root {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__title {
    color: var(--white-main);
    margin-bottom: 24px;
  }

  &__table {
    &-head {
      display: grid;
      // grid-template-columns: 136px repeat(3, 1fr);
      grid-template-columns: 136px repeat(3, 1fr) 52px;
      gap: 8px;

      & > span {
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--white-main);
        border-radius: 12px;
        border: 1px solid var(--white-400);
        height: 100%;
        padding: 16px 12px;
        width: 136px;
        @media (max-width: $mobile) {
          display: none;
        }
      }

      .checkbox {
        background: var(--green-300);
        width: 100%;
        justify-content: center;

        .icon {
          pointer-events: none;
        }

        &.checkbox_checked {
          .icon {
            border: 2px solid var(--white-main);
            background: var(--white-main);
            &::after {
              background-color: var(--color-main-reverse);
            }
          }

          label {
            color: var(--white-main) !important;
          }
        }
        &.checkbox_checked,
        &:hover {
          background: var(--green-600);
        }

        &:nth-of-type(1) {
          .icon {
            left: calc(50% - 40px);
          }
        }
        &:nth-of-type(2) {
          .icon {
            left: calc(50% - 56px);
          }
        }
        &:nth-of-type(3) {
          .icon {
            left: calc(50% - 96px);
          }
          @media (max-width: $mobile) {
            .icon {
              top: 4px;
            }
            label {
              max-width: 100px;
            }
          }
        }

        @media (max-width: $mobile) {
          .icon {
            top: 10px;
            left: 50% !important;
            transform: translateX(-50%);

            
          }
        }
        label {
          width: 100%;
          text-align: center;
          padding-left: 36px;
          span {
            display: block;
          }

          @media (max-width: $mobile) {
          }
        }
      }

      @media (max-width: $mobile) {
        grid-template-columns: repeat(3, 1fr);

        .checkbox {
          height: 60px;

          label {
            padding: 0;
            padding-top: 20px;
          }
        }
      }
    }

    &-body {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .tarifs-row {
        display: grid;
        grid-template-columns: 136px repeat(3, 1fr) 52px;
        gap: 8px;

        .input-label.disabled {
          pointer-events: all;

          input {
            pointer-events: none;
          }
        } 

        @media (max-width: $mobile) {
        grid-template-columns: 100px repeat(3, 1fr);
          .select-city {
            grid-column: 1;
          }
          .tarifs-row-trash {
            grid-column: 4;
            padding-left: 28px;
          }
        }

        .input-label {
          flex-grow: 1;
        }

        & > span {
          font-size: 16px;
          color: var(--white-main);
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          padding: 8px 16px;
          border-radius: 12px;
          width: 136px;
          background: var(--black-light);
          transition: background-color var(--switch-theme-time);
        }
        .tarifs-row-trash {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 52px;
          height: 100%;

          &:hover {
            .icon--trash {
              &::after {
                background-color: var(--red-main);
              }
            }
          }

          .icon--trash {
            &::after {
              background-color: var(--red-600);
            }
          }
        }
      }
    }
  }

  &__worktimes {
    display: flex;
    flex-wrap: nowrap;
    gap: 24px;

    .form-tarifs__title {
      margin-bottom: 0px;
    }

    &--days {
      display: flex;
      flex-direction: column;
      gap: 24px;

      & ~ .form-tarifs__root {
        .form-tarifs__title {
          height: 32px;
          display: flex;
          align-items: center;
        }
      }

      &-head {
        display: flex;
        justify-content: space-between;
        gap: 12px;
        align-items: center;
        .checkbox {
          input {
            pointer-events: none;
          }
        }

        

      }
      &-body {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
      &-item {
        display: flex;
        gap: 12px;
        align-items: center;
        .input-label.error {
          border: none;
          outline: 1px solid var(--red-800);;
          font-size: 14px;
          input {
            font-size: 16px;
          }
        }

        .checkbox {
          label {
            display: flex;
            align-items: center;
            gap: 8px;
            padding-top: 5px;
            padding-bottom: 5px;
          }
          .icon--check {
            pointer-events: none;
          }
          .icon--time-plan {
            &::after {
              background-color: var(--green-main);
            }
          }
        }
      }
    }

    @media (max-width: $tabletSmall) {
        flex-direction: column;
    }
  }

  &__discounts {
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-radius: 12px;
    border: 1px solid var(--white-400);
    gap: 16px;

    .form-tarifs__title {
      span {
        margin-left: 6px;
        color: var(--green-main);
      }
    }

    &-ofday {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
}
