.upad {
  border-radius: 16px;
  padding: 16px 10px;
  gap: 12px;
  display: flex;
  flex-direction: column;
  background-color: var(--color-200);

  &.not_active {
    opacity: 40%;
  }

  &__link--city {
    color: var(--green-main);
    font-size: 18px;
    line-height: 22px;
    font-weight: 200;
    font-family: "TT Ramillas";

    &:hover {
      text-decoration: underline;
    }
  }

  &__updown {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &__calc {
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: var(--color-300);
      width: fit-content;
      border-radius: 12px;
      padding: 4px;
      gap: 4px;
      width: 100%;
      justify-content: space-between;
      cursor: pointer;
    }

    &__about {
      display: grid;
      grid-template-columns: 1fr auto 1fr;
      gap: 10px;
    }

    &__deactivate {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }

  &__deactivate-btn {
    color: var(--color-600);
    margin-top: 8px;

    &:hover {
      text-decoration: underline;
    }
  }

  &__input {
    background-color: transparent;
    border: none;
    width: 40px;
    text-align: center;
    outline: none;
    cursor: pointer;
  }

  &__status {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    &__elite {
      background: linear-gradient(
        49.58deg,
        #b07d20 39.69%,
        #b07d20 59.3%,
        #ffda44 84.29%,
        #ffda44 104.7%,
        #b07d20 122.24%,
        #b07d20 148.51%
      );
      color: white;
      width: 100%;
      font-weight: 600;
      padding: 6px 10px;
      width: 100%;
      text-align: center;

      border-radius: 12px;
    }

    &__elite-no {
      border: 1px solid var(--gold-main);
      padding: 6px 10px;
      width: fit-content;

      border-radius: 12px;
      cursor: pointer;

      &:hover {
        background-color: rgba(255, 169, 89, 0.12);
      }
    }

    &__top-no {
      border: 1px solid var(--green-main);
      padding: 6px 10px;
      width: fit-content;

      border-radius: 12px;
      cursor: pointer;

      &:hover {
        background-color: rgba(28, 161, 130, 0.08);
      }
    }

    &__top {
      background: linear-gradient(0deg, #00a480, #00a480),
        linear-gradient(
          76.82deg,
          #546265 11.6%,
          #9da1a1 25.31%,
          #828b8a 48.06%,
          #546265 55.72%,
          #546265 77.23%,
          #747a7b 85.34%,
          #546265 91.31%
        );
      background-blend-mode: color, normal;

      color: white;
      width: 100%;
      font-weight: 600;
      padding: 6px 10px;
      width: 100%;
      text-align: center;

      border-radius: 12px;
    }
  }
}
