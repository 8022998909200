@import "./src/styles/variables.scss";

.profiles-page {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-top: 24px;
    padding-bottom: 24px;
    @media (max-width: $mobile) {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    gap: 24px;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $tabletSmall) {
      align-items: flex-start;
      flex-direction: column;
    }
  }

  &.view {
    display: grid;
    grid-template-columns: max-content max-content 600px;
    align-items: center;
    gap: 28px;
    padding-left: 8px;

    @media (max-width: 1250px) {
      display: flex;
      max-width: 600px;
      gap: 10px;
      padding-left: 0px;
    }
  }

  &.view__btn {
    @media (max-width: 1250px) {
      display: none;
    }
  }

  &__grid-ads {
    display: grid;
    grid-column-gap: 16px;
    grid-row-gap: 32px;
    grid-template-columns: repeat(2, 1fr);

    &.small {
      grid-template-columns: repeat(4, 1fr);

      @media (max-width: 1370px) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media (max-width: 1050px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (max-width: $mobile) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }

  &__filter {
    position: relative;

    &__label {
      position: relative;
      cursor: text;
      border-radius: 8px;
      padding: 4px 4px 4px 12px;
      min-height: 40px;
      background-color: var(--black-light);
      transition: background-color var(--switch-theme-time);
      display: flex;
      align-items: center;

      &:hover,
      &:focus-within {
        background-color: var(--color-400);
      }
    }

    &__input {
      width: 100%;
      min-height: 0;
      border-radius: 0;
      background-color: transparent;
      outline: none;
      color: var(--color-600);

      &::placeholder {
        color: var(--color-600);
        transition: color var(--switch-theme-time);
      }

      @media (max-width: $mobile) {
        font-size: 13px;
        padding: 0 40px 0 0;
      }
    }

    &__close {
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);
      @media (max-width: $mobile) {
        border-radius: 8px;
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
