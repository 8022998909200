@import 'src/styles/variables';

.forgot-phone-inputs{
    p{
        font-size: 18px;
        color: var(--color-main);
        padding: 0 24px;
        text-align: center;
        line-height: 22px;
        span{
            display: block;
        }
    }

    div{
        display: flex;
        justify-content: center;
        gap: 10px 12px;
        input{
            width: 68px;
            height: 68px;
            background-color: var(--color-300);
            border-radius: 12px;
            outline: none;
            color: var(--color-main);
            font-size: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
        }
    }

    //.popup-form__body{
    //    padding: 32px 16px;
    //    display: flex;
    //    flex-direction: column;
    //    gap: 24px;
    //}

    a{
        color: var(--green-main);
        text-decoration: none;
        display: inline-block;
        margin: 0 auto;
        &:hover{
            text-decoration: underline;
            text-underline-offset: 4px;
            text-decoration-thickness: 1px;
        }
    }
}