@import "./src/styles/variables";

.create-page__form.appearance {
  .select {
    height: 60px;
    justify-content: flex-start;
    color: var(--white-main);

    .select__arrow {
      transition: all 0.3s;
      right: 16px;
      top: 20px;
      position: absolute;
    }

    .select__body {
      width: 100%;
    }

    @media (max-width: 700px) {
      height: 40px;
      min-height: 40px;

      &.forman {
        order: 2;
        margin-top: 10px;
      }

      .select__arrow {
        top: 12px;
      }
    }
  }
}

.create-page__input-container {
  display: flex;
  gap: 10px;
  align-items: center;
  height: 60px;
  flex-grow: 1;

  .select {
    min-width: 180px;
    max-width: 255px;
  }

  .create-page__input-buttons {
    position: relative;
    flex-grow: 1;
    flex-shrink: 1;
    height: 100%;
    align-items: center;
    font-size: 13px;
    padding: 12px 12px 12px 0px;
    display: flex;
    border: 1px solid var(--white-300);
    gap: 8px;

    @media (min-width: 701px) {
      border-left: none;
      border-radius: 0px 12px 12px 0px;
      &::after,
      &::before {
        position: absolute;
        left: -20px;
        content: "";
        width: 20px;
        height: 1px;
        background-color: transparent;
        border-top: 1px solid var(--white-300);
      }
      &::after {
        top: -1px;
      }
      &::before {
        bottom: -1px;
      }
    }

    @media (max-width: 700px) {
      border-radius: 12px;
      padding-left: 12px;

      height: unset;
      flex-grow: 0;
    }

    @media (max-width: 420px) {
      padding: 8px 6px;
    
      .checkbox-button {
        padding: 6px;
      }
    }
   
    .checkbox-button {
      cursor: pointer;
      padding: 8px 12px;
      color: var(--white-600);
      background: var(--black-light);

      &:hover {
        color: var(--white-main);
        background-color: var(--color-400);
      }

      &.checkbox-button_checked {
        color: var(--color-main-reverse);
        background-color: var(--color-main);
      }
    }
  }

  @media (max-width: 700px) {
    flex-direction: column;
    height: unset;
    gap: 0;
    align-items: stretch;
    justify-content: flex-end;

    .select {
      max-width: unset;
    }
  }
}
.create-page__form.appearance {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .form-services__top-item {
    align-items: stretch;
  }

  .form-appearance {
    &__grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 16px;

      @media (max-width: 700px) {
        .input-label  {
          grid-column: 1 / 4;
        }
      }
    }
    &__left {
      display: flex;
      flex-direction: column;
      gap: 20px;

      @media (max-width: 700px) {
        width: 100%;
      }
    }
    &__right {
      height: 220px;
      padding-left: 12px;
      padding-right: 12px;
      background-color: var(--color-300);
      border-radius: 12px;
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 36px;
      align-items: center;

      @media (max-width: 420px) {
        padding-left: 6px;
        padding-right: 6px;
      }

      img {
        width: 110px;
      }
    }
    &__top {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;

      .input-label {
        width: 180px;
      }

      @media (max-width: 1345px) {
        .input-label {
          width: calc(50% - 8px);
        }
      }

      @media (max-width: 1345px) {
        flex-wrap: nowrap;
      }

      @media (max-width: 420px) {
        gap: 8px;

        .create-page__input-buttons {
          .checkbox-button {
            padding: 6px 4px;
          }
        }
      }
    }

    &__top-main {
      display: flex;
      justify-content: space-between;
      gap: 16px;
    }
    &__right-range {
      display: flex;
      flex-direction: column;
      gap: 12px;

      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 50px;

      .create-range:nth-of-type(3) {
        margin-top: 44px;
      }
    }
    &__bottom {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;

      @media (max-width: 1200px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}
.create-page__form {
  .select-city {
    font-size: 14px;
  }
  .select {
    justify-content: flex-start;
    .select__body {
      min-width: 100%;
      width: fit-content;
    }

    @media (max-width: $tabletSmall) {
      .select__body {
        width: max-content;
      }
    }
  }
}

.create-range {
  .multi-range-slider {
    height: 2px;
    padding: 0px;
    border: none;
    box-shadow: none;
    background-color: transparent;
  }
  input {
    color: var(--white-main);
    width: 38px;
    height: 24px;
    text-align: center;
    border-radius: 8px 8px 0px 0px;
    padding: 6px 4px 8px 4px;
    background-color: var(--color-400);
  }

  .create-range__input {
    .thumb-left {
      display: none;
    }
    .bar-left {
      display: none;
    }
    .thumb-right::before {
      width: 6px;
      height: 6px;
      margin-top: -3px;
      margin-left: -4px;
      border: none;
      box-shadow: none;
      background-color: var(--white-main);
    }

    .bar-inner {
      background-color: var(--white-main);
      border-color: transparent;
    }

    .bar-right {
      box-shadow: none;
      background-color: var(--white-600);
    }

    .caption {
      display: none !important;
    }
  }
}
