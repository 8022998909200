@import "./src/styles/variables.scss";

.steps {

    &__progressbar {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;
        border-radius: 8px;
        padding: 16px 12px;
        background-color: var(--black-light);
        color: var(--color-main);
        font-weight: 500;

        &-text {
            display: flex;
            flex-shrink: 0;
        }

        .progressbar {
            margin-left: 4px;
        }

        .progressbar-value {
            position: relative;
            background: var(--black-light);
            width: 100%;
            height: 6px;
            border-radius: 16px;

            .progressbar-current {
                position: absolute;
                border-radius: 16px;
                left: 0;
                top: 0;
                height: 6px;
                background-color: #157A63;
            }
        }
    }

  &__root {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 320px;
    @media (max-width: $tabletSmall) {
      width: 100%;
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    gap: 12px;

    
    .step {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 16px;

        .step__current {
            display: flex;
            width: 48px;
            height: 48px;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-color: transparent;
            border: 1px solid var(--green-600);
            color: var(--green-600);
        }
        .step__text {
            font-size: 16px;
            font-weight: 500;
            color: var(--color-600);
        }
        &._disabled {
          cursor: default;
          pointer-events: none;
        }

        &._active,
        &:hover {
            .step__current {
                color: var(--green-main);
                border: 1px solid var(--green-main);
            }
            .step__text {
                color: var(--color-main);
            }
        }
    }

    hr {
        margin-left: 12px;
        transform-origin: center;
        transform: rotate(90deg);
        width: 24px;
        background: var(--green-600);
    }

    @media (max-width: $tabletSmall) {
      grid-template-rows: none;
      grid-template-columns: 60% auto;
      

      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      column-gap: 0px;
      .step {
        flex-direction: column;
        width: auto;
      }

      .step__text {
        display: none;
      }

      hr {
        transform: rotate(0deg);
        margin-left: 0px;
        width: 100%;
      }

      
    }

    @media (max-width: $mobileSmall) {
      grid-template-rows: none;
      grid-template-columns: 55% auto;
      // gap: 10px;
    }

    @media (max-width: $mobileExtraSmall) {
      grid-template-rows: 1fr 1fr;
      grid-template-columns: none;
    }
  }

  &__block {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 106px;
    gap: 16px;
    border-radius: 16px;
    padding: 20px;
    background-color: var(--white-200);
    color: var(--white-main);

    &:hover {
      outline: 2px solid var(--green-main);
    }

    .light & {
      background-color: rgba(31, 33, 33, 0.06);
      color: rgba(31, 33, 33, 1);
    }
  }

  &__secure {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
}
