@import "src/styles/variables";
.tabs{
    &__nav{
        display: flex;
        align-items: center;
    }

    &__nav-btn{
        display: flex;
        justify-content: center;
        cursor: pointer;
        outline: none;
        align-items: center;
        position: relative;
        transition: all 0.15s ease;
        min-height: 40px;
        width: 100%;
        padding: 0 6px;
        text-decoration: none;
        @media(max-width: $mobile){
            font-size: 13px;
            padding: 0 4px;
        }

        &:before, &:after{
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 2px;
            transition: inherit;
        }

        &:before {
            background-color: var(--color-main);
            transform: scale(0);
        }

        &:after{
            background-color: var(--color-500);
        }

        &--active,
        &:hover,
        &:focus-within {
            color: var(--color-main);

            &:before {
                transform: scale(1);
            }
        }
        i{
            margin-right: 8px;
        }

        &--erocontent{
            color: var(--green-main);
            &--active,
            &:hover,
            &:focus-within{
                i:after{
                    background-color: var(--color-main);
                }
            }
            i:after{
                background-color: var(--green-main);
            }
        }
    }

    &__panel{
        display: none;
        &--active {
            display: block;
        }
    }
}