@import "src/styles/variables";

.girl-card {
    position: relative;
    transition: all var(--switch-theme-time);
    background-color: transparent;
    padding: 2px;
    min-width: 100%;
    border-radius: 16px;

    &:hover {
        background: var(--color-500);
    }

    &.girl-card__big {
        @media(min-width: $mobileSmall) {
            border-radius: 24px;
        }
    }

    &--elite {
        background: linear-gradient(
                        180deg,
                        rgba(186, 161, 99, 1) 30%,
                        rgba(186, 161, 99, 0) 94%
        );

        &:hover {
            background: linear-gradient(
                            180deg,
                            rgba(186, 161, 99, 1) 30%,
                            rgba(186, 161, 99, 1) 94%
            );
        }
    }

    &--top {
        background: var(--green-main);

        &:hover {
            background: var(--green-main);
        }
    }

    &.girl-card__big.girl-card--top {
        @media(min-width: $mobileSmall) {
            background: transparent;
            &:hover {
                background: linear-gradient(
                                180deg,
                                rgb(28, 161, 130, 1) 0%,
                                rgba(28, 161, 130, 0) 94%
                );
            }
        }
    }


    &__wrapper {
        padding: 6px;
        margin: 0;
        gap: 10px;
        border-radius: 16px;
        display: grid;
        grid-template-columns: 156px 1fr;
        height: 100%;
        color: var(--color-main);
        background: var(--black-light);

        &:after {
            position: absolute;
            width: 106px;
            height: 36px;
            left: 32px;
            top: -18px;
            z-index: 2;
        }

        .girl-card__big & {
            @media(min-width: $mobileSmall) {
                display: flex;
                flex-direction: column;
                padding: 0 0 20px;
                border-radius: 24px;
                overflow: hidden;
                &:after {
                    top: 0;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

                > div:not(.girl-card__slider) {
                    padding: 0 10px;
                }
            }
        }

        .girl-card--elite & {
            &:after {
                content: "";
                background: url("/assets/img/status/elite-mob.webp") no-repeat center center/ cover;
            }
        }

        .girl-card__big.girl-card--elite & {
            @media(min-width: $mobileSmall) {
                &:after {
                    width: 168px;
                    height: 50px;
                    background: url("/assets/img/status/elite.webp") no-repeat center center/ cover;
                }
            }
        }

        .girl-card--top & {
            color: var(--white-main);
            background-color: var(--green-main);

            &:after {
                content: "";
                background: url("/assets/img/status/top-mob.webp") no-repeat center center/ contain;

                .light & {
                    background: url("/assets/img/status/top-mob-light.webp") no-repeat center center/ contain;
                }
            }

            .girl-card__prices span,
            .girl-card__param,
            .girl-card__age,
            .girl-card__age span,
            .girl-card__address,
            .icon--pants {
                color: var(--white-main);
            }

            .girl-card__param span,
            .girl-card__speak span {
                color: var(--white-600);
            }
        }

        .girl-card__big.girl-card--top & {
            @media(min-width: $mobileSmall) {
                &:after {
                    width: 169px;
                    height: 59px;
                    background: url("/assets/img/status/top.webp") no-repeat center center/ contain;

                    .light & {
                        background: url("/assets/img/status/top-light.webp") no-repeat center center/ contain;
                    }
                }
            }
        }
    }

    .checkbox {
        position: absolute;
        left: 20px;
        top: 20px;
        z-index: 2;
        width: 20px;
        height: 20px;
        display: inline-block;

        input {
            position: relative;
            width: 100%;
            height: 100%;
            transform: unset;
            left: unset;
            top: unset;
            border-color: var(--white-500);

            &:checked {
                background-color: var(--color-main);
                border-color: var(--color-main);

                &:after {
                    background-color: var(--color-main-reverse);
                }
            }

            &:after {
                width: 14px;
                height: 14px;
            }
        }

        .girl-card__big & {
            @media(min-width: $mobileSmall) {
                width: 24px;
                height: 24px;
            }
        }
    }

    &__slider {
        aspect-ratio: 3/4;
        background-color: var(--color-main-reverse);
        width: 100%;
        min-width: 156px;
        height: fit-content;
        width: 100%;
        border-radius: 12px;

        .swiper-slide {
            width: 100% !important;
        }

        .girl-card--top & {
            .swiper-scrollbar__bar,
            .swiper-scrollbar span {
                background-color: var(--white-500);
            }

            .swiper-scrollbar-drag {
                background: var(--white-main);
            }
        }

        .girl-card__big & {
            @media(min-width: $mobileSmall) {
                border-radius: 24px 24px 0 0;
            }
        }
    }

    &__favorite {
        position: absolute;
        z-index: 2;
        background-color: transparent;
        top: 14px;
        right: 14px;
        color: var(--white-main);

        .icon--favorite-fill {
            color: var(--green-main);
        }

        .girl-card__big & {
            @media(min-width: $mobileSmall) {
                top: 20px;
                right: 20px;
                i {
                    font-size: 24px;
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }

    &__slide {
        cursor: pointer;
        background-color: var(--black-500);
        background-image: url("/assets/img/girl-card-bg--dark.webp");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;

        .light & {
            background-image: url("/assets/img/girl-card-bg--light.webp");
        }


        &._hover,
        &:hover,
        &:focus-within {
            img {
                transform: scale(1.1);
            }
        }

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            transition: all 0.3s ease-in-out 0s;
            transform: scale(1);
            @media (max-width: $mobile) {
                position: absolute;
            }
        }

        .girl-card__big & {
            @media(min-width: $mobileSmall) {
                &:before,
                &:after {
                    position: absolute;
                    content: "";
                    left: 0;
                    right: 0;
                    z-index: 1;
                }

                &:before {
                    top: -36px;
                    height: 100px;
                    background: linear-gradient(
                                    180deg,
                                    rgba(0, 0, 0, 1) 0%,
                                    rgba(0, 0, 0, 0.9) 20%,
                                    rgba(0, 0, 0, 0) 100%
                    );
                }

                &:after {
                    bottom: -1px;
                    background: linear-gradient(
                                    0deg,
                                    rgba(31, 31, 31, 1) 0%,
                                    rgba(31, 31, 31, 0.9) 20%,
                                    rgba(31, 31, 31, 0) 100%
                    );
                    height: 140px;
                    transition: all var(--switch-theme-time);

                    .light & {
                        background: linear-gradient(
                                        0deg,
                                        rgba(237, 237, 237, 1) 0%,
                                        rgba(237, 237, 237, 0.9) 20%,
                                        rgba(237, 237, 237, 0) 100%
                        );
                        height: 160px;
                    }
                }

                &:last-of-type {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: unset;
                }
            }
        }

        .girl-card__big.girl-card--top &:after {
            @media(min-width: $mobileSmall) {
                background: linear-gradient(
                                0deg,
                                var(--green-main) 0%,
                                var(--green-800) 20%,
                                rgba(28, 161, 130, 0) 100%
                );
            }
        }
    }

    &__box {
        display: flex;
        flex-direction: column;
        gap: 8px;
        border-radius: 16px;
        position: relative;
        z-index: 2;
        width: 100%;
        height: 100%;
        justify-content: center;
        padding: 8px;

        .button {
            text-decoration: none;

            span {
                display: none;
            }
        }

        .girl-card__big & {
            @media(min-width: $mobileSmall) {
                width: 212px;
                gap: 14px;
                padding: 16px;
                height: auto;
                border: 1px solid var(--color-300);
                .button {
                    padding: 14px 4px;
                    font-size: 16px;

                    span {
                        display: inline;
                    }
                }
            }
        }
    }

    &__info-box {
        display: flex;
        gap: 2px;
        flex-direction: column;
        align-items: center;

        span {
            font-size: 13px;
        }

        .girl-card__big & {
            @media(min-width: $mobileSmall) {
                gap: 4px;
                i {
                    font-size: 24px;
                    width: 24px;
                    height: 24px;
                }
                span {
                    font-size: 16px;
                }
            }
        }
    }

    &__descr {
        padding: 0 8px 4px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        position: absolute;

        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;

        .girl-card__tags-wrapper {
            display: none;
        }

        .girl-card__big & {
            @media(min-width: $mobileSmall) {
                padding: 0 12px 64px;
                .girl-card__tags-wrapper {
                    display: flex;
                }
                i {
                    background-color: var(--black-600);
                }
                .tag {
                    font-size: 12px;
                    background-color: var(--black-600);
                    color: var(--color-main);

                    &:first-of-type {
                        margin-left: auto;
                    }
                }
            }
        }
    }

    &__info {
        width: 100%;

        &-wrapper {
            display: flex;
            justify-content: space-between;
            gap: 8px;
            align-items: center;
            @media (max-width: $mobile) {
                justify-content: unset;
                gap: 8px;
            }

            .girl-card--top & {
                color: var(--white-main);
            }

            .girl-card__big & {
                @media(min-width: $mobileSmall) {
                    flex-direction: row-reverse;
                    position: relative;
                    margin-top: -12px;
                    transform: translateY(-28px);
                    height: 0;
                    z-index: 1;
                }
            }
        }

        a,
        span {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            word-break: break-all;

            i {
                vertical-align: text-top;
            }
        }

        a {
            text-decoration: none;

            &:hover,
            &:focus-within {
                text-decoration: underline;
                text-decoration-thickness: 1px;
                text-underline-offset: 4px;
            }
        }

        .girl-card__big & {
            @media(min-width: $mobileSmall) {
                &:after {
                    margin-top: -12px;
                    transform: translateY(-56px);
                    height: 0;
                    z-index: 1;
                }
            }
        }
    }

    &__tags-wrapper {
        display: flex;
        gap: 4px;
        flex-wrap: wrap;
        justify-content: space-between;
        height: 28px;
        overflow: hidden;
    }

    &__icon {
        background-color: var(--color-600);
        font-size: 20px;
        border-radius: 8px;
        color: var(--green-main);
        .girl-card__big & {
            @media(min-width: $mobileSmall) {
                font-size: 24px;
            }
        }
    }

    &__name {
        font-family: "TT Ramillas";
        font-weight: 300;
        line-height: 115%;
        font-size: 20px;

        &._hover {
            text-decoration: underline;
            text-decoration-thickness: 1px;
            text-underline-offset: 4px;
        }

        i {
            margin: 8px 4px 0 0;
        }

        .girl-card__big & {
            @media(min-width: $mobileSmall) {
                font-size: 22px;
                line-height: 130%;
                i {
                    margin: 8px 4px 0 0;
                }
            }
        }
    }

    &__address {
        color: var(--color-700);
        padding-bottom: 2px;
        margin-bottom: -2px;

        i {
            margin: 4px 4px 0 0;
        }

        .girl-card__big & {
            @media(min-width: $mobileSmall) {
                i {
                    margin: 2px 4px 0 0;
                }
            }
        }
    }

    &__name i,
    &__address i {
        width: 12px;
        height: 12px;

        .girl-card__big & {
            @media(min-width: $mobileSmall) {
                width: 14px;
                height: 14px;
                font-size: 14px;
            }
        }
    }

    &__age {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2px;
        text-align: center;
        padding: 6px 8px;
        height: max-content;
        color: var(--color-main);
        background-color: var(--black-400);
        border-radius: 8px;
        font-size: 16px;
        line-height: 1;

        span {
            font-size: 12px;
            color: var(--color-700);
        }

        .girl-card__big & {
            @media(min-width: $mobileSmall) {
                padding: 8px 9px 6px;
                font-size: 18px;
            }
        }
    }

    &__group {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        padding-bottom: 44px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;
    }

    &__params {
        display: flex;
        gap: 8px;
        flex-wrap: wrap;

        .girl-card__big & {
            @media(min-width: $mobileSmall) {
                gap: 16px;
            }
        }
    }

    &__param {
        display: flex;
        flex-direction: column;
        gap: 2px;
        font-size: 13px;
        white-space: nowrap;
        color: var(--color-main);

        span {
            font-size: 12px;
            color: var(--color-600);
            font-weight: 500;

            .girl-card--top & {
                color: var(--white-600);
            }
        }

        .girl-card__big & {
            @media(min-width: $mobileSmall) {
                font-size: 16px;
                gap: 4px;
                span {
                    font-size: 14px;

                }
            }
        }
    }

    &__modes {
        display: flex;
        gap: 4px;
        flex-wrap: wrap;
        max-height: 94px;
        overflow: hidden;

        i,
        span {
            height: 28px;
            border-radius: 8px;
        }

        i {
            background-color: var(--color-300);
            width: 28px;
            cursor: help;

            .girl-card--top & {
                background-color: var(--white-300);
            }
        }

        span {
            background-color: var(--color-400);
            line-height: 1;
            display: flex;
            align-items: center;
            font-size: 12px;
            padding: 6px;

            .girl-card--top & {
                background-color: var(--white-400);
            }
        }

        .girl-card__big & {
            @media(min-width: $mobileSmall) {
                max-height: 70px;
                i,
                span {
                    border-radius: var(--border-radius-primary);
                    height: 32px;
                }

                i {
                    width: 32px;
                    font-size: 24px;
                }

                span {
                    padding: 8px;
                    font-size: 13px;
                }
                .tag {
                    display: none;
                }
                .girl-card__icon {
                    display: none;
                }
            }
        }
    }

    &__info-wrapper,
    &__advantages,
    &__buttons {
        grid-column: 1 / 3;
    }

    &__advantages {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: auto;

        .girl-card--top & {
            .rating-stars .rr--on .rr--svg {
                fill: var(--white-main);
            }

            .rating-stars .rr--hf-svg-off, .rating-stars .rr--off .rr--svg {
                fill: var(--white-500);
            }
        }
    }

    &__speak {
        display: flex;
        align-items: center;
        gap: 4px;
        line-height: 1;
        height: 16px;
        margin-left: auto;

        span {
            display: inline-block;
            margin-right: 4px;
            color: var(--color-600);
        }
    }

    &__prices {
        display: flex;
        margin-top: auto;
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        gap: 12px;
        font-weight: 500;

        > span {
            display: flex;
            flex-direction: column;
            line-height: 120%;
            font-size: 18px;
            gap: 2px;

            span {
                line-height: 110%;
                display: inline-block;
                color: var(--color-600);
                font-size: 12px;
            }
        }

        .girl-card__price--accompaniment {
            @media (max-width: $mobile) {
                display: none;
            }
        }

        .girl-card__big & {
            @media(min-width: $mobileSmall) {
                gap: 18px;
                flex-wrap: wrap;
                max-height: 50px;
                overflow: hidden;
                > span {
                    gap: 8px;
                    font-size: 22px;

                    span {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    &__buttons {
        display: flex;
        gap: 4px;

        a[href*="tel:"] {
            &:hover,
            &:focus-within {
                text-decoration: underline;
                text-decoration-thickness: 1px;
                text-underline-offset: 4px;
            }
        }

        .button {
            width: 100%;
            justify-content: center;
            order: -1;
            height: 44px;
            margin-top: auto;
        }

        .girl-card--top & {
            .button-square {
                background-color: var(--white-main);
                color: var(--black-main);

                .light & {
                    &:hover,
                    &:focus-within {
                        background-color: var(--white-700);
                    }
                }
            }

            .button--green {
                background-color: var(--green-accent);

                &:hover,
                &:focus-within {
                    opacity: 0.7;
                }
            }
        }
    }
}
