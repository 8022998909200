@import 'src/styles/variables';

.agency {
    & > section:not(.breadcrumbs, .agency__banner, .swiper),
    & > div:not(.breadcrumbs, .agency__banner, .swiper) {
        padding-top: 24px;
        padding-bottom: 24px;
        @media(max-width: $mobile) {
            padding-top: 8px;
            padding-bottom: 8px;
        }
    }

    &__item {
        position: absolute;
        bottom: 0;
        left: 16px;
        border-radius: 24px;
        display: grid;
        grid-template-columns: 205px 1fr;
        grid-template-rows: max-content;
        align-items: center;
        column-gap: 16px;
        padding: 20px 24px;
        background-color: var(--color-700-reverse);
        --webkit-backdrop-filter: blur(20px);
        backdrop-filter: blur(20px);

        @media(max-width: $mobileSmall) {
            grid-template-columns: 112px 1fr;
            gap: 12px;
            padding: 16px;
            right: 16px;
            background-color: var(--color-500-reverse);
        }

        &-desc {
            grid-row: 1/3;
            grid-column: 2/3;
            @media(min-width: $mobileSmall) {
                margin-bottom: 12px;
            }
        }

        picture {
            grid-row: 1/4;
            grid-column: 1/2;
            @media(max-width: $mobileSmall) {
                grid-row: 1 / 3;
            }

            img {
                border-radius: 12px;
                object-fit: contain;
                object-position: center;
                width: 100%;
                height: 100%;
            }
        }

        .model__review {
            &, > span {
                display: inline-block;
            }
        }

        .button {
            grid-row: 3/4;
            grid-column: 2/3;
            @media(min-width: $mobileSmall) {
                max-width: 190px;
            }
            @media(max-width: $mobileSmall) {
                grid-column: 1 / 3;
            }
        }
    }

    &__top {
        display: flex;
        gap: 24px;
        @media(max-width: $tablet) {
            flex-direction: column;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        gap: 24px;
        max-width: 708px;
        width: 100%;
    }

    &__buttons {
        display: flex;
        gap: 4px;
    }

    &__links {
        display: flex;
        flex-direction: column;
        gap: 12px;
        color: var(--color-main);
        align-items: flex-start;

        div {
            display: flex;
            flex-wrap: wrap;
            gap: 12px;
            align-items: center;

            a {
                padding-left: 28px;

                &:after {
                    left: 0;
                    right: auto;
                }
            }
        }

        a {
            display: flex;
            align-items: center;
            gap: 8px;
            text-decoration: none;

            &:before {
                background-color: var(--color-500);
                bottom: -6px;
            }

            &:after {
                mask-size: 20px;
                width: 20px;
                height: 20px;
                left: auto;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    &__address {
        padding-right: 28px;

        &:after {
            mask-size: 20px;
            right: 0;
        }
    }

    .schedule-table {
        @media(min-width: $tablet) {
            min-width: 702px;
            min-height: 350px;
        }
        @media(max-width: $mobile) {
            flex-direction: column;
        }

        &__wrapper {
            @media(max-width: $mobile) {
                max-width: 100%;
            }
        }

        &__work-time {
            @media(max-width: $mobile) {
                width: 100%;
            }
        }
    }

    &__media {
        display: flex;
        gap: 24px;
        @media(max-width: $pc){
            gap: 26px;
        }
        @media(max-width: $tabletSmall){
            flex-wrap: wrap;
        }
    }

    &-tabs{
        .tabs__content{
            width: 100%;
        }
        &__slide {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;
            @media(max-width: $mobileSmall){
                grid-template-columns: repeat(2, 1fr);
            }
            img {
                border-radius: 12px;
                aspect-ratio: 112 / 135;
                object-position: center;
                object-fit: cover;
                height: 100%;
                width: 100%;
                cursor: zoom-in;
            }
        }
    }
    .tabs{
        margin-bottom: 0;
        max-width: 710px;
        width: 100%;
        @media(max-width: $pc){
            max-width: 550px;
        }
        @media(max-width: $tablet){
            max-width: 480px;
        }
        @media(max-width: $tabletSmall){
            max-width: 100%;
        }
        .button{
            max-width: max-content;
            margin: 0 auto;
            margin-top: 24px;
        }
    }
    &__block{
        width: 100%;
    }
}