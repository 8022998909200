@import "src/styles/variables";

.connectad {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px 16px;
  align-items: center;

  &__day {
    display: grid;
    grid-template-columns: 1fr min-content;
    justify-content: space-between;
    padding: 8px 12px;
    align-items: center;
    border: 1px solid var(--color-300);
    border-radius: 12px;
    width: 100%;
  }

  &__form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &__check {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
}

.up-table {
  &__header {
    font-family: "TT Ramillas";
  }

  &__header {
    display: flex;
    height: 100%;
    border-radius: 12px 12px 0 0;
    background-color: var(--color-200);

    div,
    span {
      padding: 0 4px;
      min-height: 56px;
      align-items: center;
      justify-content: center;
      height: 100%;
      display: flex;
      width: 100%;
      color: var(--color-main);

      &:nth-of-type(1) {
        min-width: 60px;
        width: 60px;
      }
      &:nth-of-type(2) {
        min-width: 70px;
        width: 70px;
      }
      &:nth-of-type(3) {
        width: 100%;
      }
    }
  }

  &__row,
  &__row__current {
    display: flex;
    height: 100%;
    .tippy {
      display: flex !important;
    }
    div,
    span {
      padding: 0 4px;
      min-height: 56px;
      align-items: center;
      justify-content: center;
      height: 100%;
      display: flex;
      width: 100%;
      color: var(--color-main);

      &:nth-of-type(1) {
        min-width: 60px;
        width: 60px;
      }
      &:nth-of-type(2) {
        min-width: 70px;
        width: 70px;
      }
      &:nth-of-type(3) {
        width: 100%;
      }
    }
  }

  &__row {
    cursor: pointer;
    outline: 2px solid transparent;
    overflow: hidden;

    &:hover {
      outline: 2px solid var(--green-main);
      border-radius: 12px;
    }

    &:first-of-type {
      border-radius: 12px 12px 0 0;

      &:hover {
        outline: 2px solid var(--green-main);
        border-radius: 12px;
      }
    }

    &:last-of-type {
      border-radius: 0 0 12px 12px;
      pointer-events: none;
      border-top: 1px solid var(--color-main);
    }

    &:nth-child(even) div {
      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(3) {
        background-color: var(--color-100);
      }
    }

    &:nth-child(odd) div {
      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(3) {
        background-color: var(--color-200);
      }
    }

    div {
      &:nth-of-type(2) {
        color: var(--green-main);
        font-family: "TT Ramillas";
        font-size: 18px;
      }

      &:nth-of-type(3) {
        justify-content: flex-start;
        position: relative;
        z-index: 1;
        &:after {
          content: "";
          position: absolute;
          margin: 4px 4px 4px 0px;
          bottom: 0;
          top: 0;
          left: 0;
          right: 0;
          background-color: var(--green-main);
          width: var(--percent);
          border-radius: 12px;
          z-index: -1;
        }
      }
    }

    &__current {
      cursor: default;
      outline: 0;
      div {
        &:nth-of-type(2) {
          color: inherit;
          font-size: inherit;
        }

        &:nth-of-type(3) {
          &:after {
            content: none;
          }
        }
      }
    }
  }
}
