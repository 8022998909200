@import "./src/styles/variables.scss";

.reservation-page {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-top: 24px;
    padding-bottom: 24px;
    @media (max-width: $mobile) {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }
}
