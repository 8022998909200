@import "./src/styles/variables.scss";

.check {
  &__root {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }

  &__try {
    color: var(--green-main);
  }

  &__form {
    display: grid;
    grid-template-columns: 1fr 160px;
    gap: 20px;
    width: 100%;

    @media (max-width: $mobileExtraSmall) {
      grid-template-columns: 1fr auto;
    }
  }
}
