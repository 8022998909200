@import "src/styles/variables";

.inputdate-label {
  display: flex;
  flex-direction: column;
  gap: 8px;
  cursor: pointer;
  border-radius: 8px;
  padding: 8px 16px;
  min-height: 40px;
  background: var(--black-light);
  transition: background-color var(--switch-theme-time);
  position: relative;

  &:hover,
  &:focus-within {
    background: var(--color-400);
  }

  &:active {
    background: var(--black-light);
  }

  span {
    font-size: 13px;
    color: var(--color-600);

    &.disabled {
      color: var(--color-400);
    }

    &.error {
      color: var(--red-800);
    }
  }

  input {
    outline: none;
    background: transparent;
    width: 100%;
    color: var(--color-main);
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    &.disabled {
      color: var(--color-400);
    }

    &::placeholder {
      color: var(--color-500);
    }
  }
}

.dropdowndate {
  position: relative;
  line-height: 120%;

  &__body {
    position: absolute;
    visibility: hidden;
    width: max-content;
    background-color: var(--black-light);
    left: calc(100% - 260px);
    border-radius: 12px;
    top: calc(100% - 380px);
    opacity: 0;
    z-index: 3;
    transition: all 0.3s;
  }
}

.dropdowndate_active {
  .dropdowndate__body {
    visibility: visible;
    opacity: 1;
  }
}
