@import "src/styles/variables";

.schedule-table {
  display: flex;
  gap: 12px;

  h3 {
    margin-bottom: 0;
  }

  &__prices {
    width: 100%;
    color: var(--color-main);
    display: flex;
    flex-direction: column;
  }

  &__row {
    display: flex;
    gap: 2px;
    height: 100%;
    div {
      padding: 0 9px;
      min-height: 56px;
      align-items: center;
      justify-content: center;
      height: 100%;
      display: flex;
      overflow: hidden;
      width: 100%;
      font-size: 16px;
      @media (max-width: $mobile) {
        font-size: 13px;
      }

      &:first-of-type {
        justify-content: flex-start;
        min-width: 90px;
        max-width: 90px;
        &:not(.schedule-table__time) {
          color: var(--color-600);
        }
      }

      &:not(.schedule-table__title, &:first-of-type) {
        font-size: 18px;
        @media (max-width: $mobileSmall) {
          font-size: 15px;
        }
      }
    }

    &:nth-child(odd) div {
      &:not(.schedule-table__title) {
        background-color: var(--color-200);
      }

      &:first-of-type {
        background-color: var(--color-300);
      }
    }

    &:nth-child(even) div {
      background-color: var(--color-100);
      &:first-of-type {
        background-color: var(--color-200);
      }
    }

    &:first-of-type div {
      border-radius: 12px 12px 0 0;
    }

    &:last-of-type div {
      border-radius: 0 0 12px 12px;
    }
  }
  &__header {
  }

  &__title {
    width: 100%;
    background-color: var(--green-400);
  }

  //&__time {
  //    div{
  //        &:nth-child(odd){
  //            background-color: var(--black-light);
  //        }
  //        &:nth-child(even){
  //            background-color: var(--color-200);
  //        }
  //    }
  //}

  &__work-time {
    min-width: 144px;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--color-300);
    border-radius: 12px;
    overflow: hidden;
    color: var(--color-main);
    > div {
      border-bottom: 1px solid var(--color-300);
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: baseline;
      i:after {
        background-color: var(--green-main);
      }
    }
  }

  &__wrapper {
    max-width: 262px;
    display: flex;
    gap: 12px;
  }

  &__day {
    border-right: 1px solid var(--color-300);
    //width: 40px;
    width: 74px;
    min-width: 40px;
    height: 100%;
    min-height: 39px;
    font-size: 20px;
    color: var(--color-600);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__work-hours {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__discounts {
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: var(--color-main);
    font-size: 22px;
    text-align: center;
    width: 100%;
  }

  &__discount {
    padding: 12px;
    border-radius: 16px;
    background-color: var(--color-300);
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    min-width: 102px;
  }
}
