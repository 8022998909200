@import "src/styles/variables";

.stories {
    &-popup {
        overflow-x: hidden;
        padding: 0;

        > .swiper {
            overflow: unset;
        }

        .button__close {
            z-index: 2;

            &:hover {
                transform: rotate(0);
            }
        }


        .swiper-slide {
            width: 476px;
            min-width: 476px;
            @media (max-width: $tablet) {
                width: 400px;
                min-width: 400px;
            }
            @media (max-width: $mobileExtraSmall) {
                width: 300px;
                min-width: 300px;
            }
        }
    }

    &__slider {
        background-color: transparent;
        border: 0;
        max-width: 1400px;

        .girl-card__info {
            width: 100%;
        }

        .swiper-wrapper {
            position: relative;
            padding-bottom: 16px;
            @media (min-width: $mobile) and (max-height: 900px) {
                height: 90vh;
            }
        }

        .swiper-slide {
            .stories__card {
                transform: scale(0.79);
                transition: all 0.1s ease-in-out 0s;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                border-radius: 24px;
                overflow: hidden;

                &:after {
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    visibility: visible;
                    opacity: 1;
                    content: "";
                    z-index: 2;
                    background-color: var(--gray-600);
                    transition: all 0.1s ease-in-out 0s;
                }
            }
        }

        .swiper-slide-active {
            .stories__card {
                transform: scale(1);
                z-index: 1;

                &:after {
                    visibility: hidden;
                    opacity: 0;
                    background-color: transparent;
                }
            }
        }

        .swiper-slide-prev,
        .swiper-slide-next {
            .stories__card {
                transform: scale(0.88);
                @media (max-width: $mobile) {
                    transform: scale(0.93);
                }
            }
        }

        .swiper-button-prev,
        .swiper-button-next {
            background-color: var(--color-400);
            @media (max-width: $mobile) {
                display: none;
            }
        }

        .swiper-button-prev {
            transform: translate(-276px, -68px);
            left: 50%;
            @media (max-width: $tablet) {
                transform: translate(-238px, -68px);
            }
        }

        .swiper-button-next {
            transform: translate(276px, -68px);
            right: 50%;
            @media (max-width: $tablet) {
                transform: translate(238px, -68px);
            }
        }
    }

    &__text {
        padding: 6px 8px;
        margin: 0 16px;
        background: rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(32px);
        -webkit-backdrop-filter: blur(32px);
        border-radius: 8px;
        font-size: 14px;
        line-height: 22px;
        z-index: 1;
        position: relative;
        @media (max-width: $mobile) {
            display: none;
        }

        p {
            z-index: 1;
        }
    }

    &__card {
        margin: 0 auto;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: relative;
        aspect-ratio: 1 / 1.69;

        img,
        video,
        iframe {
            position: absolute;
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }

        .girl-card {
            &__info-wrapper {
                position: relative;
                z-index: 1;
                display: flex;
                flex-direction: row;
                padding: 16px;
                gap: 4px;

                &:before {
                    position: absolute;
                    content: "";
                    right: 0;
                    height: 74px;
                    left: 0;
                    bottom: 0;
                    z-index: -1;
                    background: linear-gradient(
                                    0deg,
                                    #000000 7.51%,
                                    rgba(0, 0, 0, 0.84) 41.18%,
                                    rgba(0, 0, 0, 0) 87.09%
                    );
                }

                .button {
                    margin-left: auto;
                    font-size: 14px;
                    @media (max-width: $mobile) {
                        display: none;
                    }
                }
            }

            &__name {
                font-size: 18px;
                text-decoration: none;
            }

            &__age {
                background-color: var(--white-100);

                span {
                    color: var(--white-600);
                }
            }

            &__favorite {
                height: 40px;
                right: 16px;
            }
        }

        > .button-square {
            top: 16px;
            background-color: var(--white-300);
            height: 40px;
        }
    }

    &__sound {
        position: absolute;
        left: 16px;
        z-index: 1;

        &._active {
            background-color: var(--red-300);

            i {
                &:after {
                    background-color: var(--red-main);
                }
            }
        }
    }

    &__progressbar {
        display: flex;
        gap: 4px;
        height: 2px;
        margin: 0 auto;

        .swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
            display: none !important;
        }

        .swiper-pagination-bullet {
            position: relative;
            width: 100%;
            display: inline-block;
            height: auto;
            text-align: left;
            border-radius: 0;
            opacity: 1;
            background-color: transparent;
            cursor: pointer;

            span {
                display: inline-block;
            }

            &__progress-bar {
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 1;
                width: 100%;
                height: 100%;
                background-color: var(--color-400);
            }

            &__autoplay {
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 2;
                width: 0;
                height: 100%;
                background-color: var(--color-main);
            }
        }

        .swiper-pagination-bullet-active {
            background-color: transparent;

            .swiper-pagination-bullet__autoplay {
                animation-name: countingBar;
                //animation-duration: 6s; //autopaly
                animation-timing-function: ease-in;
                animation-iteration-count: 1;
                animation-direction: alternate;
                animation-fill-mode: forwards;
            }
        }

        @keyframes countingBar {
            0% {
                width: 0;
            }
            100% {
                width: 100%;
            }
        }
    }

    &__slider .stories__card,
    &__progressbar {
        width: 434px;
        min-width: 434px;
        @media (max-width: $tablet) {
            width: 360px;
            min-width: 360px;
        }
        @media (max-width: $mobileExtraSmall) {
            width: 300px;
            min-width: 300px;
        }
    }
}
