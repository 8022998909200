@import "./src/styles/variables";
.select {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 10px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 120%; /* 15.6px */
  background-color: var(--color-300);
  align-items: center;
  border-radius: 12px;
  gap: 8px;
  transition: background-color 0.2s ease 0s;
  cursor: pointer;
  &:hover,
  &:focus-within {
    background-color: var(--white-400);
  }
  .light & {
    &:hover,
    &:focus-within {
      background-color: var(--black-400);
    }
  }

  & > i:not(.select__flag) {
    transition: all 0.3s;
  }

  &__subtitle {
    display: block;
    color: var(--color-600);
    width: 100%;
    line-height: 16px;
  }

  .dropdown__search-select {
    transition: background-color var(--switch-theme-time);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    margin-top: 4px;
    max-height: 195px;
    width: fit-content;
    overflow-y: auto;
  }

  &__body {
    position: absolute;
    visibility: hidden;
    background-color: var(--black-light);
    border-radius: 12px;
    left: 0;
    padding: 12px;
    width: max-content;
    top: calc(100% + 4px);
    opacity: 0;
    z-index: 3;
    transition: all 0.3s;

    &.select-country {
      display: flex;
      gap: 16px;
    }
  }

  .select__body-left {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 8px;
    padding-right: 6px;
    min-width: 260px;
    width: max-content;
    max-height: 260px;
    overflow-y: auto;

    .checkbox  {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 4px;
      padding: 12px 14px;
      background: var(--color-300);
      transition: background-color var(--switch-theme-time);
      border-radius: 12px;

      &:hover {
        background: var(--color-300);
      }

      // &._active {
      //   background: var(--bg-main);
      // }
    }
  }

  .select__body-right {
    min-width: 260px;
    width: max-content;
    max-height: 260px;
    overflow-y: auto;
    padding-right: 6px;
    .checkbox {
      input {
        pointer-events: none;
      }
      label {
        width: 100%;
        padding-top: 6px;
      padding-bottom: 6px;
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: left;
    flex-direction: row-reverse;
    padding: 8px;
    gap: 4px;
    width: 100%;
    text-decoration: none;
    border-radius: 8px;
    transition: background-color 0.2s ease 0s;
    &:hover {
      background-color: var(--color-200);
    }

    & svg {
      width: 22px;
      height: 16px;
      margin-right: 6px;
    }
  }
}

.select_active {
  .select__body {
    visibility: visible;
    opacity: 1;
  }

  > i:not(.select__flag, .select__icon) {
    transform: rotate(180deg);
  }
}

.select-city {
  padding: 9px 8px 9px 12px;
  border: 1px solid var(--color-400);
  background-color: transparent;
  .select__flag {
    width: 28px;
    height: 20px;
    @media (max-width: $tablet) {
      height: 18px;
    }
  }
  .select__arrow {
    @media (max-width: $tablet) {
      width: 16px;
      height: 16px;
    }
  }
  @media (max-width: $tablet) {
    padding: 6px 8px;
    border-radius: 8px;
  }

  &--word {
    display: inline-block;
    font-weight: 200;
    font-family: "TT Ramillas";
    background-color: transparent;
    position: relative;
    padding: 0;
    border-radius: 0;
    font-size: 48px;
    line-height: 54px;
    @media (max-width: $tablet) {
      font-size: 40px;
      line-height: 48px;
    }
    @media (max-width: $mobileSmall) {
      font-size: 28px;
      line-height: 34px;
    }

    &:hover,
    &:focus-within {
      background-color: transparent;
    }

    .select__body {
      font-size: 16px;
      line-height: 120%;
    }

    &::after {
      position: absolute;
      content: "";
      bottom: -4px;
      border-bottom: 1px dotted var(--color-700);
      left: 0;
      width: 100%;
    }
  }
}
