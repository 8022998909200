@import "src/styles/variables";

.adult-popup {
  z-index: 9999;
  &__body {
    color: var(--color-700);
    text-align: center;
    z-index: 2;
    border-radius: 16px;
    max-width: 400px;

    @media (max-width: $mobile) {
      padding: 16px;
    }
  }

  .switch-theme {
    background-color: var(--color-400);
    color: var(--color-600);
    &__check {
      width: 86px;
      &-bar {
        border-radius: 56px;
        &._active--dark {
          width: 86px;
          left: 92px;
        }

        &._active--light {
          width: 92px;
          left: 2px;
        }
      }
    }
  }

  &__top,
  &__bottom {
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__top {
    gap: 20px;
  }

  &__actions {
    display: flex;
    gap: 8px;
  }

  &__svg {
    height: 72px;
    width: 72px;
  }

  &__title {
    display: block;
    margin: 0 auto 12px;
    font-weight: 400;
    font-family: "TT Ramillas";
    font-size: 22px;
    line-height: 28px;
    color: var(--color-main);
    @media (max-width: $mobile) {
      font-size: 20px;
      line-height: 24px;
    }
  }
  &__cookie {
    display: flex;
    gap: 20px;
    align-items: center;
    span {
      color: var(--color-700);
    }
  }
  &__bottom {
    border-top: 1px solid var(--color-400);

    a {
      text-decoration: none;
    }
  }

  &__box {
    display: flex;
    gap: 12px;
    .button {
      width: 120px;
      justify-content: center;
    }
  }
}
