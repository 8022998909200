@import "./src/styles/variables.scss";
.profile-page__container {
  margin-bottom: 80px;

  
}
.filter__root ~ .create-form__buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 48px;
  .button {
    width: 207px;
    @media (max-width: $mobile) {
      &.button-confirmation {
        width: 100%;
      }
    }
  }
}
.guide__profile {
  &-root {
    margin-top: 48px;
    margin-bottom: 48px;

    h3 + p {
      color: var(--color-main);
      font-size: 16px;
    }
  }

  &-cards {
    padding: 16px !important;
    margin-top: 24px;

    .fake-checkbox {
      margin-top: 30px;
    }
    p {
      font-size: 16px;
    }
  }
}
.profile-page {
  &__bottom {
    display: flex;
    margin-top: 16px;
    gap: 16px;
    @media (max-width: $mobile) {
      flex-direction: column-reverse;
    }
    @media (max-width: $mobileExtraSmall) {
      width: 100%;
    }
  }
  &__box {
    width: 100%;
    border-radius: 16px;
    padding: 16px 18px;
    border: 1px solid var(--color-300);
    color: var(--color-main);
    display: flex;
    align-items: center;
    @media (max-width: $mobile) {
      width: 100%;
      height: auto;
      padding: 12px;
    }
    .button {
      margin-bottom: 24px;
      text-decoration: none;
      justify-content: center;
      width: 100%;
    }

    & > div {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 14px;
      border-radius: 12px;
      cursor: pointer;
    }

    &-grey {
      background-color: var(--color-400);
      &:hover {
        background-color: var(--black-light);
      }
    }
    &-green {
      background-color: var(--green-main);
      &:hover {
        background-color: var(--green-700);
      }
    }

    .checkbox {
      background: transparent;
      label,
      input {
        pointer-events: none;
      }

      label {
        color: var(--white-main) !important;
      }
    }

    span {
      display: inline-block;
      font-size: 16px;
      line-height: 20px;
    }
    & > div ~ span {
      width: 100%;
      text-align: center;
    }
    p {
      font-size: 16px;
    }
  }
  &__old-price {
    text-decoration: line-through;
    color: var(--color-500);
  }
  &__wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
  }
}
.select__search {
  position: relative;
  background: var(--bg-main);
  transition: background-color var(--switch-theme-time);
  border-radius: 8px;
  margin-top: 8px;
  cursor: text;
  width: 100%;

  .search__input {
    pointer-events: none;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 12px;
  }

  .icon--close {
    cursor: pointer;
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.create-page {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__root {
    display: grid;
    grid-template-columns: auto 1fr;
    width: 100%;
    gap: 16px;
    justify-items: flex-end;
    @media (max-width: $tabletSmall) {
      display: flex;
      flex-direction: column;
    }

    .create-form__buttons {
      display: flex;
      gap: 8px;
      margin-top: 12px;

      justify-content: flex-end;
      grid-column: span 3 / span 3;

      @media (max-width: $mobile) {
        grid-column: span 2 / span 2;
      }

      .button {
        width: 207px;
        @media (max-width: $mobile) {
          &.button-confirmation {
            width: 100%;
          }
        }
      }
    }

    .create__select-lang {
      position: relative;
      .create-lang {
        width: 100%;
      }

      .cities__search-steady {
        label {
          padding: 12px 14px 12px 40px;
          width: 100%;
        }
      }
      &._active {
        .create__select-popup {
          opacity: 1;
          visibility: visible;
        }
        .select__arrow {
          transform: rotate(180deg);
        }
      }
      .create__select-popup {
        opacity: 0;
        visibility: hidden;
        z-index: 5;
        width: fit-content;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        position: absolute;
        top: 65px;
        left: 0;
        padding: 16px;
        background: var(--black-light);
        transition: all 0.3s;

        .cities__search-steady {
          margin-top: 4px;
          display: flex;
          flex-direction: row;
          gap: 4px;

          .checkbox {
            width: 100%;
            border-radius: 12px;
            background: var(--bg-main);
            transition: background-color var(--switch-theme-time);
            
            .icon--check {
              left: 12px;
            }
          }
        }
        .cities__search-select {
          transition: background-color var(--switch-theme-time);
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 12px;
          margin-top: 4px;
          max-height: 195px;
          width: max-content;
          overflow-y: auto;

          .checkbox {
            .icon {
              pointer-events: none;
            }
            label {
              width: 100%;
              text-align: start;
            }
          }

          .p2 {
            grid-column: span 2 / span 2;
          }
        }
      }

      @media (max-width: $mobile) {
        grid-column: span 2 / span 2;
        .create__select-popup {
          width: 100%;
          padding: 12px;
        }
        & + .create-lang--current {
          grid-column: span 2 / span 2;

          .select__item {
            padding: 10px 12px;
            height: 40px;
          }
        }
      }
    }
  }
}

.create-error {
  position: absolute;
  top: calc(100% + 8px);
  left: 16px;
}

.create-form__buttons {
  display: flex;
  gap: 8px;
  margin-top: 12px;

  justify-content: flex-end;
  grid-column: span 3 / span 3;

  @media (max-width: $mobile) {
    grid-column: span 2 / span 2;
  }

  .button {
    width: 207px;
  }
}

.create__select-lang {
  position: relative;
  .create-lang {
    width: 100%;
  }
  &._active {
    .create__select-popup {
      opacity: 1;
      visibility: visible;
    }
    .select__arrow {
      transform: rotate(180deg);
    }
  }
  .create__select-popup {
    opacity: 0;
    visibility: hidden;
    z-index: 5;
    width: fit-content;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: absolute;
    top: 65px;
    left: 0;
    padding: 16px;
    background: var(--black-light);
    transition: all 0.3s;

    .cities__search-steady {
      margin-top: 4px;
      display: flex;
      flex-direction: row;
      gap: 4px;

      .checkbox {
        width: 100%;
        border-radius: 12px;
        background: var(--bg-main);
        transition: background-color var(--switch-theme-time);

        .icon--check {
          left: 12px;
        }
      }
    }
    .cities__search-select {
      transition: background-color var(--switch-theme-time);
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 12px;
      margin-top: 4px;
      max-height: 195px;
      overflow-y: auto;

      .checkbox {
        .icon {
          pointer-events: none;
        }
        label {
          width: 100%;
          text-align: start;
        }
      }

      .p2 {
        grid-column: span 2 / span 2;
      }
    }
  }

  @media (max-width: $mobile) {
    grid-column: span 2 / span 2;
    .create__select-popup {
      width: 100%;
      padding: 12px;
    }
    & + .create-lang--current {
      grid-column: span 2 / span 2;

      .select__item {
        padding: 10px 12px;
        height: 40px;
      }
    }
  }
}
