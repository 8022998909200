.popup-form__inner {
  .cities {
    &__search {
      position: relative;
      background: var(--black-light);
      transition: background-color var(--switch-theme-time);
      padding-top: 12px;
      padding-bottom: 12px;
      padding-left: 12px;
      border-radius: 8px;
      margin-top: 8px;

      .icon--close {
        cursor: pointer;
        position: absolute;
        right: 6px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &__search-steady {
      margin-top: 4px;
      display: flex;
      flex-direction: row;
      gap: 4px;

      .checkbox {
        width: 100%;
        padding: 12px 14px;
        border-radius: 12px;
        background: var(--black-light);
        transition: background-color var(--switch-theme-time);

        .icon--check {
          left: 12px;
        }
      }
    }

    &__search-select {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 12px;
      margin-top: 4px;
      max-height: 195px;
      width: fit-content;
      overflow-y: auto;
    }
  }
}
