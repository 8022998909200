@import "src/styles/variables";

.toggle-switch {
  display: flex;
  align-items: center;
  gap: 20px;
  width: max-content;

  &.defaulttitle {
    gap: 10px;
  }

  &._disabled,
  &[disabled] {
    filter: grayscale(0.5);
    opacity: 0.4;
    pointer-events: none;
  }

  &.words {
    input {
      &:before {
        color: var(--white-main);
        content: "Off";
        position: absolute;
        font-size: 13px;
        padding: 8px 6px;
        text-align: right;
      }
      &:checked {
        &:before {
          content: "On";
          text-align: left;
        }
      }
    }
  }

  input {
    appearance: none;
    position: relative;
    height: 32px;
    width: 62px;
    cursor: pointer;
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: var(--color-400);
      border-radius: 48px;
      transition: background-color 0.2s;
      cursor: pointer;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      transition: all 0.15s ease-in 0s;
      top: 2px;
      left: 2px;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
      background-color: var(--green-main);
      height: 28px;
      width: 28px;
      border-radius: 50%;
      cursor: pointer;
    }

    &:checked {
      &:before {
        background-color: var(--green-main);
      }

      &:after {
        transform: translateX(calc(100% + 2px));
        background-color: var(--white-main);
      }
    }
  }

  a {
    color: var(--color-600);
    font-weight: 500;
    &:after {
      top: -6px;
      right: -12px;
    }
  }
}

//.toggle-switch {
//    position: relative;
//    width: 62px;
//    display: inline-block;
//    vertical-align: middle;
//    -webkit-user-select: none;
//    -moz-user-select: none;
//    -ms-user-select: none;
//    text-align: left;
//    &-checkbox {
//        display: none;
//    }
//    &-label {
//        display: block;
//        overflow: hidden;
//        cursor: pointer;
//        background-color: var(--color-400);
//        border-radius: 20px;
//        margin: 0;
//        transition: background-color 0.15s ease-in-out 0s;
//    }
//    &-inner {
//        display: block;
//        width: 200%;
//        margin-left: -100%;
//        transition: margin 0.15s ease-in 0s;
//        &:before,
//        &:after {
//            display: block;
//            float: left;
//            width: 50%;
//            height: 32px;
//            line-height: 32px;
//            font-size: 14px;
//            font-weight: 500;
//        }
//        &:before {
//            content: attr(data-yes);
//            color: var(--white-700);
//            padding-left: 5px;
//        }
//        &:after{
//            color: var(--color-500);
//        }
//    }
//    &-disabled {
//        background-color: $disabled-colour;
//        cursor: not-allowed;
//        &:before {
//            background-color: $disabled-colour;
//            cursor: not-allowed;
//        }
//    }
//    &-inner:after {
//        content: attr(data-no);
//        padding-right: 5px;
//        text-align: right;
//        color: var(--color-500);
//    }
//    &-switch {
//        display: block;
//        width: 28px;
//        height: 28px;
//        background-color: var(--green-main);
//        position: absolute;
//        top: 50%;
//        transform: translateY(-50%);
//        right: 32px;
//        border-radius: 20px;
//        transition: all 0.15s ease-in 0s;
//    }
//    &-checkbox:checked + &-label {
//        background-color: var(--green-main);
//        .toggle-switch-label{
//        }
//        .toggle-switch-inner {
//            margin-left: 0;
//        }
//        .toggle-switch-switch {
//            right: 2px;
//            background-color: var(--white-main);
//        }
//    }
//}
