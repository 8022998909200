@import "./src/styles/variables.scss";

.waiting {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 24px;
  padding: 20px;
  height: 200px;
  width: 100%;
  background-color: var(--green-200);

  &__fast {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--color-main);
    font-size: 20px;
    gap: 8px;
    margin-top: 10px;
  }
}
