@import "src/styles/variables";

.elite-block {
  display: grid;
  grid-template-columns: min-content 1fr auto;
  background-color: var(--white-200);
  align-items: center;
  padding: 4px 12px 4px 4px;
  gap: 20px;
  border-radius: 16px;

  &__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    justify-content: center;

    @media (max-width: $mobile) {
      gap: 4px;
      flex-direction: column;
    }
  }

  &__link-to {
    color: var(--green-main);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    word-break: break-all;
    font-size: 18px;
  }

  img {
    height: 86px;
    border-radius: 12px;
    object-fit: cover;
    aspect-ratio: 3/4;
  }

  &__content {
    // display: flex;
    // flex-direction: column;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    max-height: 400px;
    height: auto;
    overflow: hidden;
    overflow-y: scroll;

    @media (max-width: $mobile) {
      grid-template-columns: 1fr;
    }

    @media (max-width: $mobileExtraSmall) {
      height: 300px;
    }
  }
}
