.input-label {
  display: flex;
  flex-direction: column;
  gap: 8px;
  cursor: text;
  border-radius: 8px;
  padding: 8px 16px;
  min-height: 40px;
  background: var(--black-light);
  transition: background-color var(--switch-theme-time);
  position: relative;
  justify-content: center;

  &.error {
    border: 1px solid var(--red-800);
  }

  .input-label__error {
    color: var(--red-700);
  }

  &.disabled {
    pointer-events: none;
  }

  &:hover,
  &:focus-within {
    background: var(--color-400);
  }

  &:active {
    background: var(--black-light);
  }

  &__password {
    padding-right: 38px;
  }

  &__show-pass {
    position: absolute;
    right: 10px;
    bottom: 8px;
    background-color: transparent;
    width: 24px;
    height: 24px;
    color: var(--color-600);
    transition: color 0.15s ease-in-out 0s;
    &._show {
      color: var(--color-main);
    }
  }

  span {
    font-size: 13px;
    color: var(--color-600);

    &.disabled {
      color: var(--color-400);
    }

    &.error {
      color: var(--red-800);
    }
  }

  input,
  textarea {
    outline: none;
    background: transparent;
    width: 100%;
    color: var(--color-main);
    font-size: 16px;
    line-height: 20px;

    &.disabled {
      color: var(--color-400);
    }

    &::placeholder {
      color: var(--color-500);
    }
  }

  textarea {
    min-height: 68px;
  }

  &__radio {
    min-width: 75px;
    justify-content: center;
    padding: 8px 0;
    text-align: center;

    span {
      font-size: 14px;
      position: relative;
      z-index: 1;
      cursor: pointer;
      color: var(--color-main);
      transition: color 0.1s ease-in 0s;
    }
    input {
      position: absolute;
      top: 0;
      cursor: pointer;
      left: 0;
      right: 0;
      bottom: 0;
      appearance: none;
      border-radius: 8px;
      transition: background 0.1s ease-in 0s;

      &:checked {
        background: var(--color-main);
        ~ span {
          color: var(--color-main-reverse);
        }
      }
    }
    &._big {
      min-width: 104px;
      min-height: 52px;
    }
  }
  &__checkbox {
    min-width: 60px;
    justify-content: center;
    align-items: center;
    padding: 12px;
    cursor: pointer;
    color: var(--color-main);
    input {
      cursor: pointer;
      appearance: none;
      border: 1px solid var(--color-500);
      width: 16px;
      height: 16px;
      background: transparent;
      border-radius: 4px;
      transition: background 0.1s ease-in 0s;
      position: relative;
      &:after {
        background: transparent;
      }
      &:checked {
        &:after {
          background: var(--color-main);
        }
      }
    }
  }
}

.disabled {
  color: var(--color-400);
}
