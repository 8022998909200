@import "./src/styles/variables.scss";

.balance-page {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-top: 24px;
    padding-bottom: 24px;
    max-width: 800px;
    @media (max-width: $mobile) {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media (max-width: $mobile) {
      flex-direction: column;
      gap: 16px;
    }
  }

  &__tabs {
    position: relative;
    transition: all 0.15s ease;
    width: max-content;
    display: flex;
    align-items: center;
    width: 100%;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 2px;
      background-color: var(--color-500);
    }
    @media (max-width: $tablet) {
      margin-left: 8px;
    }
  }

  &__tab {
    padding: 7px 16px 12px;
    color: var(--color-700);
    position: relative;
    transition: all 0.15s ease;
    width: max-content;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 2px;
      background-color: var(--color-main);
      transition: inherit;
      transform: scale(0);
      .header--type-one & {
        content: unset;
      }
    }

    &:hover {
      color: var(--color-main);
      cursor: pointer;
      .header--type-one & {
        text-decoration: underline;
      }

      &:before {
        transform: scale(1);
      }
    }

    &:after {
      top: 2px;
      right: 4px;
    }

    &._active {
      color: var(--color-main);
      &:before {
        transform: scale(1);
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
  }

  &.your__balance {
    display: flex;
    flex-direction: column;
  }

  &.day__balance {
    display: grid;
    grid-template-columns: 1fr max-content;
    padding: 8px 16px;
    gap: 12px;
    border-radius: 12px;
    border: 1px solid var(--color-400);
    width: 200px;
    align-items: center;
  }

  &__select {
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 10px;

    @media (max-width: 799px) {
      grid-template-columns: auto 1fr;
    }

    @media (max-width: $mobileSmall) {
      display: flex;
      flex-direction: column;
    }
  }

  &__updown {
    display: flex;
    flex-direction: row;
    height: 40px;
    padding: 12px;
    align-items: center;
    gap: 8px;
    border-radius: 12px;

    &.up {
      background-color: var(--green-300);
    }

    &.down {
      background-color: var(--red-300);
    }
  }
}
