@import "../../styles/variables";

.notifications {
    background-color: var(--color-main-reverse);
    padding-top: 40px;
    position: absolute;
    z-index: 6;
    // top: calc(100% + 8px);
    top: -50px;
    right: 0;
    border-radius: 16px;
    box-shadow: 0 12px 64px 0 var(--color-400);
    @media (max-width: $tablet) {
        top: 0;
    }
    @media(max-width: $mobileExtraSmall){
        right: -16px;
    }
    .button{
        justify-content: center;
    }
    .button__close{
        width: 24px;
        margin-left: auto;
        margin-bottom: 4px;
    }
    .popup-form {
        &__title{
            text-align: center;
            &:last-of-type{
                margin-bottom: 12px;
            }
        }
        &__textarea {
            padding: 16px;
            margin-bottom: 12px;
            textarea {
                min-height: 48px;
                &::placeholder{
                    color: var(--color-500);
                }
            }
        }

    }
    label:last-of-type{
        margin-bottom: 12px;
    }
    .input-label__wrapper {
        display: flex;
        gap: 8px;
        align-items: center;
        margin-bottom: 12px;
        .input-label{
            margin-bottom: 0;
        }
    }

    button[type=submit] {
        margin-bottom: 0;
    }

    &__main-button{
        max-height: 40px;
        @media(min-width: $tablet){
            width: max-content;
            z-index: 4;
            position: absolute;
            top: -50px;
            right: 0;
        }
        @media(max-width: $tablet){
            margin-left: auto;
            margin-right: 10px;
        }
        @media(max-width: $mobile){
            padding: 0;
            width: 40px;
            justify-content: center;
        }
        span{
            @media(max-width: $tablet){
                display: none;
                margin-left: auto;
            }
        }
    }

    &__text{
        line-height: 22px;
        margin-bottom: 24px;
        color: var(--color-600);
        a{
            display: inline;
        }
    }

    &__subscribe{
        p{
            margin-top: 12px;
            text-align: center;
        }
        button[type=submit] {
            width: 100%;
            justify-content: center;
        }
    }
}