.icon {
    aspect-ratio: 1/1;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:before {
        font-family: "lady4love" !important;
        font-style: normal !important;
        font-weight: normal !important;
        font-variant: normal !important;
        text-transform: none !important;
        speak: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "";
        -webkit-mask-size: 100%;
        mask-size: 100%;
        mask-repeat: no-repeat;
        mask-position: center;
        -webkit-mask-position: center;
        transition: background-color var(--switch-theme-time),
        color var(--switch-theme-time);
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center center;
    }

    &-6xl {
        width: 64px;
        height: 64px;
        font-size: 64px;
    }

    &-5xl {
        width: 48px;
        height: 48px;
        font-size: 48px;
    }

    &-4xl {
        width: 40px;
        height: 40px;
        font-size: 40px;
    }

    &-xxxl {
        width: 36px;
        height: 36px;
        font-size: 36px;
    }

    &-xxl {
        width: 32px;
        height: 32px;
        font-size: 32px;
    }

    &-xl {
        width: 28px;
        height: 28px;
        font-size: 28px;
    }

    &-l {
        width: 24px;
        height: 24px;
        font-size: 24px;
    }

    &-m {
        width: 20px;
        height: 20px;
        font-size: 20px;
    }

    &-s {
        width: 16px;
        height: 16px;
        font-size: 16px;
    }

    &-xs {
        width: 12px;
        height: 12px;
        font-size: 12px;
    }

    &-2xs {
        width: 8px;
        height: 8px;
        font-size: 8px;
    }

    // Иконки



  &--offline,
  &--offline-light,
  &--online{
    width: 14px;
    height: 14px;
    &:before{
      width: 100%;
      height: 100%;
    }
  }
    &--offline:before {
      background-image: url("/assets/img/icons/offline.svg");
    }

    &--offline-light:before {
      background-image: url("/assets/img/icons/offline-light.svg");
    }

    &--online:before {
      background-image: url("/assets/img/icons/online.svg");
    }
}

.icon--body:before {
    content: "\61";
}
.icon--body2:before {
    content: "\62";
}
.icon--body-fill:before {
    content: "\63";
}
.icon--burger:before {
    content: "\64";
}
.icon--calendar:before {
    content: "\65";
}
.icon--call:before {
    content: "\66";
}
.icon--call-outline:before {
    content: "\68";
}
.icon--checkl:before {
    content: "\69";
}
.icon--chevrone-down:before {
    content: "\6a";
}
.icon--chevrone-left:before {
    content: "\6b";
}
.icon--chevrone-right:before {
    content: "\6c";
}
.icon--chevrone-up:before {
    content: "\6d";
}
.icon--health-fill:before {
    content: "\6e";
}
.icon--comment:before {
    content: "\70";
}
.icon--dark:before {
    content: "\72";
}
.icon--edit:before {
    content: "\74";
}
.icon--incognito:before {
    content: "\75";
}
.icon--edit-2:before {
    content: "\76";
}
.icon--light:before {
    content: "\77";
}
.icon--video-play:before {
    content: "\78";
}
.icon--video:before {
    content: "\79";
}
.icon--verify-fill:before {
    content: "\7a";
}
.icon--verify:before {
    content: "\41";
}
.icon--upload:before {
    content: "\42";
}
.icon--escort:before {
    content: "\43";
}
.icon--eye:before {
    content: "\73";
}
.icon--eye-off:before {
    content: "\45";
}
.icon--favorite:before {
    content: "\44";
}
.icon--favorite-fill:before {
    content: "\46";
}
.icon--film:before {
    content: "\47";
}
.icon--home:before {
    content: "\71";
}
.icon--image:before {
    content: "\48";
}
.icon--location:before {
    content: "\49";
}
.icon--user:before {
    content: "\4a";
}
.icon--erocoin:before {
    content: "\4c";
}
.icon--erocoin2:before {
    content: "\4d";
}
.icon--erocoin41:before {
    content: "\4e";
}
.icon--erocoin-leg:before {
    content: "\4f";
}
.icon--filter:before {
    content: "\50";
}
.icon--filter2:before {
    content: "\51";
}
.icon--girlfriend:before {
    content: "\52";
}
.icon--fix:before {
    content: "\53";
}
.icon--ing-yang-1:before {
    content: "\54";
}
.icon--ing-yang-2:before {
    content: "\55";
}
.icon--logout:before {
    content: "\56";
}
.icon--map:before {
    content: "\57";
}
.icon--minus:before {
    content: "\58";
}
.icon--more:before {
    content: "\59";
}
.icon--navigation:before {
    content: "\5a";
}
.icon--notification:before {
    content: "\30";
}
.icon--notify:before {
    content: "\31";
}
.icon--pants:before {
    content: "\32";
}
.icon--payment:before {
    content: "\33";
}
.icon--photo:before {
    content: "\34";
}
.icon--plus:before {
    content: "\35";
}
.icon--plus-1:before {
    content: "\36";
}
.icon--profile-add:before {
    content: "\37";
}
.icon--profile-card:before {
    content: "\38";
}
.icon--promo:before {
    content: "\39";
}
.icon--search:before {
    content: "\21";
}
.icon--sort:before {
    content: "\22";
}
.icon--star-fill:before {
    content: "\23";
}
.icon--stat:before {
    content: "\24";
}
.icon--statistic:before {
    content: "\25";
}
.icon--status:before {
    content: "\26";
}
.icon--telegram:before {
    content: "\27";
}
.icon--travel:before {
    content: "\4b";
}
.icon--web-cam:before {
    content: "\28";
}
.icon--18-popup:before {
    content: "\29";
}
.icon--arrow-left:before {
    content: "\2a";
}
.icon--call-check:before {
    content: "\2b";
}
.icon--catalog:before {
    content: "\2c";
}
.icon--chevron-left:before {
    content: "\2d";
}
.icon--diploma-verified:before {
    content: "\2e";
}
.icon--filter-1:before {
    content: "\2f";
}
.icon--gender-couple:before {
    content: "\3a";
}
.icon--gender-female:before {
    content: "\3b";
}
.icon--gender-male:before {
    content: "\3c";
}
.icon--gender-trans:before {
    content: "\3d";
}
.icon--globe:before {
    content: "\3e";
}
.icon--map-pin:before {
    content: "\3f";
}
.icon--play:before {
    content: "\5e";
}
.icon--whatsapp:before {
    content: "\5f";
}
.icon--vector-1:before {
    content: "\60";
}
.icon--volume:before {
    content: "\7b";
}
.icon--volume-x:before {
    content: "\7c";
}
.icon--pay-content:before {
    content: "\7e";
}