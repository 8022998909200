@import "src/styles/variables";

.about {
  &__container {
    display: flex;
    justify-content: space-between;
    padding-top: 31px;
    padding-bottom: 31px;
    @media (min-width: $tablet) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    @media (max-width: $tablet) {
      flex-direction: column;
    }
    @media (max-width: $mobile) {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }

  &__description {
    max-width: 494px;
    padding-top: 12px;
    margin-bottom: 44px;
    @media (max-width: $tablet) {
      padding: 0;
      margin-bottom: 32px;
    }

    .title_h1 {
      max-width: 300px;
      margin-bottom: 44px;

      span {
        color: var(--green-main);
      }
    }

    p {
      max-width: 434px;

      span {
        color: var(--green-main);
      }
    }
  }

  &__undertitle {
    margin-bottom: 6px;
    display: block;
    font-family: "TT Ramillas";
    font-size: 22px;
  }

  &__slogan,
  &__item {
    background: linear-gradient(
      110deg,
      rgba(28, 161, 130, 0.17) 0%,
      rgba(28, 161, 130, 0) 100%
    );
    border-left: 2px solid var(--green-accent);
    border-radius: 24px;
    display: flex;
    color: var(--color-main);
  }

  &__slogan {
    align-items: flex-end;
    max-width: 494px;
    padding: 12px 24px 24px 18px;
    gap: 30px;

    @media (min-width: $tablet) {
      grid-column: 1/2;
      grid-row: 2/3;
      margin-bottom: 28px;
    }
    @media (max-width: $mobile) {
      flex-direction: column;
      gap: 4px;
      align-items: center;
      text-align: center;
      max-width: 100%;
    }

    .logo-img {
      max-width: 132px;
      @media (max-width: $mobile) {
        max-width: 190px;
      }
    }

    span {
      font-size: 22px;
      line-height: 130%;
      display: block;
      color: var(--color-main);
      font-family: "TT Ramillas";
      font-weight: 200;
    }
  }

  &__benefits {
    max-width: 648px;
    padding: 31px 0;
    width: 100%;
    position: relative;
    @media (min-width: $tablet) {
      grid-row: 1/3;
    }
    @media (max-width: $pc) and (min-width: $tablet) {
      max-width: 570px;
    }
    @media (max-width: $tablet) {
      padding: 0;
      margin-bottom: 24px;
    }

    &:after {
      position: absolute;
      top: 0;
      transform: translateX(-100%);
      content: "";
      width: 182px;
      height: 616px;
      background: url("/assets/img/about.webp") no-repeat center left / contain;
      .light & {
        background: url("/assets/img/about-light.webp") no-repeat center left /
          contain;
      }

      @media (max-width: $pc) {
        transform: translateX(-50%);
      }
      @media (max-width: $tablet) {
        right: 0;
        top: -30px;
        transform: translateX(0);
      }
      @media (max-width: $mobileExtraSmall) {
        top: 0;
      }
    }

    .title {
      font-size: 24px;
      margin-bottom: 32px;
      line-height: 125%;
    }
  }

  &__item {
    align-items: center;
    gap: 8px;
    padding: 16px;
    width: 100%;
    @media (max-width: $mobile) {
      gap: 12px;
    }

    &:not(&:last-of-type) {
      margin-bottom: 12px;
    }

    i {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 40px;
      min-height: 40px;
      border-radius: 14px;
      background-color: var(--green-300);
      color: var(--green-main);
    }
  }
}
