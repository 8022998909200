@import "src/styles/variables";

.swiper {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
  display: block;

  .girl-card {
    height: 100%;
  }

  &-agency {
    padding: 0 16px 2px;
    margin: 0 -16px -2px;
    @media (max-width: $pc) {
      overflow: visible;
    }
  }
}

.swiper-vertical > .swiper-wrapper {
  flex-direction: column;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}

.swiper-android .swiper-slide,
.swiper-ios .swiper-slide,
.swiper-wrapper {
  transform: translate3d(0px, 0, 0);
}

.swiper-horizontal {
  touch-action: pan-y;
}

.swiper-vertical {
  touch-action: pan-x;
}

.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: auto;
  position: relative;
  transition-property: transform;
  display: block;

  .swiper-agency & {
    max-width: 348px;
    @media (max-width: $mobileSmall) {
      max-width: 324px;
    }
    & > div,
    & > a {
      height: 100%;
    }
  }
}

.swiper-slide-invisible-blank {
  visibility: hidden;
}

.swiper-android .swiper-slide,
.swiper-ios .swiper-slide,
.swiper-wrapper {
  transform: translate3d(0px, 0, 0);
}

/* 3D Effects */
.swiper-3d.swiper-css-mode .swiper-wrapper {
  perspective: 1200px;
}

.swiper-3d .swiper-wrapper {
  transform-style: preserve-3d;
}

.swiper-3d {
  perspective: 1200px;

  .swiper-slide,
  .swiper-cube-shadow {
    transform-style: preserve-3d;
  }
}

.swiper-3d {
  .swiper-slide-shadow,
  .swiper-slide-shadow-left,
  .swiper-slide-shadow-right,
  .swiper-slide-shadow-top,
  .swiper-slide-shadow-bottom,
  .swiper-slide-shadow,
  .swiper-slide-shadow-left,
  .swiper-slide-shadow-right,
  .swiper-slide-shadow-top,
  .swiper-slide-shadow-bottom {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 10;
  }

  .swiper-slide-shadow {
    background: rgba(0, 0, 0, 0.15);
  }

  .swiper-slide-shadow-left {
    background-image: linear-gradient(
      to left,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0)
    );
  }

  .swiper-slide-shadow-right {
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0)
    );
  }

  .swiper-slide-shadow-top {
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0)
    );
  }

  .swiper-slide-shadow-bottom {
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0)
    );
  }
}

/* scrollbar */
.swiper-scrollbar {
  margin: 0 auto;
  position: relative;
  z-index: 5;
  cursor: pointer;
  height: 2px;
  display: flex;
  gap: 4px;

  span {
    display: inline-block;
    min-width: 32px;
    height: 100%;
    background-color: var(--color-500);
    border-radius: 4px;
  }

  &__bar {
    min-width: 32px;
    background-color: var(--color-500);
  }
}

.swiper-scrollbar-drag {
  height: 100%;
  cursor: pointer;
  background: var(--color-main);
  border-radius: 4px;
  position: absolute;
}

.swiper-scrollbar-cursor-drag {
  border-radius: 4px;
  cursor: pointer;
  background-color: var(--color-main);
}

.swiper-scrollbar-lock {
  display: none;
}

// BUTTON
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  border-radius: 19px;
  top: 50%;
  transform: translateY(-68px);
  width: 52px;
  height: 52px;
  z-index: 4;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--black-light);
  color: var(--color-main);
  transition: background-color 0.1s ease-in 0s;
  background-repeat: no-repeat;
  background-position: center center;

  &:hover,
  &:focus-within {
    background-color: var(--color-400);
  }

  @media (max-width: $mobile) {
    display: none;
  }

  &.swiper-button-disabled {
    opacity: 0.35;
    cursor: auto;
  }

  &.swiper-button-hidden {
    opacity: 0;
    cursor: auto;
  }

  .swiper-navigation-disabled & {
    display: none !important;
  }
}

.swiper-button-prev {
  left: 0;
  background-image: url("/assets/img/icons/chevrone-left.svg");

  .light & {
    background-image: url("/assets/img/icons/chevrone-left-black.svg");
  }
}

.swiper-button-next {
  right: 0;
  background-image: url("/assets/img/icons/chevrone-right.svg");

  .light & {
    background-image: url("/assets/img/icons/chevrone-right-black.svg");
  }
}

.swiper-button-lock {
  display: none !important;
}
