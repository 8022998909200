@import './src/styles/variables';

.navigation {
    &__container {
        transition: color var(--switch-theme-time);
        color: var(--color-main);
        padding-bottom: 31px;

        .dropdown {
            > button {
                background-color: var(--color-400);

                &:hover, &:focus-within {
                    background-color: var(--black-light);
                }
            }
        }

        .grid-cards {
            .navigation__empty {
                display: grid;
                grid-template-columns: max-content;
            }
        }
    }

    &__top {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
        @media(max-width: $mobile) {
            flex-direction: column;
            align-items: flex-start;
        }

        &-wrapper {
            margin-left: auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            gap: 8px;
            @media(min-width: $tablet) {
                .notifications__main-button {
                    display: none;
                }
            }
            @media(max-width: $mobile) {
                // margin-left: 30px;
                // margin-top: -35px;
                width: 100%;
            }

            > a {
                text-decoration: none;
                font-family: "Inter";
                padding: 9px 12px;
                @media(max-width: $tabletSmall) {
                    display: none;
                }
            }
        }

        h1 {
            margin: 0;
            @media(max-width: $tabletSmall) and (min-width: $mobile) {
                max-width: 624px;
            }

        }

        ._empty-map {
            opacity: 0.3;
            cursor: default;

            &:hover {
                background-color: transparent;
            }
        }
    }

    &__bottom {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px;
        margin-bottom: 40px;
        @media(max-width: $mobile) {
            margin-bottom: 20px;
        }

        .dropdown {
            .button {
                @media(max-width: $mobileExtraSmall) {
                    gap: 6px;
                }
            }

            .chevrone {
                width: 16px;
                height: 16px;
            }
        }

        .select {
            margin-left: auto;

            .select__body {
                left: auto;
                right: 0;
            }
        }
    }

    &__nav {
        display: flex;
        align-items: center;
        gap: 8px;

        > .checkbox {
            display: none;
            @media(min-width: $mobile) {
                &:nth-of-type(1),
                &:nth-of-type(2),
                &:nth-of-type(3),
                &:nth-of-type(4),{
                    display: flex;
                }
            }
            @media(min-width: $tabletSmall) {
                &:nth-of-type(1),
                &:nth-of-type(2),
                &:nth-of-type(3),
                &:nth-of-type(4),
                &:nth-of-type(5),
                &:nth-of-type(6) {
                    display: flex;
                }
            }
            @media(min-width: $tablet) {
                &:nth-of-type(1),
                &:nth-of-type(2),
                &:nth-of-type(3),
                &:nth-of-type(4),
                &:nth-of-type(5),
                &:nth-of-type(6),
                &:nth-of-type(7),
                &:nth-of-type(8) {
                    display: flex;
                }
            }

            @media(min-width: $pc) {
                &:nth-of-type(1),
                &:nth-of-type(2),
                &:nth-of-type(3),
                &:nth-of-type(4),
                &:nth-of-type(5),
                &:nth-of-type(6),
                &:nth-of-type(7),
                &:nth-of-type(8),
                &:nth-of-type(9) {
                    display: flex;
                }
            }
        }

        a {
            text-decoration: none;
        }
    }

    &__more{
        display: none;
        @media(min-width: $mobile){
            display: flex;
        }
        .checkbox {
            @media(min-width: $mobile) {
                &:nth-of-type(1),
                &:nth-of-type(2),
                &:nth-of-type(3),
                &:nth-of-type(4),{
                    display: none;
                }
            }

            @media(min-width:$tabletSmall ) {
                &:nth-of-type(1),
                &:nth-of-type(2),
                &:nth-of-type(3),
                &:nth-of-type(4),
                &:nth-of-type(5),
                &:nth-of-type(6) {
                    display: none;
                }
            }

            @media(min-width: $tablet) {
                &:nth-of-type(1),
                &:nth-of-type(2),
                &:nth-of-type(3),
                &:nth-of-type(4),
                &:nth-of-type(5),
                &:nth-of-type(6),
                &:nth-of-type(7),
                &:nth-of-type(8) {
                    display: none;
                }
            }

            @media(min-width: $pc) {
                &:nth-of-type(1),
                &:nth-of-type(2),
                &:nth-of-type(3),
                &:nth-of-type(4),
                &:nth-of-type(5),
                &:nth-of-type(6),
                &:nth-of-type(7),
                &:nth-of-type(8),
                &:nth-of-type(9) {
                    display: none;
                }
            }
        }
    }

    &__show-more {
        width: 300px;
        margin: 32px auto 0;
        justify-content: center;

        @media (max-width: 762px) {
            width: 100%;
            min-height: 60px;
        }
    }
}

.short-filter {
    position: fixed;
    top: 0;
    left: -100%;
    width: 100%;
    bottom: 0;
    max-width: 600px;
    overflow: clip;
    background-color: var(--bg-main);
    z-index: 5;
    margin: 104px 0 0;
    display: flex;
    flex-direction: column;
    gap: 18px;
    transition: all var(--switch-theme-time);
    @media(min-height: 700px){
        overflow-y: auto;
    }
    @media(max-width: $mobile) {
        margin: 150px 0 76px;
        z-index: 4;
        //padding: 104px 0 76px;
    }
    @media(max-width: $mobileExtraSmall) {
        margin: 130px 0 76px;
    }
    @media(min-width: $mobile){
        display: none;
    }
    &._active {
        left: 0;
    }

    .filter__close {
        right: 20px;
        position: absolute;
        top: 16px;
        @media(max-width: $mobile) {
            top: -30px;
        }
        @media(max-width: $mobileExtraSmall) {
            top: -20px;
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        gap: 18px;
        padding: 0 20px;
    }

    &__group {
        display: grid;
        gap: 12px 16px;
        grid-template-columns: repeat(3, 1fr);
        @media(max-width: $mobileSmall) {
            grid-template-columns: repeat(2, auto);
        }

        .checkbox {
            @media(max-width: $mobileSmall) {
                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4) {
                    grid-column: 1 / 2;
                }

                &:nth-child(5),
                &:nth-child(6),
                &:nth-child(7),
                &:nth-child(8) {
                    grid-column: 2 / 3;
                    grid-row: 1/2;
                }

                &:nth-child(1), &:nth-child(5) {
                    grid-row: 1/2;
                }

                &:nth-child(2), &:nth-child(6) {
                    grid-row: 2/3;
                }

                &:nth-child(3), &:nth-child(7) {
                    grid-row: 3/4;
                }

                &:nth-child(4), &:nth-child(8) {
                    grid-row: 4/5;
                }
                label {
                    padding: 4px 4px 4px 26px;
                }
            }
        }
    }

    &__button {
        justify-content: center;
        padding: 10px 0;
    }

    &__box {
        background-color: #0A0A0A;
        padding: 18px 10px;
        margin-top: auto;

        .light & {
            background-color: var(--color-300);
        }

        .button {
            width: 100%;
            justify-content: center;
        }
    }
}