.adcard-main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 10px;
  background-color: var(--color-200);
  border-radius: 24px;

  &.small {
    display: flex;
    flex-direction: column;
    padding: unset;
  }

  &__root {
    padding: 16px 0 16px 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &.small {
      padding: 12px;
      height: 100%;
    }
  }

  &__header {
    display: grid;
    grid-template-columns: 1fr min-content;
    align-items: center;
    gap: 4px;
  }

  &__title {
    width: 110px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    word-break: break-all;
  }

  &__progressbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    border-radius: 8px;
    padding: 12px;
    background-color: var(--color-200);
    color: var(--color-main);
    font-weight: 500;

    &.not_active {
      opacity: 40%;
    }

    &-text {
      display: flex;
      flex-shrink: 0;
    }

    .progressbar {
      margin-left: 4px;
    }

    .progressbar-value {
      position: relative;
      background: var(--black-light);
      width: 100%;
      height: 6px;
      border-radius: 16px;

      .progressbar-current {
        position: absolute;
        border-radius: 16px;
        left: 0;
        top: 0;
        height: 6px;
        background-color: #157a63;
      }
    }
  }

  &__toogle {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    gap: 12px;

    justify-content: space-between;

    &.not_active {
      opacity: 40%;
    }
  }

  &__statistic {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 16px;
  }

  &__link {
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;

    &.not_active {
      opacity: 40%;
    }
  }
}

.stat {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.panel {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.vertical-line {
  border-left: 2px solid var(--color-300); /* Толщина и цвет линии */
  height: 100%;
}
