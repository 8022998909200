@import "src/styles/variables";

.agency-party{
    & > section:not(.breadcrumbs, .agency__banner, .swiper),
    & > div:not(.breadcrumbs, .agency__banner, .swiper) {
        padding-top: 24px;
        padding-bottom: 24px;
        @media(max-width: $mobile) {
            padding-top: 8px;
            padding-bottom: 8px;
        }
    }
    &__top{
        display: flex;
        gap: 32px;
        @media(max-width: $tablet){
            flex-direction: column;
        }
    }

    &__list{
        ul{
            grid-gap: 0 24px;
            @media(max-width: $mobile){
                grid-template-columns: 1fr;
                grid-gap: 0;
            }
        }
        li{
            max-width: 100%;
            span:last-of-type{
                display: inline;
            }
        }
    }

    &__description{
        @media(min-width: $tablet){
            min-width: 560px;
        }
        .model-header__address{
            padding-left: 28px;
            text-decoration: none;
            color: var(--color-main);
            &:before{
                background-color: var(--color-500);
            }
            &:first-of-type{
                padding-right: 28px;
                .icon--map{
                    left: auto;
                    right: 0;
                }
            }
        }
    }
    &__buttons{
        display: flex;
        gap: 4px;
        align-items: center;
        a:first-of-type{
            @media(max-width: $mobileExtraSmall){
                width: 100%;
            }
        }
    }
    .start-videochat__box{
        @media(max-width: $mobileSmall){
            padding: 10px 12px;
        }
        div{
            @media(max-width: $mobileSmall){
                display: flex;
                gap: 4px;
                flex-direction: column;
            }
        }
        span{
            font-size: 32px;
            margin-bottom: 0;
            @media(max-width: $mobile){
                font-size: 24px;
            }
        }
    }
    .tabs{
        margin-bottom: 0;
        max-width: 710px;
        width: 100%;
        @media(max-width: $pc){
            //max-width: 550px;
            max-width: 100%;
        }
        @media(max-width: $tablet){
            //max-width: 480px;
        }
        @media(max-width: $tabletSmall){
            //max-width: 100%;
        }
    }
    //.agency__item{
    //    display: flex;
    //    align-items: center;
    //    h1{
    //        margin-bottom: 0;
    //    }
    //}
    //.agency__banner-img img{
    //    object-position: top;
    //}
}