@import "./src/styles/variables.scss";

.add {
  &__root {
    display: flex;
    flex-direction: row;
    gap: 12px;
    justify-content: space-between;
  }

  &__add {
    display: unset;
    @media (max-width: 915px) {
      display: none;
    }
  }

  &__addad {
    display: none;
    @media (max-width: 915px) {
      display: unset;
    }
  }

  &__text {
    display: unset;
    @media (max-width: 1245px) {
      display: none;
    }
  }

  &__right {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;

    @media (max-width: 1306px) {
      width: auto;
    }
  }

  &__link {
    width: 300px;

    display: unset;
    @media (max-width: 861px) {
      display: none;
    }
  }

  &__select {
    width: max-content;
    color: var(--color-main);

    @media (max-width: 861px) {
      display: none;
    }
  }

  &__import {
    display: flex;

    @media (max-width: 861px) {
      display: none;
    }
  }

  &__import__modal {
    display: none;

    @media (max-width: 861px) {
      display: flex;
    }
  }
}
