.switch-theme {
    display: flex;
    position: relative;
    gap: 4px;
    width: fit-content;
    padding: 2px;
    background-color: var(--color-200);
    transition: background-color var(--switch-theme-time);
    border-radius: 24px;
    height: 32px;

    &__check {
        width: 28px;
        height: 28px;
        display: flex;
        gap: 4px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 1;
        position: relative;
        transition: color 0.1s ease 0s;

        &._active {
            color: #F1C40F;
        }

        &-bar {
            display: inline-block;
            width: 28px;
            background-color: var(--white-main);
            border-radius: 50%;
            position: absolute;
            top: 2px;
            bottom: 2px;
            transition: all 0.3s ease;
            right: 2px;
            left: auto;

            &._active--light {
                left: 2px;
                right: auto;
            }
        }
    }
}