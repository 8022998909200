.party-card {
    &--private{
        cursor: help;
    }

    &__info {
        & > span {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
    }

    &__country{
        display: flex;
        gap: 4px;
        align-items: center;
    }

    .agency-card__heading > div {
        justify-content: space-between;
    }

    .agency-card__counts {
        justify-content: space-between;
    }
}