@import "src/styles/variables";
@import "src/components/ui/tabs/tabs";

.special-label {
    display: none;
}

.rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
    background-color: var(--color-200) !important;
}

.rdp-day__weekend {
    background-color: red !important;
}

.Toastify__toast-container {
    width: 390px !important;

    @media (max-width: $mobileSmall) {
        width: 360px !important;
        left: auto;
        padding-top: 10px;
        padding-left: 16px;
    }
}

.Toastify__toast-body {
    padding: 0 !important;
}

header {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 5;
    padding: 20px 0 60px;
    color: var(--color-main);
    transition: color var(--switch-theme-time);
    background-color: transparent;
    @media (min-width: $mobile) {
        transition: all 0.15s ease 0s;
        border-radius: 0 0 20px 20px;
        border-bottom: 1px solid transparent;
        top: 0;
    }
    @media (max-width: $tablet) {
        padding: 40px 0 30px;
    }
    @media (max-width: $mobile) {
        padding: 40px 0 20px;
    }
    @media (max-width: $mobileExtraSmall) {
        padding: 28px 0 8px;
    }

    &._scroll {
        @media (min-width: $mobile) {
            z-index: 7;
            position: fixed;
            top: 0;
            padding: 0;
            border-bottom-color: var(--color-400);
            background-color: var(--bg-main);
            .user__save {
                display: none;
            }

            .logo {
                img {
                    transform: translateY(-50%);
                }
            }

            .header__bottom,
            .header__top {
                padding: 14px 0;
            }
            .header__top {
                margin-bottom: 0;
            }
        }

        @media (min-width: $tablet) {
            top: -62px;
        }
    }

    .logo {
        margin-right: 12px;
        max-width: 244px;
        width: 100%;
        height: 40px;
        position: relative;
        @media (max-width: $pc) {
            margin-right: 0;
            max-width: 200px;
        }

        @media (max-width: $tablet) {
            max-width: 178px;
        }

        @media (max-width: $mobile) {
            height: 32px;
        }

        @media (max-width: $mobileExtraSmall) {
            max-width: 114px;
        }

        img {
            max-width: 100%;
            width: 100%;
            position: absolute;
            top: 50%;
            transform: translateY(-60%);
        }
    }

    .add-form {
        border: 1px solid var(--green-500);
        @media (max-width: $tablet) and (min-width: $mobile) {
            width: 40px;
            padding: 0;
            justify-content: center;
        }
        @media (max-width: $mobile) {
            padding: 0;
            min-width: 32px;
            align-items: center;
            justify-content: center;
            i {
                width: 20px;
                height: 20px;
            }
            span {
                display: none;
            }
        }
    }
}

.header {
    &__container {
        @media (max-width: $mobileSmall) {
        }

        .select-city {
            @media (max-width: $tablet) and (min-width: $mobile) {
                height: 40px;
            }
            @media (max-width: $mobile) {
                margin-right: auto;
            }
        }
    }

    &__top {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 24px;
        gap: 16px;
        @media (max-width: $tablet) {
            justify-content: space-between;
            margin-bottom: 20px;
            gap: 10px;
        }
        @media (max-width: $mobile) {
            margin-bottom: 0;
        }

        a {
            text-decoration: none;
        }

        .header__language {
            svg {
                width: 16px;
                height: 16px;
            }
        }
    }

    &__bottom {
        width: 100%;
        display: flex;
        gap: 10px;
        @media (max-width: $mobile) {
            justify-content: space-between;
            height: 32px;
        }
    }

    &__filter--place {
        .icon {
            pointer-events: none;
        }
    }

    &__filters {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 14px;

        .count {
            position: absolute;
            color: var(--color-main-reverse);
            top: -2px;
            right: -2px;
            width: 14px;
            height: 14px;
            background-color: var(--color-main);
            border-radius: 50%;
            font-size: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__search--label {
        @media (max-width: $pc) {
            width: 160px;
        }
        @media (max-width: $tablet) {
            width: 100%;
        }
    }

    &__filter {
        position: relative;
        text-decoration: none;
        background-color: var(--black-light);
        transition: background-color var(--switch-theme-time),
        color var(--switch-theme-time);

        &:hover,
        &:focus-within {
            background-color: var(--white-400);
        }

        .light & {
            &:hover,
            &:focus-within {
                background-color: var(--black-400);
            }
        }
    }

    &__reset {
        color: var(--color-600);
        transition: color 0.2s ease 0s;

        &:hover,
        &:focus-within {
            color: var(--color-800);
        }

        @media (max-width: $pc) {
            margin-left: 4px;
        }
    }

    &__tabs {
        display: flex;
        align-items: center;
        @media (max-width: $tabletSmall) {
            display: none;
        }
    }

    &__tab {
        padding: 7px 16px;
        color: var(--color-700);
        position: relative;
        transition: all 0.15s ease;
        width: max-content;

        &:before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 2px;
            background-color: var(--color-main);
            transition: inherit;
            transform: scale(0);

            .header--type-one & {
                content: unset;
            }
        }

        &:hover {
            color: var(--color-main);

            .header--type-one & {
                text-decoration: underline;
            }

            &:before {
                transform: scale(1);
            }
        }

        &:after {
            top: 2px;
            right: 4px;
        }

        &._active {
            color: var(--color-main);

            &:before {
                transform: scale(1);
            }
        }

        @media (max-width: $pc) {
            padding: 7px 10px;
        }

        @media (max-width: $tablet) {
            padding: 6px 6px;
        }

        @media (max-width: $tabletSmall) {
            padding: 8px 6px;
        }
    }

    &--type-two {
        @media (max-width: $mobile) {
            border-bottom: 1px solid var(--color-300);
            padding-bottom: 8px;
            ~ main {
                padding-top: 146px;
            }
        }

        .header__top {
            @media (max-width: $mobile) {
                margin-bottom: 20px;
            }
            @media (max-width: $mobileExtraSmall) {
                margin-bottom: 12px;
            }
        }

        .header__tabs {
            position: relative;
            margin-left: 32px;
            transition: all 0.15s ease;
            width: max-content;
            display: flex;
            align-items: center;

            &:after {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: 2px;
                background-color: var(--color-500);
            }

            @media (max-width: $tablet) {
                margin-left: 8px;
            }

            a {
                height: 100%;
                text-decoration: none;
                display: flex;
                align-items: center;
                gap: 4px;
            }
        }
    }
}

.user {
    &__wrapper {
        display: flex;
        gap: 8px;
        align-items: center;
        margin-left: auto;
        position: relative;
        @media (max-width: $tablet) and (min-width: $mobile) {
            .button_outline--black {
                height: 40px;
            }
        }
        @media (max-width: $mobile) {
            margin-left: 0;
        }
    }

    &__notification:after,
    &__favorite:after {
            background-color: var(--green-main);
            color: var(--white-main);
            top: 6px;
            right: 2px;
    }

    &__favorite:after{
        @media(max-width: $tablet) {
            top: 22px;
            transform: translateX(20px);
            right: 50%;
        }
    }

    &__log-out {
        &:hover,
        &:focus-within {
            color: var(--color-600);
        }
    }
}

.users {
    transition: all 0.2s ease-in-out 0s;
    position: fixed;
    top: 0;
    bottom: 0;
    right: -100%;
    background-color: var(--bg-main);
    padding: 40px 24px 30px 16px;
    max-width: 400px;
    width: 100%;
    z-index: 3;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    @media (max-width: $mobile) {
        right: auto;
        left: -100%;
        padding: 40px 16px 90px;
    }

    @media (max-width: $mobileExtraSmall) {
        max-width: 100%;
        padding: 16px 16px 90px;
    }

    &._active {
        right: 0;
        @media (max-width: $mobile) {
            right: auto;
            left: 0;
        }
    }

    &__nav {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 12px;
        margin-bottom: 40px;

        .menu__link {
            width: 100%;
            height: 100%;
            border-radius: 16px;
            padding: 24px 0;
            font-size: 13px;

            &:hover,
            &:focus-within {
                i:after {
                    background-color: var(--green-main);
                }
            }

            @media (max-width: $mobile) {
                font-size: 13px;
            }
        }
    }

    &__links {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;

        .button {
            font-size: 20px;

            &:hover,
            &:focus-within {
                color: var(--color-600);
            }
        }
    }

    &__bottom {
        margin: auto auto 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        width: 100%;
    }

    .user__log-out {
        color: var(--red-main);
        gap: 10px;
        min-height: 28px;
        font-size: 13px;

        i:after {
            background-color: var(--red-main);
        }
    }

    .icon-menu {
        margin-bottom: 16px;
        @media(min-width: $mobile) {
            margin-left: auto;
        }
    }
}

.menu {
    &__body {
        position: fixed;
        bottom: 0;
        z-index: 5;
        background-color: var(--bg-main);
        left: 0;
        right: 0;
        display: flex;
        gap: 2px;
        justify-content: space-between;
        padding: 10px;
        border-top: 1px solid var(--color-300);
        @media (min-width: $mobile) {
            display: none;
        }

        .menu__wallet {
            padding: 2px 4px;
        }

        .user__favorite:after{
            top: 6px;
            transform: translateX(18px);
        }
    }

    &__link {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        width: 56px;
        height: 56px;
        gap: 2px;
        min-height: auto;
        padding: 2px;
        background-color: var(--color-300);
        transition: color 0.2s ease 0s;
        outline: none;

        &:hover,
        &:focus-within {
            color: var(--green-main);
        }

        [data-counter] {
            &:after {
                top: 0;
                right: 0;
            }
        }
    }

    &__wallet {
        padding: 6px 8px;
        color: var(--green-main);
        border-radius: 6px;
        border: 1px solid var(--color-200);
        font-size: 15px;
    }
}

.search {
    position: relative;

    &__label {
        cursor: text;
        border-radius: 8px;
        padding: 4px 4px 4px 12px;
        min-height: 40px;
        background-color: var(--black-light);
        transition: background-color var(--switch-theme-time);
        display: flex;
        align-items: center;

        &:hover,
        &:focus-within {
            background-color: var(--color-400);
        }
    }

    &__input {
        padding: 0 46px 0 0;
        min-height: 0;
        border-radius: 0;
        background-color: transparent;
        outline: none;

        &::placeholder {
            color: var(--color-600);
            transition: color var(--switch-theme-time);
        }

        @media (max-width: $mobile) {
            font-size: 13px;
            padding: 0 40px 0 0;
        }
    }

    &__close {
        position: absolute;
        top: 50%;
        right: 44px;
        transform: translateY(-50%);
        @media (max-width: $mobile) {
            border-radius: 8px;
            svg {
                width: 16px;
                height: 16px;
            }
        }
    }

    &__icon {
        position: absolute;
        top: 50%;
        right: 4px;
        transform: translateY(-50%);
        background-color: var(--color-400);
        transition: background-color var(--switch-theme-time);
        @media (max-width: $mobile) {
            border-radius: 8px;
            svg {
                width: 16px;
                height: 16px;
            }
        }
    }
}
