@import "src/styles/variables";

.tabs {
    &__content {
        &._active {
            display: flex;
        }
    }
}

.model {
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    @media (max-width: $mobile) {
        padding-bottom: 30px;
        gap: 24px;
    }

    ~ .new-girls__container {
        padding-bottom: 130px;
        @media (max-width: $mobile) {
            padding-bottom: 24px;
        }
    }

    .model__title {
        margin-bottom: 20px;
    }

    &__review {
        display: flex;
        gap: 4px;
        padding: 4px;
        border-radius: 4px;
        background-color: var(--color-200);
        align-items: center;
        text-decoration: none;

        > span:not(.rating) {
            white-space: nowrap;
            @media (max-width: $mobile) {
                display: none;
            }
        }
    }

    &__reviews {
        grid-row: 3 / 4;
        grid-column: 1/3;
        @media (max-width: $tablet) {
            grid-column: 1/2;
        }

        @media (max-width: $tablet) and (min-width: $tabletSmall) {
            grid-column: 1/2;
            grid-row: 4 / 5;
        }

        &__top {
            @media (max-width: $tabletSmall) {
                > div {
                    width: 100%;

                    .button {
                        width: 100%;
                    }
                }
            }
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        width: 276px;
        height: max-content;
        @media (min-width: $tabletSmall) {
            padding: 16px;
            background-color: var(--color-200);
            border-radius: 8px;
        }

        @media (max-width: $tablet) {
            width: 450px;
        }

        @media (max-width: $tabletSmall) and (min-width: $mobileSmall) {
            grid-column: 2 / 3;
        }
        @media (max-width: $tabletSmall) {
            width: 100%;
        }

        p {
            margin-bottom: 24px;
        }

        .girl-card__icons {
            margin-bottom: 12px;
            @media(max-width: $mobile) {
                height: 28px;
            }

            span {
                background-color: var(--green-400);
                padding: 2px;
                @media (min-width: $tabletSmall) {
                    display: none;
                }

                span {
                    @media (max-width: $tabletSmall) and (min-width: $mobileSmall) {
                        display: none;
                    }
                }
            }
        }

        &-box {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        &-bottom {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
            @media (max-width: $tabletSmall) {
                gap: 12px;
            }

            .girl-card__speak {
                svg {
                    width: 17px;
                    height: 12px;
                }
            }
        }
    }

    &-card {
        @media(max-width: $tabletSmall) {
            max-width: 482px;
        }

        &__parties {
            @media(max-width: $tabletSmall) {
                max-width: 100%;
            }
        }

        &__wrapper {
            display: flex;
            gap: 32px;
            @media(max-width: $pc) {
                gap: 24px;
            }
            @media(max-width: $mobile) {
                flex-direction: column;
            }

            .party-card {
                width: 100%;
                max-width: 482px;
            }
        }
    }

    .schedule-table {
        @media (max-width: $tablet) {
            flex-direction: column;
        }

        &__work-time {
            @media (max-width: $tablet) {
                width: 100%;
            }
        }

        &__wrapper {
            @media (max-width: $tablet) {
                max-width: 100%;
            }
        }
    }

    &__extras {
        display: grid;
        gap: 10px;
        grid-template-columns: auto auto max-content auto;
        @media (max-width: $pc) {
            gap: 12px;
            grid-template-columns: repeat(2, 1fr);
        }
        @media (max-width: $mobile) {
            grid-template-columns: 1fr;
        }

        .button {
            min-height: 62px;
            padding: 10px 12px;
            gap: 8px;
            justify-content: center;

            > span {
                @media (max-width: $mobile) {
                    font-size: 14px;
                }
            }

            svg {
                min-width: 32px;
                min-height: 32px;
            }

            @media (max-width: $tabletSmall) {
                justify-content: flex-start;
            }
        }
    }

    &__buttons {
        display: flex;
        align-items: center;
        gap: 4px;
        width: 100%;

        .button {
            justify-content: center;
        }
    }

    &__interactions {
        display: flex;
        flex-direction: column;
        gap: 48px;
        @media (max-width: $tabletSmall) and (min-width: $mobileSmall) {
            flex-direction: row;
        }
        @media (max-width: 844px) {
            flex-direction: column;
        }
        @media (max-width: $mobile) {
            gap: 20px;
            flex-direction: column;
        }
        @media (max-width: $mobileSmall) {
            gap: 12px;
        }

        > .button {
            @media (max-width: $tabletSmall) {
                font-size: 12px;
                padding: 10px 4px;
            }
            @media (max-width: $mobileExtraSmall) {
                font-size: 11px;
                gap: 4px;
            }

            span {
                font-size: 13px;
                height: auto;
                @media (max-width: $tabletSmall) {
                    font-size: 12px;
                }
                @media (max-width: $mobileExtraSmall) {
                    font-size: 11px;
                    height: auto;
                }
            }
        }
    }

    &-header {
        color: var(--color-main);
        position: relative;

        &__breadcrumbs {
            margin-right: auto;
            margin-bottom: 16px;
            @media (min-width: $mobile) {
                height: 32px;
            }
        }

        &__back {
            margin-right: auto;
            margin-bottom: 16px;
            display: flex;
            width: fit-content;
            flex-direction: row;
            align-items: center;
            gap: 8px;
            text-decoration: none;
            @media (min-width: $mobile) {
                height: 32px;
                padding-right: 280px;
            }
        }

        &__body {
            display: grid;
            grid-template-columns: auto 1fr auto;
            grid-template-rows: 1fr auto;
            align-items: center;
            grid-gap: 0 12px;
            @media (max-width: $tablet) {
                grid-gap: 12px;
            }
            @media (max-width: $tabletSmall) {
                grid-template-columns: auto 1fr;
            }

            &.no-stories {
                grid-template-columns: 1fr auto;
            }
        }

        &__interactions {
            display: flex;
            gap: 8px;
            margin-left: auto;
            justify-content: flex-end;
            @media (min-width: $mobile) {
                position: absolute;
                top: 0;
                right: 0;
            }
            @media (max-width: $mobile) {
                width: 60%;
            }
            @media (max-width: $mobileExtraSmall) {
                width: 58%;
            }
        }

        &__wrapper {
            display: flex;
            gap: 16px;
            flex-direction: column;
            grid-row: 1 / 3;
            @media (min-width: $tabletSmall) {
                padding: 8px 0;
            }
        }

        &__stories {
            width: 106px;
            height: 106px;
            margin-bottom: 0;
            grid-column: 1/2;
            grid-row: 1 / 4;
            @media (max-width: $tablet) {
                grid-row: 1 / 3;
            }
            @media (max-width: $mobile) {
                width: 74px;
                height: 74px;
            }

            &:before {
                width: 104px;
                @media (max-width: $mobile) {
                    width: 72px;
                }
            }

            .stories-circle__image {
                min-width: 100px;
                width: 100px;
                @media (max-width: $mobile) {
                    min-width: 68px;
                    width: 68px;
                }
            }
        }

        &__box {
            display: flex;
            gap: 16px;
            align-items: center;
            @media (max-width: $tablet) {
                grid-row: 1 / 2;
                //grid-column: 2 / 3;
                gap: 8px;
            }
            @media (max-width: $tabletSmall) {
                grid-row: 1 / 3;
                flex-wrap: wrap;
            }
            @media (max-width: $mobile) {
                gap: 4px;
                grid-row: 1 / 2;
            }

            &-info {
                display: flex;
                gap: 8px;
                align-items: center;
                width: 100%;
            }

            &-info-edit {
                display: flex;
                gap: 8px;
                flex-direction: row;
                align-items: center;
                width: 100%;

                @media (max-width: $mobile) {
                    justify-content: space-between;
                }
            }
        }

        &__bottom {
            display: flex;
            align-items: center;
            gap: 12px;
            grid-column: 2 / 4;
            grid-row: 2 / 3;
            line-height: 1;
            @media (max-width: $tablet) {
                grid-row: 2 / 3;
            }
            @media (max-width: $tabletSmall) {
                grid-column: 1 / 4;
                grid-row: 3/4;
            }
            @media (max-width: $mobile) {
                grid-column: 1 / 3;
                grid-row: 3/ 4;
            }

            .no-stories & {
                grid-column: 1 / 3;
            }
        }

        &__distance {
            text-decoration: none;
            width: max-content;
            @media (max-width: $mobile) {
                min-width: 90px;
            }
        }

        &__address,
        &__distance,
        &__last-visit {
            position: relative;
            padding-left: 20px;

            i {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        &__last-visit {
            i {
                width: 14px;
                height: 14px;
            }
        }

        &__status {
            min-width: 168px;
            width: 168px;
            height: 60px;
            position: relative;

            img {
                position: absolute;
                object-fit: contain;
                object-position: center center;
                width: 100%;
            }

            @media (max-width: $tablet) {
                margin-right: auto;
            }

            @media (max-width: $tabletSmall) {
                height: 52px;
                margin-top: -22px;
            }

            @media (max-width: $mobile) {
                min-width: 94px;
                width: 94px;
                height: 34px;
                margin: 0;
            }
        }

        &__name,
        &__address {
            line-height: 20px;
        }

        &__name {
            font-size: 28px;
            line-height: 115%;
            margin-bottom: 0;
            @media (max-width: $mobile) {
                font-size: 24px;
            }
        }

        &__age {
            padding: 4px 8px;
            display: flex;
            align-items: center;
            gap: 4px;
            background-color: var(--color-400);
            color: var(--color-600);
            border-radius: 8px;
            min-height: 36px;

            span {
                font-size: 24px;
                color: var(--color-main);
                @media (max-width: $mobile) {
                    font-size: 14px;
                    flex-direction: column;
                    gap: 0;
                    padding: 0 4px;
                }
            }

            @media (max-width: $mobile) {
                font-size: 13px;
                flex-direction: column;
                gap: 0;
                padding: 0 4px;
                line-height: 120%;
                justify-content: center;
            }
        }

        &__buttons {
            grid-row: 1/2;
            grid-column: 3/4;

            .button {
                height: 100%;
            }

            @media (max-width: $tabletSmall) {
                grid-row: 1 / 4;
                grid-column: 3 / 4;
            }

            @media (max-width: $mobile) {
                grid-row: 4 / 5;
                grid-column: 1 / 3;
            }

            .no-stories & {
                grid-column: 2 / 3;
                @media (max-width: $mobile) {
                    grid-column: 1 / 3;
                }
            }

            &-group {
                display: flex;
                gap: 4px;
                height: 100%;
            }
        }
    }

    &-main {
        display: grid;
        grid-template-columns: 482px 1fr;
        grid-gap: 40px 32px;
        @media (max-width: $pc) {
            grid-template-columns: 400px 1fr;
            grid-gap: 40px 18px;
        }

        @media (max-width: $tabletSmall) {
            grid-template-columns: minmax(286px, 1fr) 258px;
        }

        @media (max-width: $mobileSmall) {
            grid-template-columns: 100%;
            grid-gap: 24px 18px;
        }

        &__sliders {
            @media (max-width: $tabletSmall) and (min-width: $mobile) {
                grid-column: 1/2;
            }
        }

        &__slider-video {
            min-height: auto;
            border-radius: 8px;
            height: 100%;

            video {
                object-fit: contain;
            }
        }

        &__private,
        &__thumb {
            .swiper-slide {
                border-radius: 8px;
                overflow: hidden;
            }
        }

        > .tabs {
            @media (max-width: $tabletSmall) {
                grid-column: 1 / 2;
                grid-row: 1/2;
            }

            .tabs__content {
                &._active {
                    display: block;
                }

                &._initial {
                    .swiper-slide:first-child {
                        display: none;
                    }
                }
            }

            span {
                @media (max-width: $mobile) {
                    display: none;
                }
            }
        }

        &__slider {
            margin-bottom: 24px;
            aspect-ratio: 3 / 4;

            & .stories__slide {
                &:not(.model-main__private-slide) {
                    cursor: zoom-in;

                    .video {
                        height: 100%;
                        border-radius: 0;
                    }

                    @media (max-width: $mobileSmall) {
                        cursor: default;
                    }
                }
            }
        }

        &__thumb {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            .swiper-button-prev,
            .swiper-button-next {
                display: flex;
            }

            .swiper {
                margin: 0 30px;
                width: 100%;
                @media (min-width: $tabletSmall) {
                    max-width: 422px;
                    height: 108px;
                }
                @media (max-width: $mobileSmall) {
                    height: 108px;
                }
            }

            .swiper-slide {
                cursor: pointer;
                border: 2px solid transparent;
                @media (max-width: $tabletSmall) {
                    aspect-ratio: 3 / 4;
                }

                &-thumb-active {
                    border: 2px solid var(--color-main);
                }
            }

            .swiper-button-prev,
            .swiper-button-next {
                transform: unset;
                height: 100%;
                width: 24px;
                top: 0;
                border-radius: 8px;

                i {
                    position: absolute;
                    aspect-ratio: 1/1;
                    width: 28px;
                }
            }

            .swiper-button-prev {
                left: 0;
            }

            .swiper-button-next {
                right: 0;
            }
        }

        .model-card {
            @media (max-width: $tabletSmall) {
                max-width: 482px;
                grid-column: 1 / 2;
            }
            @media (max-width: $mobile) {
                max-width: 100%;
                grid-column: 1 / 3;
                grid-row: 6/7;
            }
            @media (max-width: $mobileSmall) {
                grid-column: 1 / 2;
            }
        }

        &__private {
            grid-column: 1 / 2;
            width: 100%;
            position: relative;
            height: 170px;
            @media (max-width: $tabletSmall) and (min-width: $mobileSmall) {
                grid-column: 1 / 3;
                grid-row: 3/4;
            }
            @media (max-width: $mobile) {
                height: 184px;
            }

            &:before {
                content: "";
                position: absolute;
                width: 138px;
                height: 264px;
                top: calc(50%);
                transform: translateY(-50%);
                right: -65px;
                background: var(--color-main-reverse);
                filter: blur(32px);
                border-radius: 64px 0 0 64px;
                z-index: 2;
                pointer-events: none;
            }

            &-slide {
                aspect-ratio: 3/4;
                position: relative;
                cursor: pointer;

                //i:after {
                //    background-color: var(--white-main);
                //}

                div,
                > i {
                    position: absolute;
                }

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    object-position: center;
                }

                .icon--video-triangle {
                    right: 8px;
                    top: 8px;
                }

                &:hover,
                &:focus-within {
                    div:not(.icon--video-triangle) {
                        opacity: 1;
                        visibility: visible;
                    }
                }

                div:not(.icon--video-triangle) {
                    visibility: hidden;
                    opacity: 0;
                    top: 50%;
                    left: 50%;
                    gap: 6px;
                    z-index: 1;
                    transform: translate(-50%, -50%);
                    transition: all 0.1s ease-in 0s;
                }
            }

            .swiper-button-prev,
            .swiper-button-next {
                height: 40px;
                transform: translateY(-50%);
                width: 28px;
                border-radius: 8px;
                display: flex;

                i {
                    position: absolute;
                    aspect-ratio: 1/1;
                    width: 18px;
                }

                &[disabled] {
                    opacity: 0;
                }
            }
        }

        .model-card {
            @media (max-width: $tabletSmall) {
                grid-row: 6/7;
            }
        }

        &__wrapper {
            grid-column: 1/3;
            display: flex;
            flex-direction: column;
            gap: 48px;
            @media (max-width: $tablet) {
                grid-column: 1/2;
            }
            @media (max-width: $mobileSmall) {
                gap: 24px;
            }

            .model__buttons {
                @media (max-width: $mobile) {
                    height: 40px;
                }

                .button {
                    height: 100%;
                    width: 100%;
                }

                a {
                    height: 100%;
                }

                .model-btn__videochat {
                    min-width: 60px;
                    @media (min-width: $tablet) {
                        width: 100%;
                    }
                    @media (max-width: $mobile) {
                        min-width: 40px;
                    }
                }

                .model-btn__tel {
                    min-width: 60px;
                    @media (min-width: $tablet) {
                        width: 100%;
                    }
                    @media (max-width: $mobile) {
                        min-width: 40px;
                    }
                }
            }
        }
    }

    &-content {
        display: grid;
        grid-template-columns: 276px 1fr;
        grid-template-rows: min-content;
        grid-gap: 48px 32px;
        grid-column: 2 / 3;
        grid-row-start: 1;
        grid-row-end: 5;
        @media (max-width: $pc) {
            grid-gap: 48px 12px;
        }

        @media (max-width: $tablet) {
            grid-template-columns: 1fr;
            grid-gap: 24px;
        }

        @media (max-width: $tabletSmall) {
            grid-row-start: 2;
        }

        @media (max-width: $tabletSmall) and (min-width: $mobileSmall) {
            grid-column: 1 / 3;
            grid-gap: 24px 18px;
        }

        @media (max-width: $mobileSmall) {
            grid-column: 1/2;
        }

        &__top {
            display: flex;
            gap: 32px;
        }

        .girl-card__icons,
        .girl-card__tags {
            height: auto;
        }

        .girl-card__icons {
            @media (max-width: $tabletSmall) and (min-width: $mobileSmall) {
                display: none;
            }
            //background-color: red;
            span {
                padding: 4px 8px 4px 4px;
                @media (max-width: $mobileSmall) {
                    padding: 2px 4px 2px 2px;
                }

                span {
                    background-color: transparent;
                    color: var(--green-main);
                    @media (max-width: $mobile) {
                        font-size: 12px;
                    }
                }
            }
        }

        .girl-card__icon {
            background-color: var(--green-300);
            display: flex;
            gap: 8px;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            min-height: 28px;
            min-width: 28px;
            cursor: help;
            color: var(--green-main);

            .light & {
                background-color: var(--green-300);
            }

            i:after {
                background-color: var(--green-main);
            }
        }

        .tabs {
            &__nav {
                display: none;
                @media (max-width: $mobileSmall) {
                    display: flex;
                    grid-column: 1/2;
                    grid-row: 1/2;
                    margin-bottom: -8px;
                }
            }

            &__panel {
                display: flex;
                @media (max-width: $mobileSmall) {
                    display: none;
                }
                @media (max-width: $mobileSmall) {
                    grid-column: 1/2;
                    grid-row: 2/3;
                    &--active {
                        display: flex;
                    }
                }
            }
        }

        .model__info {
            @media (max-width: $tabletSmall) and (min-width: $mobileSmall) {
                display: none;
            }
        }

        &__cards {
            display: grid;
            gap: 24px;
            @media (min-width: $tabletSmall) {
                grid-template-columns: minmax(auto, 400px) 1fr;
            }

            @media(min-width: $pc) {
                gap: 32px;
                grid-template-columns: minmax(auto, 482px) 1fr;
            }

            @media (max-width: $mobile) {
                grid-template-columns: 1fr;
            }
        }
    }

    &__tags {
        margin-bottom: 12px;

        .girl-card__tag {
            font-size: 12px;
            padding: 8px;
            color: var(--color-main);
            background-color: var(--color-400);
            min-height: 30px;

            .light & {
                background-color: var(--color-400);
            }
        }
    }

    .girl-card__tags span:nth-of-type(4) {
        @media (max-width: $mobile) {
            display: flex;
        }
    }

    &__params {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 12px 4px;
        justify-content: space-between;
        margin-bottom: 16px;
    }

    &__param {
        display: flex;
        flex-direction: column;
        gap: 4px;
        font-size: 13px;

        span {
            font-size: 15px;
            color: var(--color-main);

            &:first-letter {
                text-transform: uppercase;
            }
        }
    }

    &__lang {
        display: flex;
        gap: 12px;

        .girl-card__speak {
            margin-left: 0;
        }
    }

    .body-shape {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 12px;
        gap: 2px;
        padding: 14px;
        width: 70px;
        height: 90px;
        background-color: var(--color-300);
        position: relative;

        .light & {
            background-color: var(--color-200);
        }

        span {
            min-height: 13px;
            padding: 0 2px;
            background-color: var(--color-400);
            border-radius: 4px;
            color: var(--color-main);
            font-size: 11px;
            font-weight: 800;
            display: flex;
            line-height: 1;
            align-items: center;

            &:last-of-type {
                margin-top: auto;
            }
        }

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -57%);
        }
    }

    &__serfices {
        display: flex;
        flex-direction: column;
        gap: 16px;
        @media (max-width: $tablet) {
            grid-row: 2/3;
        }
        @media (max-width: $tabletSmall) and (min-width: $mobileSmall) {
            grid-row: 1/2;
        }

        .girl-card__icons {
            @media (max-width: $mobileSmall) {
                display: none;
            }
        }

        &-box {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            justify-items: start;
            align-items: center;

            li {
                transition: background-color 0.1s ease-in 0s;
                max-width: 270px;

                &._active {
                    background-color: var(--color-400);
                    padding: 8px;
                    border-radius: 8px;

                    &:before {
                        top: 6px;
                    }
                }
            }
        }
    }

    &__counters {
        display: flex;
        justify-content: center;
        gap: 8px;
        font-size: 24px;
        color: var(--color-main);
        @media (max-width: $mobile) {
            font-size: 18px;
        }
    }

    &__counter {
        min-height: 70px;
        padding: 10px;
        border-radius: 12px;
        display: flex;
        gap: 4px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-color: var(--color-200);
        width: 286px;

        span {
            color: var(--color-600);
        }
    }

    .agency-card {
        padding: 20px;
        background-color: var(--white-200);
        gap: 20px;
        @media (max-width: $mobile) {
            padding: 16px;
        }

        &__content {
            padding: 0;
            background-color: transparent;
        }

        &__img {
            overflow: hidden;
            border-radius: 20px;
            background: url("/assets/img/agency-cover.webp") no-repeat center center/cover;
        }
    }
}

.lg-outer .lg-object {
    object-fit: contain;
    width: 100% !important;
    height: 100% !important;
}

.lg-backdrop {
    background-color: rgba(0, 0, 0, 0.96);
}

.current-model {
    &__back {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        margin-bottom: 12px;
        text-decoration: none;
        width: fit-content;

        &:hover {
            text-decoration: underline;
        }
    }
}
