@import "./src/styles/variables.scss";

footer {
  padding: 60px 0;
  font-size: 16px;
  background-color: var(--bg-footer-main);
  position: relative;
  @media (max-width: $tabletSmall) {
    padding: 40px 0 0;
  }

  h3 {
    font-size: 16px;
  }

  a {
    text-decoration-thickness: 1px;
    text-underline-offset: 4px;
    text-decoration-color: transparent;
    transition: all 0.2s ease 0s;

    &._disabled {
      cursor: default;
      color: var(--color-400);
      pointer-events: none;
    }

    &:not(._disabled) {
      &:focus-within,
      &:hover {
        text-decoration-color: var(--color-600);
        outline: none;
      }
    }
  }

  .logo {
    text-decoration: none;
    width: 280px;
    margin-right: 120px;
    display: flex;
    gap: 14px;
    flex-direction: column;
    align-items: center;
    @media (max-width: $pc) {
      margin-right: 30px;
    }
    @media (max-width: $tablet) and (min-width: $tabletSmall) {
      width: 200px;
      margin-right: 20px;
    }
    @media (max-width: $tabletSmall) {
      margin-right: 0;
    }
    img {
      width: 100%;
      max-width: 262px;
    }
    p {
      line-height: 120%;
      @media (max-width: $pc) {
        text-wrap: pretty;
      }
    }
  }
}

.footer {
  &__container {
    display: grid;
    grid-template-columns: auto 1fr 430px;
    @media (max-width: $tablet) {
      grid-template-columns: auto 1fr 420px;
    }
    @media (max-width: $tabletSmall) {
      display: flex;
      flex-wrap: wrap;
      gap: 40px;
    }
  }

  &__title {
    font-weight: 600;
    margin-bottom: 36px;
    position: relative;
    font-family: "Inter";
    @media (max-width: $tabletSmall) {
      max-width: 400px;
    }
    svg {
      width: 32px;
      height: 32px;
      position: absolute;
      right: 0;
      top: -16px;
    }
  }
  &-categories {
    &__body {
      display: grid;
      gap: 20px 56px;
      grid-template-columns: repeat(3, auto);
      grid-template-rows: repeat(5, auto);
      grid-auto-flow: column;
      justify-content: start;
      align-items: center;
      @media (max-width: $pc) {
        gap: 20px 32px;
      }
      @media (max-width: $tablet) {
        grid-template-columns: repeat(2, auto);
        grid-template-rows: repeat(8, auto);
        align-items: center;
      }
      @media (max-width: $tabletSmall) {
        grid-template-columns: repeat(3, auto);
        grid-template-rows: repeat(5, auto);
      }
      @media (max-width: $mobileExtraSmall) {
        grid-template-columns: repeat(2, auto);
        grid-template-rows: repeat(8, auto);
        gap: 20px 40px;
      }
    }
  }

  &-info {
    position: relative;
    z-index: 1;
    padding-left: 40px;
    @media (max-width: $tabletSmall) {
      padding: 40px 0;
      margin-top: 0;
      width: 100%;
    }

    &:after {
      content: "";
      z-index: -1;
      background-color: var(--bg-footer-second);
      transition: background-color var(--switch-theme-time);
      position: absolute;
      top: -60px;
      bottom: -60px;
      left: 0;
      right: calc(-100% - 100vw);
      user-select: none;
      touch-action: none;
      @media (max-width: $tabletSmall) {
        top: 0;
        bottom: 0;
        left: -50%;
        right: -50%;
      }
    }
    &__top-links,
    &__bottom-links {
      display: flex;
      align-items: flex-start;
      gap: 16px;
      font-size: 14px;
      margin-bottom: 28px;
      flex-wrap: wrap;
      @media (max-width: $mobileExtraSmall) {
        gap: 12px;
      }
    }

    &__bottom-links {
      display: flex;
      flex-wrap: wrap;

      a {
        text-decoration: underline;
        text-decoration-thickness: 1px;
        text-underline-offset: 6px;
        color: var(--color-500);
        transition: all var(--switch-theme-time);
        &:hover,
        &:focus-within {
          text-decoration-color: transparent;
        }
      }
    }
  }
}

.payment-methods {
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
  color: var(--color-main);
  transition: color var(--switch-theme-time);
  &__icon {
    width: 45px;
    height: 30px;
    padding: 3px;
    border-radius: 4px;
    background-color: var(--color-200);
    transition: background-color var(--switch-theme-time);
    svg {
      width: 100%;
      height: 100%;
    }
  }
}
