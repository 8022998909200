.icon-payment {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    cursor: help;
    width: 100%;
    height: 100%;
    display: inline-block;
    background-color: transparent;
    &.alipay {
        background-image: url("/assets/img/icons/payment/dark/alipay.svg");
        .light & {
            background-image: url("/assets/img/icons/payment/light/alipay.svg")
        }
    }

    &.american-express {
        background-image: url("/assets/img/icons/payment/dark/american-express.svg");
        .light & {
            background-image: url("/assets/img/icons/payment/light/american-express.svg")
        }
    }

    &.apple-pay {
        background-image: url("/assets/img/icons/payment/dark/apple-pay.svg");
        .light & {
            background-image: url("/assets/img/icons/payment/light/apple-pay.svg")
        }
    }

    &.bitcoin {
        background-image: url("/assets/img/icons/payment/dark/bitcoin.svg");
        .light & {
            background-image: url("/assets/img/icons/payment/light/bitcoin.svg")
        }
    }

    &.discover {
        background-image: url("/assets/img/icons/payment/dark/discover.svg");
        .light & {
            background-image: url("/assets/img/icons/payment/light/discover.svg")
        }
    }

    &.elo {
        background-image: url("/assets/img/icons/payment/dark/elo.svg");
        .light & {
            background-image: url("/assets/img/icons/payment/light/elo.svg")
        }
    }

    &.google-pay {
        background-image: url("/assets/img/icons/payment/dark/google-pay.svg");
        .light & {
            background-image: url("/assets/img/icons/payment/light/google-pay.svg")
        }
    }

    &.hipercard {
        background-image: url("/assets/img/icons/payment/dark/hipercard.svg");
        .light & {
            background-image: url("/assets/img/icons/payment/light/hipercard.svg")
        }
    }

    &.jcb {
        background-image: url("/assets/img/icons/payment/dark/jcb.svg");
        .light & {
            background-image: url("/assets/img/icons/payment/light/jcb.svg")
        }
    }

    &.maestro {
        background-image: url("/assets/img/icons/payment/dark/maestro.svg");
        .light & {
            background-image: url("/assets/img/icons/payment/light/maestro.svg")
        }
    }

    &.master-card {
        background-image: url("/assets/img/icons/payment/dark/master-card.svg");
        .light & {
            background-image: url("/assets/img/icons/payment/light/master-card.svg")
        }
    }

    &.paypal {
        background-image: url("/assets/img/icons/payment/dark/paypal.svg");
        .light & {
            background-image: url("/assets/img/icons/payment/light/paypal.svg")
        }
    }

    &.union-pay {
        background-image: url("/assets/img/icons/payment/dark/union-pay.svg ");
        .light & {
            background-image: url("/assets/img/icons/payment/light/union-pay.svg ")
        }
    }

    &.visa {
        background-image: url("/assets/img/icons/payment/dark/visa.svg");
        .light & {
            background-image: url("/assets/img/icons/payment/light/visa.svg")
        }
    }

    &.webmoney {
        background-image: url("/assets/img/icons/payment/dark/webmoney.svg");
        .light & {
            background-image: url("/assets/img/icons/payment/light/webmoney.svg")
        }
    }
}

