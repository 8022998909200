
.rating-stars {
    width: 66px;
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    > div{
        width: 100%;
        height: 100%;
    }

    .rr--box:focus, .rr--box:focus-visible, .rr-reset:focus-visible, .rr-reset:focus {
        outline: none;
        box-shadow: none
    }

    .rr--fx-colors .rr--svg {
        transition-duration: .2s;
        transition-timing-function: var(--rr--easing);
        transition-property: background-color, border-color, fill, stroke
    }

.rr--svg-stop-1 {
    stop-color: var(--color-main)
}

.rr--hf-svg-on .rr--svg, .rr--on .rr--svg{
    fill: var(--color-main) ;
    .rating-stars--gold & {
        fill: var(--gold-second) !important;
    }
}


.rr--hf-svg-off, .rr--off .rr--svg{
    fill: var(--color-500)
}

[dir=rtl] .rr--svg-stop-1, .rr--svg-stop-2 {
    stop-color: var(--color-500, rgba(0, 0, 0, 0));
}
&--gold{
    .rr--svg-stop-1 {
        stop-color: var(--gold-second)
    }
      .rr--hf-svg-on .rr--svg, .rr--on .rr--svg{
          fill: var(--gold-second) !important;
      }
}
}


//.rating {
//    display: inline-block;
//    // .rating__items
//    &__items {
//        display: inline-flex;
//        align-items: flex-start;
//    }
//
//    // .rating__item
//    &__item {
//        width: 14px;
//        height: 12px;
//        position: relative;
//        transition: all 0.3s;
//
//        &:after {
//            background-color: var(--color-500);
//            .rating-gold & {
//                background-color: var(--color-400);
//            }
//        }
//
//        &--active {
//            &:after {
//                background-color: var(--color-main);
//                .rating-gold & {
//                    background-color: #FFCC4A;
//                }
//            }
//        }
//
//        span {
//            position: absolute;
//            top: 0;
//            left: 0;
//            width: 100%;
//            height: 100%;
//            z-index: 1;
//            &:after{
//                .rating-gold & {
//                    background-color: #FFCC4A;
//                }
//            }
//        }
//    }
//
//    // .rating__input
//    &__input {
//        width: 100%;
//        height: 100%;
//        opacity: 0;
//        display: block;
//    }
//}