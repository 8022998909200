@import 'src/styles/variables';

.attention-box{
    margin: 24px 0;
    padding: 12px;
    border-radius: 12px;
    border-left: 3px solid transparent;
    background-color: var(--color-200);

    &.important{
        border-color: var(--red-800);
        .attention-box__word{
            color: var(--red-800);
        }
        p{
            display: inline;
        }
    }

    &.advice{
        border-color: var(--color-400);
        background-color: transparent;
        border: 1px solid;
    }

    &.warning{
        border-color: var(--gold-400);
        background-color: transparent;
        border: 1px solid;
    }

    .attention__icon{
        border-radius: 12px;
        padding: 8px 12px;
        display: flex;
        align-items: center;
        gap: 14px;
        width: max-content;
        &.advice{
            background-color: var(--green-300);
            color: var(--green-main);
        }

        &.warning{
            background: linear-gradient(0deg, #00A480, #00A480), linear-gradient(76.82deg, #546265 11.6%, #9DA1A1 25.31%, #828B8A 48.06%, #546265 55.72%, #546265 77.23%, #747A7B 85.34%, #546265 91.31%);
            background-blend-mode: color, normal;
            color: var(--white-main);

            i:after{
                background-color: var(--white-main);
            }
        }
    }

    //ul{
    //    display: flex;
    //    flex-direction: column;
    //    gap: 20px;
    //    li{
    //        margin-bottom: 0;
    //    }
    //}
}