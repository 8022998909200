@import "./src/styles/variables.scss";

.create-page {

  &__form {
    &.photo {
      gap: 8px;
    }

    .second-images {
      display: inline-flex;
      gap: 16px;
      width: 100%;
      justify-content: flex-start;
      padding: 16px;

      &.upload-video {
        .create-page__upload-image {
          width: 340px;
          height: 190px;

          @media (max-width: $mobileSmall) {
            width: unset;
            height: 135px;
          }
        }
      }

      @media (max-width: $mobileSmall) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 8px;
        padding: 8px;
      }
    }
    .tabs__nav-btn._disabled {
      cursor: default;
      pointer-events: none;
      opacity: 0.4;
    }
    .important-icons {
      display: inline-flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 6px;
      margin-right: 6px;
      margin-bottom: 6px;
      transform: translateY(6px);
    }
    .girl-card__icon {
      padding-right: 4px;
      background-color: var(--green-300);
      display: inline-flex;
      gap: 8px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      min-height: 28px;
      min-width: 28px;
      cursor: help;
      color: var(--green-main);
    }

    .attention-box.important {
      padding: 24px;
      border-radius: 12px;
      border-left: 3px solid var(--red-main);
      background-color: var(--color-200);

      .error {
        font-size: 14px;
        display: inline-block;
        margin-right: 6px;
      }

      .green {
        display: inline-block;
        margin-right: 6px;
        margin-left: 6px;
      }

      &__icon {
        display: inline-flex;
        gap: 6px;
        align-items: flex-end;
        margin-right: 6px;
        transform: translateY(4px);
      }
    }
  }
  &__form.photo {
    font-weight: 500;
    .error-block {
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 20px;
      background-color: var(--red-300);
      border-radius: 12px;

      span {
        color: var(--red-main);
        display: inline-block;
      }
    }
    .upload__zone__main {
      span.text-center {
        color: var(--green-main);
        font-weight: 500;
      }
    }
    .main-images-text {
      width: 100%;
      display: flex;
    }
    .main-images {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 16px;
      border: 2px solid var(--black-light);
      align-items: center;
      justify-content: center;
      border-radius: 12px;
      padding: 16px;
      margin-bottom: 16px;
      .main-images--content {
        display: inline-flex;
        gap: 16px;
      }

      .text-center {
        flex-basis: 100%;
        width: min-content;
      }

      @media (max-width: $mobileSmall) {
        width: 100%;
        gap: 8px;
        padding: 8px;

        .main-images--content {
          display: grid;
          grid-template-columns: repeat(3, 1fr);

          .create-page__upload-image {
            width: unset;
            height: 135px;
          }
        }

        .text-center {
          order: -1;
        }
      }
    }
    .main-images ~ .second-images {
      padding: 16px;
    }
    
    .create-page__upload {
      &.upload-ero {
        flex-direction: column;
      }

      display: flex;
      flex-wrap: wrap;
      &.visual-hide {
        display: none;
      }
      
      
      .main-images ~ .second-images ~ .load-more {
        padding: 16px;
      }
      .load-more {
        margin-top: 16px;
        &.upload-video {
          .create-page__upload-image {
            width: 340px;
            height: 190px;
          }
        }
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 16px;

        &.upload-video {
          grid-template-columns: repeat(3, 1fr);
        }
      }
      &-image {
        position: relative;
        width: 160px;
        height: 212px;

        @media (max-width: $mobileSmall) {
          width: unset;
          height: 135px;
        }

        &.rejected {
          img {
            opacity: 0.4;
          }
          .span-rejected {
            position: absolute;
            left: 50%;
            top: 50%;
            color: var(--red-main);
            line-height: 20px;
            transform: translate(-50%, -50%);
          }
        }

        img {
          height: 100%;
          max-width: 100%;
          object-fit: cover;
          border-radius: 12px;
        }

        .input--upload {
          position: relative;
          display: flex;
          border-radius: 12px;
          justify-content: center;
          align-items: center;
          border: 2px dashed var(--green-600);
          width: 100%;
          height: 100%;

          &:hover {
            border-color: var(--green-main);

            input {
              pointer-events: all;
              cursor: pointer;
            }
          }
          &.drag {
            border-color: var(--green-main);
          }

          input {
            pointer-events: none;
            opacity: 0;
            width: 100%;
            height: 100%;
          }

          .icon--plus {
            pointer-events: none;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }
  &__eroLinks {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;

    .eroLink {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .currentVideo {
        position: relative;
      }
    }

    @media (max-width: $mobileSmall) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
.upload-ero__info {
  display: flex;
  flex-direction: column;

  .upload-ero__recommendation {
    display: flex;
    gap: 20px;
    width: fit-content;

    div {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      span:first-of-type {
        font-size: 16px;
        color: var(--white-main);
      }
    }
  }
  .upload-ero__watch {
    position: relative;
    margin-top: 12px;
    display: flex;
    align-items: center;
    gap: 8px;
    width: fit-content;
    &-notification {
      display: none;
      position: absolute;
      left: calc(100% - 28px);
      top: calc(100% + 10px);
      width: 275px;
      padding: 12px;
      border-radius: 12px;
      z-index: 2;
      border: 1px solid var(--white-300);
      background-color: var(--black-light);
      transition: background-color var(--switch-theme-time);
      color: var(--white-700);
      text-align: center;
    }

    .icon--info {
      cursor: help;

      &:hover {
        & + span {
          display: block;
        }
      }
    }
  }
}
.upload-ero--title {
  margin-bottom: 20px;
  font-size: 28px;
  line-height: 32px;
  color: var(--white-main);
  margin-top: 28px;
  &:first-of-type {
    margin-top: 16px;
  }
}
.icon-bg {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 4px;
  top: 4px;
  padding: 4px;
  border-radius: 8px;
  background-color: var(--white-400);
  transition: background-color 0.4s;

  &:hover {
    background-color: var(--white-500);
  }
}
