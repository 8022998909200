@import "./src/styles/variables.scss";

.date-picker--wrapper {
  &.disabled {
    pointer-events: none;
    .rc-time-picker {
      input {
        color: var(--color-400);

        &::placeholder {
          color: var(--color-400);
        }
        cursor: default !important;
      }
      .rc-time-picker-input {
        background: var(--black-light) !important;
      }
    }
  }
  display: flex;
  gap: 12px;

  .rc-time-picker-input {
    height: 37px;
    font-size: 14px;
    color: var(--color-main) !important;
    border: none !important;
    outline: none !important;
    border-radius: 12px;
    padding: 8px 16px;
    background: var(--color-400) !important;
    transition: background-color var(--switch-theme-time);

    &:focus-visible {
      background: var(--color-400) !important;
    }
  }
} 
.rc-time-picker-panel-inner {
  transform: translateY(45px);
  border: none !important;
  background: var(--black-light) !important;
  transition: background-color var(--switch-theme-time);
  .rc-time-picker-panel-input-wrap {
    display: none;
  }
  input {
    background: var(--black-light) !important;
    border: none;
  }

  .rc-time-picker-panel-select {
    border: none;
    li {
      font-size: 14px;
      background: var(--black-light) !important;

      &:hover {
        background: var(--color-400) !important;
      }
    }
  }
}
// .rc-time-picker-clear {
//   top: 50% !important;
//   transform: translateY(-50%);
// }

.rc-time-picker-clear {
  display: none;
}
// .rc-time-picker-clear-icon::after {
//   font-size: 18px !important;
// }