@import "./variables";

.w-100 {
  width: 100%;
}

.w-mc {
  width: max-content;
}

.h-48 {
  height: 48px;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-4 {
  margin-bottom: 4px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-32 {
  margin-bottom: 32px;
}

.mb-36 {
  margin-bottom: 36px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-48 {
  margin-bottom: 48px;
}

.ml-4 {
  margin-left: 4px;
}

.ml-6 {
  margin-left: 6px;
}

.ml-8 {
  margin-left: 8px;
}

.mb-4-mob {
  @media (max-width: $mobile) {
    margin-bottom: 4px;
  }
}

.mb-8-mob {
  @media (max-width: $mobile) {
    margin-bottom: 8px;
  }
}

.mb-12-mob {
  @media (max-width: $mobile) {
    margin-bottom: 12px;
  }
}

.mb-16-mob {
  @media (max-width: $mobile) {
    margin-bottom: 16px;
  }
}

.mb-20-mob {
  @media (max-width: $mobile) {
    margin-bottom: 20px;
  }
}

.mb-24-mob {
  @media (max-width: $mobile) {
    margin-bottom: 24px;
  }
}

.mb-32-mob {
  @media (max-width: $mobile) {
    margin-bottom: 32px;
  }
}

.mb-36 {
  @media (max-width: $mobile) {
    margin-bottom: 36px;
  }
}

.mb-40-mob {
  @media (max-width: $mobile) {
    margin-bottom: 40px;
  }
}

.mb-48-mob {
  @media (max-width: $mobile) {
    margin-bottom: 48px;
  }
}

.mt-4-mob {
  @media (max-width: $mobile) {
    margin-top: 4px;
  }
}

.mt-8-mob {
  @media (max-width: $mobile) {
    margin-top: 8px;
  }
}

.mt-12-mob {
  @media (max-width: $mobile) {
    margin-top: 12px;
  }
}

.mt-16-mob {
  @media (max-width: $mobile) {
    margin-top: 16px;
  }
}

.mt-20-mob {
  @media (max-width: $mobile) {
    margin-top: 20px;
  }
}

.mt-24-mob {
  @media (max-width: $mobile) {
    margin-top: 24px;
  }
}

.mt-32-mob {
  @media (max-width: $mobile) {
    margin-top: 32px;
  }
}

.mt-36 {
  @media (max-width: $mobile) {
    margin-top: 36px;
  }
}

.mt-40-mob {
  @media (max-width: $mobile) {
    margin-top: 40px;
  }
}

.mt-48-mob {
  @media (max-width: $mobile) {
    margin-top: 48px;
  }
}

.mt-auto {
  margin-top: auto;
}

.mt-4 {
  margin-top: 4px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-32 {
  margin-top: 32px;
}

.mt-36 {
  margin-top: 36px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-48 {
  margin-top: 48px;
}

.p-0 {
  padding: 0;
}

.p-8 {
  padding: 8px;
}
.p-24 {
  padding: 24px;
}

.fz-10 {
  font-size: 10px;
}

.fz-11 {
  font-size: 11px;
}

.fz-12 {
  font-size: 12px;
}

.fz-13 {
  font-size: 13px;
}

.fz-14 {
  font-size: 14px;
}

.fz-15 {
  font-size: 15px;
}

.fz-16 {
  font-size: 16px;
}

.fz-17 {
  font-size: 17px;
}

.fz-18 {
  font-size: 18px;
}

.fz-19 {
  font-size: 19px;
}

.fz-20 {
  font-size: 20px;
}

.fz-21 {
  font-size: 21px;
}

.fz-22 {
  font-size: 22px;
}

.fz-23 {
  font-size: 23px;
}

.fz-24 {
  font-size: 24px;
}

.fz-25 {
  font-size: 25px;
}

.fz-26 {
  font-size: 26px;
}

.fz-27 {
  font-size: 27px;
}

.fz-28 {
  font-size: 28px;
}

.fz-32 {
  font-size: 32px;
  line-height: 42px;
}

.fz-10-mob {
  @media (max-width: $mobile) {
    font-size: 10px;
  }
}

.fz-11-mob {
  @media (max-width: $mobile) {
    font-size: 11px;
  }
}

.fz-12-mob {
  @media (max-width: $mobile) {
    font-size: 12px;
  }
}

.fz-13-mob {
  @media (max-width: $mobile) {
    font-size: 13px;
  }
}

.fz-14-mob {
  @media (max-width: $mobile) {
    font-size: 14px;
  }
}

.fz-15-mob {
  @media (max-width: $mobile) {
    font-size: 15px;
  }
}

.fz-16-mob {
  @media (max-width: $mobile) {
    font-size: 16px;
  }
}

.fz-17-mob {
  @media (max-width: $mobile) {
    font-size: 17px;
  }
}

.fz-18-mob {
  @media (max-width: $mobile) {
    font-size: 18px;
  }
}

.fz-19-mob {
  @media (max-width: $mobile) {
    font-size: 19px;
  }
}

.fz-20-mob {
  @media (max-width: $mobile) {
    font-size: 20px;
  }
}

.fz-21-mob {
  @media (max-width: $mobile) {
    font-size: 21px;
  }
}

.fz-22-mob {
  @media (max-width: $mobile) {
    font-size: 22px;
  }
}

.fz-23-mob {
  @media (max-width: $mobile) {
    font-size: 23px;
  }
}

.fz-24-mob {
  @media (max-width: $mobile) {
    font-size: 24px;
  }
}

.fz-25-mob {
  @media (max-width: $mobile) {
    font-size: 25px;
  }
}

.fz-26-mob {
  @media (max-width: $mobile) {
    font-size: 26px;
  }
}

.fz-27-mob {
  @media (max-width: $mobile) {
    font-size: 27px;
  }
}

.fz-28-mob {
  @media (max-width: $mobile) {
    font-size: 28px;
  }
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.bold {
  font-weight: 500;
}

.lh-14 {
  line-height: 14px;
}

.lh-16 {
  line-height: 16px;
}

.lh-18 {
  line-height: 18px;
}

.lh-20 {
  line-height: 20px;
}

.lh-22 {
  line-height: 22px;
}

.lh-24 {
  line-height: 24px;
}

.lh-26 {
  line-height: 26px;
}

.lh-28 {
  line-height: 28px;
}

.lh-34 {
  line-height: 34px;
}

.lh-54 {
  line-height: 54px;
}

.b-radius-6 {
  border-radius: 6px;
}

.b-radius-8 {
  border-radius: 8px;
}

.b-radius-12 {
  border-radius: 12px;
}

.b-radius-16 {
  border-radius: 16px;
}

.b-radius-20 {
  border-radius: 20px;
}

.b-radius-24 {
  border-radius: 24px;
}

.height-100 {
  height: 100%;
}

.width-100 {
  width: 100%;
}

.width-50 {
  width: 50%;
}

.width-max {
  width: max-content;
}

.width-min {
  width: min-content;
}

.width-fit {
  width: fit-content;
}

.d-flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

.d-inline {
  display: inline;
}

.justify-center {
  justify-content: center;
}

.justify-sb {
  justify-content: space-between;
}

.justify-start {
  justify-content: start;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.fd-column {
  flex-direction: column;
}

.fd-row {
  flex-direction: row;
}

.gap-0 {
  gap: 0;
}

.gap-4 {
  gap: 4px;
}

.gap-8 {
  gap: 8px;
}

.gap-10 {
  gap: 10px;
}

.gap-12 {
  gap: 12px;
}

.gap-16 {
  gap: 16px;
}

.gap-20 {
  gap: 20px;
}

.gap-24 {
  gap: 24px;
}

.gap-28 {
  gap: 28px;
}

.gap-40 {
  gap: 40px;
}

.old-price {
  color: var(--color-600);
  text-decoration: line-through;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.bg-green {
  background-color: var(--green-main);
}

.bg-green-400 {
  background-color: var(--green-400);
}

.bg-green-300 {
  background-color: var(--green-300);
}

.color-green {
  color: var(--green-main);
}

.color-white {
  color: var(--white-main);
}

.color-700 {
  color: var(--color-700);
}

.color-600 {
  color: var(--color-600);
}

.color-500 {
  color: var(--color-500);
}

.color-main {
  color: var(--color-main);
}

.color-gold-main {
  color: var(--gold-main);
}

.color-gold-second {
  color: var(--gold-second);
}

.color-main-reverse {
  color: var(--color-main-reverse);
}

.color-red-700 {
  color: var(--red-main);
}

[hidden] {
  display: none;
}
