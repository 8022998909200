.checkbox {
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 8px;
  position: relative;
  transition: all 0.2s;

  &:hover,
  &:focus-within {
    background-color: var(--color-200);

    input[type="checkbox"] {
      border: 2px solid var(--color-main);
    }
  }

  &-icon {
    position: absolute;
    pointer-events: none;
    user-select: none;
    opacity: 0;
    width: 12px;
    height: 12px;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
    transition: opacity 0.2s ease;
  }

  &-flag {
    width: 22px;
    height: 16px;
  }

  input[type="checkbox"] {
    appearance: none;
    width: 16px;
    position: absolute;
    height: 16px;
    left: 6px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    border: 1px solid var(--color-500);
    transition: all 0.1s ease 0s;
    border-radius: 4px;

    &.radio {
      border-radius: 50px;
    }

    &:after {
      position: absolute;
      content: "";
      width: 12px;
      height: 12px;
      top: 50%;
      left: 50%;
      transform: translate(-7px, -50%);
      transition: all 0.15s ease 0s;
      opacity: 0;
      mask-size: 100%;
      mask-repeat: no-repeat;
      mask-position: bottom center;
      -webkit-mask-position: bottom center;
      background-color: var(--color-main);
      mask-image: url('/assets/img/icons/check-white.svg');

      .light & {
        background-image: url('/assets/img/icons/check-black.svg');
      }
    }

    &:active {
      border: 2px solid var(--color-main);
    }

    &:checked {
      border: 2px solid var(--color-main-reverse);
      background-color: var(--color-main-reverse);

      ~ label {
        color: var(--color-main-reverse);
      }
      &:after {
        background-color: var(--color-main);
        opacity: 1;
      }
    }
  }

  // .checkbox__label
  label {
    cursor: pointer;
    color: var(--color-700);
    border-radius: 12px;
    transition: color 0.2s ease 0s;
    padding: 8px 8px 8px 28px;
    font-weight: 500;
  }

  &_disabled {
    user-select: none;
    touch-action: none;
    cursor: default;

    &:hover,
    &:focus-within {
      background-color: transparent;

      input[type="checkbox"] {
        border: 2px solid var(--white-200);
      }
    }

    label {
      color: var(--color-500);
      cursor: default;
    }

    input[type="checkbox"] {
      border: 2px solid var(--color-200);
      cursor: default;
      &:hover,
      &:focus-within,
      &:active {
        border: 2px solid var(--color-200);
      }
    }
  }

  &_checked {
    background-color: var(--color-800);

    &:hover,
    &:focus-within {
      background-color: var(--color-main);
    }
  }

  &._no-border {
    &.checkbox_checked {
      background-color: transparent;

      &:hover {
        background-color: transparent;
      }
    }

    &:hover,
    &:focus-within {
      background-color: transparent;
    }

    input[type="checkbox"] {
      left: 0;

      &:checked {
        border: 2px solid var(--color-main);
        background-color: var(--color-main);
        &:after{
          background-color: var(--color-main-reverse);
        }
        ~ label {
          color: var(--color-main);
        }
      }
    }

    svg {
      left: 2px;
    }

    label {
      padding: 0 0 0 22px;
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
}

.checkbox-button {
  padding: 8px 0;
  font-weight: 500;
  line-height: 17px;
  width: 100%;
  text-align: center;
  background-color: var(--color-300);
  border-radius: var(--border-radius-primary);
  cursor: pointer;
  transition: background-color var(--switch-theme-time),
    color var(--switch-theme-time);

  &:hover,
  &:focus-within {
    background-color: var(--color-400);
  }

  input {
    appearance: none;
    width: 0;
    height: 0;
  }

  &_checked {
    background-color: var(--color-main);
    color: var(--color-main-reverse);

    &:hover,
    &:focus-within {
      background-color: var(--color-main);
    }
  }

  &_disabled {
    cursor: default;
    background-color: var(--color-400);
    color: var(--color-500);
    user-select: none;
    touch-action: none;
    pointer-events: none;
    &:hover,
    &:focus-within {
      background-color: var(--color-400);
    }

    input {
      cursor: default;
      &:hover,
      &:focus-within {
        background-color: var(--color-400);
      }
    }
  }
}
