@import "./src/styles/variables.scss";

.account-page {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-top: 24px;
    padding-bottom: 24px;
    @media (max-width: $mobile) {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }

  &__root {
    display: grid;
    grid-template-columns: 1fr auto;
    width: 100%;
    gap: 16px;

    @media (max-width: $tablet) {
      gap: 12px;
    }

    @media (max-width: $tabletSmall) {
      display: flex;
      flex-direction: column;
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    max-width: 810px;
    gap: 24px;
    @media (max-width: $tabletSmall) {
      max-width: 100%;
    }
  }

  &__identity {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__form {
      display: grid;
      grid-template-columns: 1fr 1fr auto;
      gap: 16px;
      align-items: center;
      @media (max-width: $mobile) {
        grid-template-columns: 1fr 160px;
        justify-content: space-between;
      }
    }
  }

  &__link-block {
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
    padding: 12px 24px;
    width: 100%;
    background-color: var(--white-200);
    align-items: center;
    line-height: 28px;
    border-radius: 12px;
    color: var(--white-main);
    gap: 8px;
    height: 84px;
    font-size: 24px;

    @media (max-width: $mobile) {
      line-height: 24px;
    }

    &:hover {
      outline: 2px solid var(--green-main);
    }

    .light & {
      background-color: rgba(31, 33, 33, 0.06);
      color: rgba(31, 33, 33, 1);
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__removebtn {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    gap: 4px;

    &__active {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;

      @media (max-width: $mobile) {
        gap: 4px;
      }
    }
  }

  &__confirm {
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
  }

  &__cash {
    color: var(--green-main);
  }
}
