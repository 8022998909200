.example__photo {
  height: 200px;
  border-radius: 12px;
  position: relative;

  img {
    height: 100%;
    border-radius: 12px;
    object-fit: cover;
    aspect-ratio: 3/4;
  }
}

.icon-remove {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 4px;
  top: 4px;
  padding: 4px;
  border-radius: 8px;
  background-color: var(--green-main);
  transition: background-color 0.4s;

  &:hover {
    background-color: var(--green-500);
  }
}
