@import "./src/styles/variables";

.agency-filter{
    &__top{
        display: flex;
        justify-content: space-between;
        align-items: center;
        h3{
            @media(min-width: $mobile){
                margin-bottom: 0;
            }
        }
    }
    &__nav{
        display: flex;
        gap: 4px;
        .dropdown{
            color: var(--color-main);
            @media (min-width: $mobileSmall) {
                margin-left: auto;
            }
            @media(max-width: $mobileSmall){
                &,  .button, .dropdown__body{
                    width: 100%;
                }
                .chevrone{
                    margin-left: auto;
                }
            }
            .dropdown__body{
                left: auto;
                right: 0;
            }
        }
        @media(max-width: $pc){
            flex-wrap: wrap;
        }
    }
    &__box{
        padding: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: var(--color-200);
        border-radius: 16px;
        margin-top: 32px;
        gap: 8px;

        @media(max-width: $pc){
            flex-wrap: wrap;
            margin: 32px auto 0;
            max-width: 652px;
            gap: 24px;
        }
        .button{
            max-height: 60px;
            width: 316px;
            justify-content: center;
            @media(max-width: $pc){
                margin: 0 auto;
            }
        }

        @media(max-width: $mobile){
            padding: 16px;
        }

    }
    &__inner{
        &:nth-of-type(1){
            max-width: 414px;
        }
        &:nth-of-type(2){
            display: flex;
            gap: 34px;
            align-items: center;
            @media(min-width: $pc){
                max-width: 584px;
            }
            @media(max-width: $mobileSmall){
                flex-wrap: wrap;
                justify-content: center;
                gap: 24px;
            }
            div:not(.agency-filter__counter){
                @media(max-width: $mobileSmall){
                    width: 130px;
                }
            }
        }

        .title{
            color: var(--color-main);
        }
    }
    &__counter{
        width: 100%;
        display: flex;
        gap: 6px;
        @media(min-width: $mobileSmall){
            max-width: 212px;
            flex-direction: column;
        }
        @media(max-width: $mobileSmall){
            justify-content: center;
            align-items: center;
        }
    }
    &__count{
        padding: 12px;
        text-align: center;
        background-color: var(--color-200);
        display: flex;
        gap: 10px;
        align-items: center;
        border-radius: 20px;
        justify-content: center;
        @media(max-width: $mobileExtraSmall){
            width: 158px;
            padding: 12px 24px;
        }
    }
}