@import "root";
@import "reset";
@import "variables";
@import "src/assets/fonts/fonts";
// Оболочка wrapper

.lock {
  scrollbar-gutter: stable;
}

// Оболочка wrapper
.wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: var(--bg-main);
  transition: background-color var(--switch-theme-time),
    color var(--switch-theme-time);

  @media (max-width: $mobile) {
    padding-bottom: 76px;
  }

  @supports (overflow: clip) {
    overflow: clip;
  }

  /* Прижимаем footer */
  > main {
    flex: 1 1 auto;
    padding-top: 160px;
    @media (max-width: $tablet) {
      padding-top: 140px;
    }
    @media (max-width: $mobile) {
      padding-top: 104px;
    }
    @media (max-width: $mobileExtraSmall) {
      padding-top: 94px;
    }
  }

  /* Фикс для слайдеров */
  > * {
    min-width: 0;
  }
}

body {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  /* Скролл заблокирован */
  .lock &,
  .lg-on & {
    overflow: hidden;
    touch-action: none;
    overscroll-behavior: none;
  }
}

$containerPadding: 32px;
// Ширина ограничивающего контейнера по макету
$maxWidthContainer: 1440px;
// Ограничивающий контейнер
[class*="__container"] {
  margin: 0 auto;
  max-width: calc($maxWidthContainer + $containerPadding);
  padding-left: calc($containerPadding / 2);
  padding-right: calc($containerPadding / 2);

  @media (max-width: $mobileExtraSmall) {
    padding-left: calc($containerPadding / 4);
    padding-right: calc($containerPadding / 4);
  }
}

h1,
.title_h1,
h2,
.title_h2,
h3,
.title_h3,
h4,
.title_h4,
.title {
  color: var(--color-main);
  transition: color var(--switch-theme-time);
  font-weight: 200;
  font-family: "TT Ramillas";
  display: block;
}

h1,
.title_h1 {
  font-size: 48px;
  line-height: 54px;
  @media (max-width: $tablet) {
    font-size: 40px;
    line-height: 48px;
  }
  @media (max-width: $mobileSmall) {
    font-size: 28px;
    line-height: 34px;
  }
}

h1:not([class]) {
  margin: 20px 0;
}

h2,
.title_h2 {
  font-size: 32px;
  line-height: 38px;
  @media (max-width: $tablet) {
    font-size: 26px;
    line-height: 30px;
  }
  @media (max-width: $mobileSmall) {
    font-size: 22px;
    line-height: 24px;
  }
}

h2:not([class]) {
  margin: 16px 0 12px;
}

h3,
.title_h3 {
  font-size: 22px;
  line-height: 26px;
  @media (max-width: $mobileSmall) {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 16px;
  }
}

h4,
.title_h4 {
  font-size: 18px;
  line-height: 22px;
}

h3:not([class]) {
  margin: 16px 0 12px;
}

p:not([class]) {
  margin: 8px 0;
}

.p1 {
  font-size: 16px;
  @media (max-width: $mobileSmall) {
    font-size: 15px;
  }
}

.p2 {
  font-size: 14px;
  @media (max-width: $mobileSmall) {
    font-size: 13px;
  }
}

.p3 {
  font-size: 13px;
  @media (max-width: $mobileSmall) {
    font-size: 12px;
  }
}

ul {
  list-style: none;
  margin: 6px 0 12px 4px;
  li {
    position: relative;
    &:before {
      position: absolute;
      content: "";
      left: -24px;
      top: 0;
      aspect-ratio: 1/1;
      width: 16px;
      -webkit-mask-size: 100%;
      mask-size: 100%;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      mask-position: center;
      -webkit-mask-position: center;
      background-color: var(--color-main);
      -webkit-mask-image: url("/assets/img/icons/check-white.svg");
      mask-image: url("/assets/img/icons/check-white.svg");
    }
  }
}

ul {
  li {
    padding: 2px 0;
    margin: 4px 0 4px 24px;
  }
}

ol {
  li {
    margin: 8px 0 8px 24px;
  }
}

b {
  font-weight: 600;
  color: var(--color-main);
}

// Сетка для карточек
.grid-cards {
  display: grid;
  grid-gap: 32px 18px;
  grid-template-columns: repeat(4, 1fr);
  @media(max-width: $pc){
    grid-gap: 32px 8px;
  }
  @media (max-width: 1299.98px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: $tabletSmall) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: $mobile) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 24px 0;
  }
}
.text-dots {
  // Ограничивается высота строки и обрезается лишнее с добавлением точек ("и так далее...")
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
}

.td-none {
  text-decoration: none;
}

.td-underline {
  text-decoration: underline;
}

.text-underline {
  text-underline-offset: 4px;
  text-decoration-thickness: 1px;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
    color: var(--color-main);
  }
}

.underline {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    bottom: -4px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: var(--color-main);
  }

  &:hover {
    &:before {
      opacity: 0;
    }
  }
}

.hover-line {
  text-decoration: none;

  &:hover,
  &:focus-within {
    text-underline-offset: 4px;
    text-decoration-thickness: 1px;
    text-decoration: underline;
  }
}

.img-cover {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.img-contain {
  object-fit: contain;
  object-position: center;
  width: 100%;
}

.error-page {
  font-size: 24px;
  color: white;
  padding: 200px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.loader__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.tag{
  padding: 8px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  border-radius: 8px;
}