@import "src/styles/variables";

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.input {
  display: flex;
  padding: 4px 4px 4px 12px;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: none;
  min-height: 40px;
  font-size: 14px;
  font-weight: 500;
  line-height: 120%; /* 16.8px */
  color: inherit;
  width: 100%;
}

.search {
  position: relative;

  &__label {
    position: relative;
    cursor: text;
    border-radius: 8px;
    padding: 4px 4px 4px 12px;
    min-height: 40px;
    background-color: var(--black-light);
    transition: background-color var(--switch-theme-time);
    display: flex;
    align-items: center;

    &:hover,
    &:focus-within {
      background-color: var(--color-400);
    }
  }

  &__input {
    width: 100%;
    padding: 0 46px 0 0;
    min-height: 0;
    border-radius: 0;
    background-color: transparent;
    outline: none;
    color: var(--color-600);

    &::placeholder {
      color: var(--color-600);
      transition: color var(--switch-theme-time);
    }

    @media (max-width: $mobile) {
      font-size: 13px;
      padding: 0 40px 0 0;
    }
  }

  &__close {
    position: absolute;
    top: 50%;
    right: 44px;
    transform: translateY(-50%);
    @media (max-width: $mobile) {
      border-radius: 8px;
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
  &__icon {
    position: absolute;
    top: 50%;
    right: 4px;
    transform: translateY(-50%);
    background-color: var(--color-400);
    transition: background-color var(--switch-theme-time);
    @media (max-width: $mobile) {
      border-radius: 8px;
    }
  }
}
