@import "./src/styles/variables.scss";

.review__root {
  display: flex;
  flex-direction: row;
  background-color: var(--color-200);
  width: 100%;
  padding: 20px;
  border-radius: 16px;
  gap: 16px;

  &__user__block {
    display: flex;
    gap: 12px;
    align-items: center;
  }

  &__user {
    display: flex;
    background-color: var(--color-300);
    border-radius: 12px;
    min-height: 32px;
    align-items: center;
    padding: 4px 8px;
    gap: 8px;

    &__title {
      width: 60px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      word-break: break-all;
    }
  }

  &__sect {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }

  &__info {
    display: grid;
    grid-template-columns: 1fr auto auto;
    gap: 32px;

    @media (max-width: $mobile) {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: space-between;
  }

  &__head {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
  }

  &__form__pc {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 10px;
    width: 100%;
    align-items: center;

    @media (max-width: $mobile) {
      display: none;
    }
  }

  &__form__mobile {
    display: none;

    @media (max-width: $mobile) {
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 10px;
      width: 100%;
      align-items: center;
    }
  }

  &__ratings {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border: 1px solid var(--color-300);
    padding: 12px;
    border-radius: 12px;
  }

  &__rating {
    display: grid;
    grid-template-columns: 1fr max-content;
    gap: 10px;
    align-items: center;

    &__main {
      display: grid;
      grid-template-columns: 1fr auto max-content;
      gap: 4px;
      align-items: center;
    }
  }
}
