.popup-order{
    &__address{
        min-height: 61px;
        justify-content: center;
    }
    .popup-order__radio{
        padding: 8px;
        min-width: auto;
        width: 100%;
    }
}
