@import "src/styles/variables";

.toast {
  display: flex;
  flex-direction: row;
  gap: 10px;
  border-radius: 12px;
  justify-content: space-between;
  background-color: var(--gray-main);
  padding: 8px;
  h3{
    margin-bottom: 0;
  }
  // &.error {
  //   background-color: orangered !important;
  // }

  // &.succes {
  //   background-color: var(--green-main);
  // }

  &__root {
    display: flex;
    flex-direction: column;
    color: var(--color-main);
    margin-left: 12px;
    gap: 12px;
    width: 100%;
  }

  &__info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  &__try {
    color: var(--green-main);
  }

  svg {
    color: var(--color-main);
    width: 20px;
    height: 20px;
  }
}
