@import "src/styles/variables";

.authorization {
    .popup-form {
        &__title{
            @media(max-width: $mobileExtraSmall){
                font-size: 22px;
            }
        }
        &__box {
            flex-direction: column;
            align-items: center;
            &-title{
                @media(max-width: $mobileExtraSmall){
                    font-size: 18px;
                }
            }
        }
    }

    &__forgot {
        color: var(--green-main);
        font-weight: 500;
        width: max-content;
        &:hover, &:focus-within {
            text-decoration: underline;
        }
    }
}

