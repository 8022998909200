.map-filter{
    //&__container{
    //    position: relative;
    //    display: flex;
    //}

    .popup-form__body{
        position: relative;
        display: flex;
    }

    .map-filter__body {
        width: 80vw;
        height: 80vh;
        padding-top: 50px;

        .loader-wrapper {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &__close, &__natification{
        position: absolute;
        top: 40px;
    }
    &__close{
        right: 40px;
        background-color: var(--black-main);
    }

    &__natification{
        left: 50%;
        transform: translateX(-50%);
        width: fit-content;
        color: var(--white-main);
        background-color: var(--black-500);
        border-radius: 8px;
        display: flex;
        gap: 8px;
        align-items: center;
        padding: 8px;
    }
    &__wrapper{
        position: absolute;
        top: 20%;
        left: 20px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        > .button-square{
            margin-left: auto;
        }

        .loader-wrapper {
            transform: translate(-50%, -50%) !important;
        }
    }

    .girl-card {
        box-shadow: 0 12px 64px 0 rgba(0, 0, 0, 0.7215686275);
        width: 400px;
        height: 365px;
    }
    &__circle{
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        color: var(--color-main-reverse);
        position: relative;
        border-radius: 50%;
        z-index: 1;
        &:before,&:after{
            position: absolute;
            content: "";
            border-radius: 50%;
            z-index: -1;
        }
        &:before{
            background-color: var(--green-600);
            bottom: -2px;
            left: -2px;
            right: -2px;
            top: -2px;
        }
        &:after{
            width: 100%;
            height: 100%;
            background-color: var(--color-main);
        }
    }
    &__tippy{
        cursor: pointer;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px 8px;
        position: relative;
        width: 100%;
        height: 35px;
        background-color: var(--color-main);
        color: var(--color-main-reverse);
        font-weight: 600;
        border-radius: 8px;
        margin-bottom: 8px;
        &:after{
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 8px 8px 0 8px;
            border-color: var(--color-main) transparent transparent transparent;
            transform: rotate(0deg) translateX(-50%);
            position: absolute;
            bottom: -8px;
            left: 50%;
        }
        &--top, &--elite{
            color: var(--white-main);
        }

        &--elite{
            //background: linear-gradient(75.69deg, #B07D20 5.01%, #B07D20 41.48%, #FFDA44 87.96%, #FFDA44 125.92%, #B07D20 158.54%, #B07D20 207.38%);
            background: linear-gradient(75.69deg, color(display-p3 0.659 0.498 0.208) 5.01%, color(display-p3 0.659 0.498 0.208) 41.48%, color(display-p3 1.000 0.863 0.384) 87.96%, color(display-p3 1.000 0.863 0.384) 125.92%, color(display-p3 0.659 0.498 0.208) 158.54%, color(display-p3 0.659 0.498 0.208) 207.38%);
            background-blend-mode: color, normal;

            &:after{
                border-color: #BA8724 transparent transparent transparent;
            }
        }

        &--top{
            background: linear-gradient(0deg, var(--green-main), var(--green-main)), linear-gradient(76.82deg, #546265 11.6%, #9DA1A1 25.31%, #828B8A 48.06%, #546265 55.72%, #546265 77.23%, #747A7B 85.34%, #546265 91.31%);
            background-blend-mode: color, normal;
            &:after{
                border-color: var(--green-main) transparent transparent transparent;
            }
        }
    }
}
.current-tippy {
    background-color: var(--black-light);
    color: var(--color-main);

    &::after {
        border-color: var(--black-light) transparent transparent transparent;
    }
}
.infoBox {
    & > img {
        display: none;
    }
}