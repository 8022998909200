@import "src/styles/variables";

.res {
  &__dur {
    display: inline;

    @media (max-width: $mobile) {
      display: none;
    }
  }

  &__durmob {
    display: none;

    @media (max-width: $mobile) {
      display: inline;
    }
  }

  &__book {
    display: inline;

    @media (max-width: $mobile) {
      display: none;
    }
  }

  &__bookmob {
    display: none;

    @media (max-width: $mobile) {
      display: flex;
      flex-direction: column;
    }
  }

  &__root {
    display: grid;
    grid-template-columns: auto 1fr auto;
    background-color: var(--color-200);
    border-radius: 23px;
    justify-content: space-between;
    padding: 24px;
    height: 184px;

    @media (max-width: $tabletSmall) {
      display: flex;
      flex-direction: row;
      padding: 12px;
      align-items: center;
    }

    @media (max-width: $mobileSmall), (max-width: $mobile) {
      flex-direction: column;
      padding: 16px;
      gap: 24px;
      height: max-content;
      align-items: center;
    }
  }

  &__user__main {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &.decline {
      opacity: 40%;
    }

    @media (max-width: $mobile) {
      flex-direction: row;
      width: 100%;
      justify-content: space-around;
    }

    @media (max-width: $mobileSmall) {
      justify-content: space-between;
    }
  }

  &__user__block {
    display: flex;
    gap: 12px;
    align-items: center;

    @media (max-width: $tablet) {
      flex-direction: column;
    }
  }

  &__info__block {
    display: grid;
    grid-template-columns: 1fr auto;

    &.decline {
      opacity: 40%;
    }

    @media (max-width: $tablet) {
      gap: 16px;
      margin-left: 16px;
    }

    @media (max-width: $mobile) {
      justify-content: space-around;
      grid-template-columns: max-content auto;
      width: 100%;
      margin-left: 0;
      gap: 10px;
    }
  }

  &__info__block__root {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 16px;

    @media (max-width: $tabletSmall) {
      flex-direction: column;
    }

    @media (max-width: $mobile) {
      width: 100%;
    }
  }

  &__info__block__money {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-left: 20px;

    @media (max-width: $tabletSmall), (max-width: $mobile) {
      flex-direction: row;
      justify-content: space-between;
      // gap: 40px;
      width: 100%;
      margin-left: 0;
    }

    @media (max-width: $mobileExtraSmall) {
      gap: 12px;
    }
  }

  &__user {
    display: flex;
    background-color: var(--color-300);
    border-radius: 12px;
    min-height: 32px;
    align-items: center;
    padding: 4px 8px;
    gap: 8px;

    &__title {
      width: 60px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      word-break: break-all;
    }
  }

  &__price {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__var {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    width: max-content;

    @media (max-width: $tabletSmall), (max-width: $mobile) {
      flex-direction: column;
      align-items: flex-start;
    }

    &__type {
      background-color: var(--color-300);
      color: var(--color-mian);
      border-radius: 12px;
      padding: 8px;
    }

    &__city {
      width: 140px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      word-break: break-all;

      @media (max-width: $tabletSmall), (max-width: $mobile) {
        display: none;
      }
    }
  }

  &__status {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    justify-content: center;

    &__actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;
    }

    &.done {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 8px;

      @media (max-width: $mobileSmall), (max-width: $mobile) {
        order: -1;
      }
    }

    &.approve {
      gap: 12px;

      @media (max-width: $mobileSmall), (max-width: $mobile) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: center;
      }
    }

    &.work {
      gap: 12px;
      align-items: normal;

      @media (max-width: $mobileSmall), (max-width: $mobile) {
        align-items: center;
      }
    }

    @media (max-width: $mobileSmall), (max-width: $mobile) {
      width: 100%;
    }
  }

  &__ad {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 100px;

    @media (max-width: $tablet) {
      margin: 0 20px;
    }

    @media (max-width: 800px) {
      margin: 0 12px 0 0;
    }

    &__photo {
      width: 86px;
      border-radius: 12px;
    }

    span {
      width: 86px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      word-break: break-all;
    }
  }
}
