.progress-bar {
  //   background-color: var(--green-main);
  width: 44px;
  height: 44px;
}

.CircularProgressbar-text {
  font-size: 26px !important;
  dominant-baseline: central !important;
}
