.ui-kit{
    &__icons{
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 4px;
        background-color: var(--color-400);
        margin-bottom: 80px;
        div{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 4px;
            color: var(--color-main);
            border: 1px solid var(--color-main);
            i{
                font-size: 24px;
            }
            img{
                width: 24px;
                height: 24px;
            }
        }
    }

    .girl-cards{
        display: flex;
        max-width: 1400px;
        gap: 40px;
        flex-wrap: wrap;
    }

    .girl-card{
        width:482px;
        min-width:482px;
    }
}