@import "./src/styles/variables.scss";

.rules {
  &__root {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 366px;
    // font-size: 14px;
    @media (max-width: $tabletSmall) {
      width: 100%;
    }
  }

  &__main {
    display: grid;
    grid-template-rows: 1fr 1fr;
    justify-content: space-between;
    gap: 16px;

    @media (max-width: $tabletSmall) {
      grid-template-rows: none;
      grid-template-columns: 60% auto;
    }

    @media (max-width: $mobileSmall) {
      grid-template-rows: none;
      grid-template-columns: 55% auto;
      gap: 10px;
    }

    @media (max-width: $mobileExtraSmall) {
      grid-template-rows: 1fr 1fr;
      grid-template-columns: none;
    }
  }

  &__block {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 106px;
    gap: 16px;
    border-radius: 16px;
    padding: 20px;
    background-color: var(--white-200);
    color: var(--white-main);

    &:hover {
      outline: 2px solid var(--green-main);
    }

    .light & {
      background-color: rgba(31, 33, 33, 0.06);
      color: rgba(31, 33, 33, 1);
    }
  }

  &__secure {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
}
