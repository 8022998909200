@import "./src/styles/variables";

.info-form {
  display: grid;
  grid-template-columns: repeat(3, 1fr) 160px;
  gap: 12px;
  align-items: center;

  @media (max-width: $tabletSmall) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: $mobileExtraSmall) {
    display: flex;
    flex-direction: column;
    align-items: normal;
  }
}
