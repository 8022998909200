@import "src/styles/variables";

.start-videochat{
    .popup-form__body{
        gap: 24px;
    }
    &__box{
        padding: 12px 20px;
        border: 1px solid var(--color-300);
        border-radius: 16px;
        min-height: 66px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        justify-content: center;
        color: var(--color-700);
        div{
            display: flex;
            justify-content: space-between;
            align-items: center;
            span{
                font-size: 22px;
                margin-bottom: 0;
            }
        }
    }
}