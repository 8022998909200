@import "./src/styles/variables.scss";

.create-page__form.services {
  grid-template-columns: repeat(2, 1fr);

  .create-page__input-container {
    width: 100%;

    .select {
      height: 60px;

      .select__arrow {
        transition: all 0.3s;
        right: 16px;
        top: 20px;
        position: absolute;
      }
    }

    .create-page__input-buttons {
      .checkbox-button:nth-of-type(2) {
        font-size: 12px;
      }
    }
  }
}
.form-services {
  &__top {
    grid-column: span 2 / span 2;
    display: flex;
    justify-content: space-between;
    gap: 24px;
  }
  &__desc {
    grid-column: span 2 / span 2;
  }
  &__preference {
    grid-column: span 2 / span 2;

    label {
      textarea {
        height: 120px;
      }
    }

    .loader-wrapper {
      width: 50%;
      display: flex;
      justify-content: center;
    }

    &--title {
      font-size: 22px;
      line-height: 26px;
      color: var(--white-main);
    }
    &--block {
      margin-top: 16px;
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      gap: 16px;
      justify-content: space-between;

      span:first-of-type {
        width: 50%;
        color: var(--white-main);
      }
    }
    &--inputs {
      display: flex;
      justify-content: space-between;
      gap: 12px;

      .input-label {
        flex-grow: 1;
        width: 50%;
        height: auto;

        @media (max-width: $mobile) {
          width: 100%;
        }
      }
    }
  }
  &__section {
    grid-column: span 2 / span 2;
    &--title {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-bottom: 24px;

      color: var(--white-main);

      hr {
        height: 1px;
        width: 14px;
        background-color: var(--white-700);
      }

      .checkbox {
        label {
          color: var(--white-600);

          &:hover {
            color: var(--white-main);
          }
        }
      }
    }
    &--checkbox {
      display: grid;
      gap: 12px;
      grid-template-columns: repeat(2, 1fr);

      @media (max-width: $mobile) {
        grid-template-columns: 1fr;
      }

      .checkbox {
        width: fit-content;
      }
    }

    &--comment {
      display: none;
      width: 100%;
      z-index: 2;
      margin-top: 12px;
      gap: 8px;
      &._open {
        display: flex;
      }

      .input-label:nth-of-type(1) {
        flex-grow: 1;
        background: var(--black-light);

        &:hover,
        &:focus-within {
          background: var(--color-400);
        }
      }
      .input-label:nth-of-type(2) {
        width: 120px;
        min-width: 100px;
        font-size: 12px;
        margin-left: auto;
      }
    }
    &--column {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
    &--item {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      column-gap: 12px;

      input {
        pointer-events: none;
      }
    }
  }
  &__top-item {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
    grid-column: span 2 / span 2;
    & > span {
      color: var(--white-main);
    }

    &.services-for {
      .create-page__input-buttons {
        &::after,
        &::before {
          display: none;
        }
        border-left: 1px solid var(--white-300);
        border-radius: 12px;
        padding-left: 12px;
      }
    }
  }
}
