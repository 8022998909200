@import "src/styles/variables";
.breadcrumbs {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;

    &__link{
        font-size: 13px;
        text-decoration: none;
        transition: color 0.1s ease-in;
        color: var(--color-600);

        &:last-child{
            pointer-events: none;
            cursor: default;
            user-select: none;
            //outline: none;
            tab-index: 1;
            touch-action: none;
        }

        &:not(:last-child){
            color: var(--color-main);
            &:after{
                content: '/';
                margin-left: 8px;
            }
        }

        &:hover:not(:last-child), &:focus-within:not(:last-child){
            color: var(--color-700);
        }

        @media(max-width: $mobile){
            font-size: 13px;
        }
    }
}