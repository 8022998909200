@import "../../styles/variables";

dialog {
  display: flex;
  visibility: hidden;
  transform: scale(0);
  position: fixed;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  color: inherit;
  overflow-y: auto;
  padding: 24px 12px;
  z-index: 10;
  top: 0;
  left: 0;
  transition: all 0.2s ease;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.96);
  scrollbar-gutter: stable;

  .light & {
    background-color: rgba(255, 255, 255, 0.9);
  }

  &.adult {
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
  }
  @media (max-width: $mobile) {
    padding: 24px 12px 76px;
  }

  &[open] {
    visibility: visible;
    transform: scale(1);
  }

  .button__close {
    color: var(--color-main);
    position: absolute;
    right: 16px;
    top: 16px;
    transform: rotate(0);
    transition: all 0.2s ease;
    @media (max-width: $mobile) {
      svg {
        width: 24px;
        height: 24px;
      }
    }

    &:hover {
      transform: rotate(20deg);
    }
  }

  form,
  > div {
    width: 100%;
    margin: auto;
    background-color: var(--bg-main);
    border-radius: 24px;
    border: 1px solid var(--color-300);
    position: relative;
  }
}

.popup-form {
  &__body {
    padding: 40px 12px 24px;
    width: 360px;
    display: flex;
    flex-direction: column;

    h2,
    h3 {
      text-align: center;
    }

    &__super__large {
      max-width: 800px;
      min-width: 320px;
      padding: 40px 12px 24px;
    }

    &__edit {
      max-width: 1000px;
      min-width: 320px;
      padding: 40px 12px 24px;
    }

    &__large {
      width: 460px;
      padding: 40px 12px 24px;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &__medium {
      width: 400px;
      padding: 40px 12px 24px;
    }
  }

  &__subtitle {
    display: inline-block;
    margin-bottom: 14px;
    text-align: center;
    span {
      color: var(--color-600);
    }
  }

  button[type="submit"] {
    justify-content: center;
  }

  &__text {
    font-size: 18px;
    color: var(--color-main);
    text-align: center;
    line-height: 22px;
  }

  &__error {
    color: var(--red-main);
    font-size: 14px;
    margin-top: -8px;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    justify-content: space-between;
  }

  &__box {
    padding-top: 20px;
    display: flex;
    &-title {
      font-family: "TT Ramillas";
      font-size: 22px;
      line-height: 28px;
      font-weight: 200;
      color: var(--color-main);
      margin-bottom: 16px;
      @media (max-width: $mobile) {
        line-height: 23px;
        font-weight: 400;
        font-size: 20px;
      }
    }
    a {
      text-decoration: none;
    }
  }

  &__fake-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    border-radius: 16px;
    min-height: 66px;
    border: 1px solid var(--color-300);
    p {
      color: var(--color-700);
    }

    span {
      font-size: 32px;
      line-height: 42px;
    }
  }

  &__bottom-link {
    justify-content: center;
    flex-direction: row;
    align-items: baseline;
    gap: 8px;

    span {
      font-size: 16px;
      color: var(--color-600);
    }

    button {
      font-size: 16px;
      font-weight: 500;
      color: var(--green-main);

      &:hover,
      &:focus-within {
        text-decoration: underline;
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__scroll {
    &__list {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
  &__radio-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  &__date {
    label {
      display: flex;
      gap: 8px;
      flex-direction: column;
    }
    .dropdowndate__body {
      left: -14px;
      bottom: calc(100% - 350px);
      top: auto;
    }
  }

  &-erocontent {
    .popup-form__fake-input-erocontent {
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      align-items: center;
      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
      }
    }
  }
}
