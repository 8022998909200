@import "src/styles/variables";

.video {
  &__root {
    display: grid;
    grid-template-columns: 30% 1fr auto;
    align-items: center;
    background-color: var(--color-200);
    border-radius: 23px;
    justify-content: space-between;
    padding: 24px;
    height: 184px;

    @media (max-width: $tablet) {
      grid-template-columns: 35% 1fr auto;
    }

    @media (max-width: $tabletSmall) {
      display: flex;
      flex-direction: row;
      padding: 12px;
    }

    @media (max-width: $mobileSmall), (max-width: $mobile) {
      flex-direction: column;
      padding: 16px;
      gap: 24px;
      height: max-content;
    }
  }

  &__user__main {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &.decline {
      opacity: 40%;
    }

    @media (max-width: $mobile) {
      flex-direction: row;
      width: 100%;
      justify-content: space-around;
    }

    @media (max-width: $mobileSmall) {
      justify-content: space-between;
    }
  }

  &__user__block {
    display: flex;
    gap: 12px;
    align-items: center;

    @media (max-width: $tabletSmall), (max-width: $mobile) {
      flex-direction: column;
    }
  }

  &__info__block {
    display: flex;
    gap: 60px;
    align-items: center;

    &.decline {
      opacity: 40%;
    }

    @media (max-width: $tabletSmall) {
      gap: 30px;
    }

    @media (max-width: $mobile) {
      justify-content: space-around;
      width: 100%;
    }

    @media (max-width: $mobileSmall) {
      justify-content: space-between;
    }
  }

  &__user {
    display: flex;
    background-color: var(--color-300);
    border-radius: 12px;
    min-height: 32px;
    align-items: center;
    padding: 4px 8px;
    gap: 8px;

    &__title {
      width: 60px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      word-break: break-all;
    }
  }

  &__price {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__status {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    justify-content: center;

    &.done {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 8px;

      @media (max-width: $mobileSmall), (max-width: $mobile) {
        order: -1;
      }
    }

    &.approve {
      gap: 12px;

      @media (max-width: $mobileSmall), (max-width: $mobile) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: center;
      }
    }

    &.work {
      gap: 12px;
      align-items: normal;

      @media (max-width: $mobileSmall), (max-width: $mobile) {
        align-items: center;
      }
    }

    @media (max-width: $mobileSmall), (max-width: $mobile) {
      width: 100%;
    }
  }

  &__ad {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__photo {
      width: 86px;
      border-radius: 12px;
    }

    span {
      width: 86px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      word-break: break-all;
    }
  }
}
