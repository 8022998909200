.icon-flag {
    display: inline-block;
    cursor: help;
    width: 22px;
    height: 16px;
    background: url('/assets/img/flag-sprite.png') no-repeat top left;

    &-m {
        width: 28px;
        height: 20px;
        background: url('/assets/img/flag-sprite-big.png') no-repeat top left;
    }

    //флаги
    &.AD {
        background-position: 0 0;

        &.icon-flag-m {
            background-position: 0 0;
        }
    }

    &.AE {
        background-position: 0 -26px;

        &.icon-flag-m {
            background-position: 0 -30px;
        }
    }

    &.AF {
        background-position: 0 -52px;

        &.icon-flag-m {
            background-position: 0 -60px;
        }
    }

    &.AG {
        background-position: 0 -78px;

        &.icon-flag-m {
            background-position: 0 -90px;
        }
    }

    &.AI {
        background-position: 0 -104px;

        &.icon-flag-m {
            background-position: 0 -120px;
        }
    }

    &.AL {
        background-position: 0 -130px;

        &.icon-flag-m {
            background-position: 0 -150px;
        }
    }

    &.AM {
        background-position: 0 -156px;

        &.icon-flag-m {
            background-position: 0 -180px;
        }
    }

    &.AN {
        background-position: 0 -182px;

        &.icon-flag-m {
            background-position: 0 -210px;
        }
    }

    &.AO {
        background-position: 0 -208px;

        &.icon-flag-m {
            background-position: 0 -240px;
        }
    }

    &.AR {
        background-position: 0 -234px;

        &.icon-flag-m {
            background-position: 0 -270px;
        }
    }

    &.AT {
        background-position: 0 -260px;

        &.icon-flag-m {
            background-position: 0 -300px;
        }
    }

    &.AU {
        background-position: 0 -286px;

        &.icon-flag-m {
            background-position: 0 -330px;
        }
    }

    &.AW {
        background-position: 0 -312px;

        &.icon-flag-m {
            background-position: 0 -360px;
        }
    }

    &.AX {
        background-position: 0 -338px;

        &.icon-flag-m {
            background-position: 0 -390px;
        }
    }

    &.AZ {
        background-position: 0 -364px;

        &.icon-flag-m {
            background-position: 0 -420px;
        }
    }

    &.BA {
        background-position: 0 -390px;

        &.icon-flag-m {
            background-position: 0 -450px;
        }
    }

    &.BB {
        background-position: 0 -416px;

        &.icon-flag-m {
            background-position: 0 -480px;
        }
    }

    &.BD {
        background-position: 0 -442px;

        &.icon-flag-m {
            background-position: 0 -510px;
        }
    }

    &.BE {
        background-position: 0 -468px;

        &.icon-flag-m {
            background-position: 0 -540px;
        }
    }

    &.BF {
        background-position: 0 -494px;

        &.icon-flag-m {
            background-position: 0 -570px;
        }
    }

    &.BG {
        background-position: 0 -520px;

        &.icon-flag-m {
            background-position: 0 -600px;
        }
    }

    &.BH {
        background-position: 0 -546px;

        &.icon-flag-m {
            background-position: 0 -630px;
        }
    }

    &.BI {
        background-position: 0 -572px;

        &.icon-flag-m {
            background-position: 0 -660px;
        }
    }

    &.BJ {
        background-position: 0 -598px;

        &.icon-flag-m {
            background-position: 0 -690px;
        }
    }

    &.BM {
        background-position: 0 -624px;

        &.icon-flag-m {
            background-position: 0 -720px;
        }
    }

    &.BN {
        background-position: 0 -650px;

        &.icon-flag-m {
            background-position: 0 -750px;
        }
    }

    &.BO {
        background-position: 0 -676px;

        &.icon-flag-m {
            background-position: 0 -780px;
        }
    }

    &.BR {
        background-position: 0 -702px;

        &.icon-flag-m {
            background-position: 0 -810px;
        }
    }

    &.BS {
        background-position: 0 -728px;

        &.icon-flag-m {
            background-position: 0 -840px;
        }
    }

    &.BT {
        background-position: 0 -754px;

        &.icon-flag-m {
            background-position: 0 -870px;
        }
    }

    &.BW {
        background-position: 0 -780px;

        &.icon-flag-m {
            background-position: 0 -900px;
        }
    }

    &.BY {
        background-position: 0 -806px;

        &.icon-flag-m {
            background-position: 0 -930px;
        }
    }

    &.BZ {
        background-position: 0 -832px;

        &.icon-flag-m {
            background-position: 0 -960px;
        }
    }

    &.CA {
        background-position: 0 -858px;

        &.icon-flag-m {
            background-position: 0 -990px;
        }
    }

    &.CAF {
        background-position: 0 -884px;

        &.icon-flag-m {
            background-position: 0 -1020px;
        }
    }

    &.CAS {
        background-position: 0 -910px;

        &.icon-flag-m {
            background-position: 0 -1050px;
        }
    }

    &.CD {
        background-position: 0 -936px;

        &.icon-flag-m {
            background-position: 0 -1080px;
        }
    }

    &.CEU {
        background-position: 0 -962px;

        &.icon-flag-m {
            background-position: 0 -1110px;
        }
    }

    &.CF {
        background-position: 0 -988px;

        &.icon-flag-m {
            background-position: 0 -1140px;
        }
    }

    &.CG {
        background-position: 0 -1014px;

        &.icon-flag-m {
            background-position: 0 -1170px;
        }
    }

    &.CH {
        background-position: 0 -1040px;

        &.icon-flag-m {
            background-position: 0 -1200px;
        }
    }

    &.CI {
        background-position: 0 -1066px;

        &.icon-flag-m {
            background-position: 0 -1230px;
        }
    }

    &.CL {
        background-position: 0 -1092px;

        &.icon-flag-m {
            background-position: 0 -1260px;
        }
    }

    &.CM {
        background-position: 0 -1118px;

        &.icon-flag-m {
            background-position: 0 -1290px;
        }
    }

    &.CN {
        background-position: 0 -1144px;

        &.icon-flag-m {
            background-position: 0 -1320px;
        }
    }

    &.CNA {
        background-position: 0 -1170px;

        &.icon-flag-m {
            background-position: 0 -1350px;
        }
    }

    &.CO {
        background-position: 0 -1196px;

        &.icon-flag-m {
            background-position: 0 -1380px;
        }
    }

    &.COC {
        background-position: 0 -1222px;

        &.icon-flag-m {
            background-position: 0 -1410px;
        }
    }

    &.CR {
        background-position: 0 -1248px;

        &.icon-flag-m {
            background-position: 0 -1440px;
        }
    }

    &.CSA {
        background-position: 0 -1274px;

        &.icon-flag-m {
            background-position: 0 -1470px;
        }
    }

    &.CU {
        background-position: 0 -1300px;

        &.icon-flag-m {
            background-position: 0 -1500px;
        }
    }

    &.CV {
        background-position: 0 -1326px;

        &.icon-flag-m {
            background-position: 0 -1530px;
        }
    }

    &.CY {
        background-position: 0 -1352px;

        &.icon-flag-m {
            background-position: 0 -1560px;
        }
    }

    &.CZ {
        background-position: 0 -1378px;

        &.icon-flag-m {
            background-position: 0 -1590px;
        }
    }

    &.DE {
        background-position: 0 -1404px;

        &.icon-flag-m {
            background-position: 0 -1620px;
        }
    }

    &.DJ {
        background-position: 0 -1430px;

        &.icon-flag-m {
            background-position: 0 -1650px;
        }
    }

    &.DK {
        background-position: 0 -1456px;

        &.icon-flag-m {
            background-position: 0 -1680px;
        }
    }

    &.DM {
        background-position: 0 -1482px;

        &.icon-flag-m {
            background-position: 0 -1710px;
        }
    }

    &.DO {
        background-position: 0 -1508px;

        &.icon-flag-m {
            background-position: 0 -1740px;
        }
    }

    &.DZ {
        background-position: 0 -1534px;

        &.icon-flag-m {
            background-position: 0 -1770px;
        }
    }

    &.EC {
        background-position: 0 -1560px;

        &.icon-flag-m {
            background-position: 0 -1800px;
        }
    }

    &.EE {
        background-position: 0 -1586px;

        &.icon-flag-m {
            background-position: 0 -1830px;
        }
    }

    &.EG {
        background-position: 0 -1612px;

        &.icon-flag-m {
            background-position: 0 -1860px;
        }
    }

    &.ER {
        background-position: 0 -1638px;

        &.icon-flag-m {
            background-position: 0 -1890px;
        }
    }

    &.ES {
        background-position: 0 -1664px;

        &.icon-flag-m {
            background-position: 0 -1920px;
        }
    }

    &.ET {
        background-position: 0 -1690px;

        &.icon-flag-m {
            background-position: 0 -1950px;
        }
    }

    &.EU {
        background-position: 0 -1716px;

        &.icon-flag-m {
            background-position: 0 -1980px;
        }
    }

    &.FI {
        background-position: 0 -1742px;

        &.icon-flag-m {
            background-position: 0 -2010px;
        }
    }

    &.FJ {
        background-position: 0 -1768px;

        &.icon-flag-m {
            background-position: 0 -2040px;
        }
    }

    &.FK {
        background-position: 0 -1794px;

        &.icon-flag-m {
            background-position: 0 -2070px;
        }
    }

    &.FM {
        background-position: 0 -1820px;

        &.icon-flag-m {
            background-position: 0 -2100px;
        }
    }

    &.FR {
        background-position: 0 -1846px;

        &.icon-flag-m {
            background-position: 0 -2130px;
        }
    }

    &.GA {
        background-position: 0 -1872px;

        &.icon-flag-m {
            background-position: 0 -2160px;
        }
    }

    &.EN {
        background-position: 0 -1898px;

        &.icon-flag-m {
            background-position: 0 -2190px;
        }
    }

    &.GD {
        background-position: 0 -1924px;

        &.icon-flag-m {
            background-position: 0 -2220px;
        }
    }

    &.GE {
        background-position: 0 -1950px;

        &.icon-flag-m {
            background-position: 0 -2250px;
        }
    }

    &.GG {
        background-position: 0 -1976px;

        &.icon-flag-m {
            background-position: 0 -2280px;
        }
    }

    &.GH {
        background-position: 0 -2002px;

        &.icon-flag-m {
            background-position: 0 -2310px;
        }
    }

    &.GI {
        background-position: 0 -2028px;

        &.icon-flag-m {
            background-position: 0 -2340px;
        }
    }

    &.GM {
        background-position: 0 -2054px;

        &.icon-flag-m {
            background-position: 0 -2370px;
        }
    }

    &.GN {
        background-position: 0 -2080px;

        &.icon-flag-m {
            background-position: 0 -2400px;
        }
    }

    &.GQ {
        background-position: 0 -2106px;

        &.icon-flag-m {
            background-position: 0 -2430px;
        }
    }

    &.GR {
        background-position: 0 -2132px;

        &.icon-flag-m {
            background-position: 0 -2460px;
        }
    }

    &.GT {
        background-position: 0 -2158px;

        &.icon-flag-m {
            background-position: 0 -2490px;
        }
    }

    &.GW {
        background-position: 0 -2184px;

        &.icon-flag-m {
            background-position: 0 -2520px;
        }
    }

    &.GY {
        background-position: 0 -2210px;

        &.icon-flag-m {
            background-position: 0 -2550px;
        }
    }

    &.HK {
        background-position: 0 -2236px;

        &.icon-flag-m {
            background-position: 0 -2580px;
        }
    }

    &.HN {
        background-position: 0 -2262px;

        &.icon-flag-m {
            background-position: 0 -2610px;
        }
    }

    &.HR {
        background-position: 0 -2288px;

        &.icon-flag-m {
            background-position: 0 -2640px;
        }
    }

    &.HT {
        background-position: 0 -2314px;

        &.icon-flag-m {
            background-position: 0 -2670px;
        }
    }

    &.HU {
        background-position: 0 -2340px;

        &.icon-flag-m {
            background-position: 0 -2700px;
        }
    }

    &.ID {
        background-position: 0 -2366px;

        &.icon-flag-m {
            background-position: 0 -2730px;
        }
    }

    &.IE {
        background-position: 0 -2392px;

        &.icon-flag-m {
            background-position: 0 -2760px;
        }
    }

    &.IL {
        background-position: 0 -2418px;

        &.icon-flag-m {
            background-position: 0 -2790px;
        }
    }

    &.IM {
        background-position: 0 -2444px;

        &.icon-flag-m {
            background-position: 0 -2820px;
        }
    }

    &.IN {
        background-position: 0 -2470px;

        &.icon-flag-m {
            background-position: 0 -2850px;
        }
    }

    &.IQ {
        background-position: 0 -2496px;

        &.icon-flag-m {
            background-position: 0 -2880px;
        }
    }

    &.IR {
        background-position: 0 -2522px;

        &.icon-flag-m {
            background-position: 0 -2910px;
        }
    }

    &.IS {
        background-position: 0 -2548px;

        &.icon-flag-m {
            background-position: 0 -2940px;
        }
    }

    &.IT {
        background-position: 0 -2574px;

        &.icon-flag-m {
            background-position: 0 -2970px;
        }
    }

    &.JE {
        background-position: 0 -2600px;

        &.icon-flag-m {
            background-position: 0 -3000px;
        }
    }

    &.JM {
        background-position: 0 -2626px;

        &.icon-flag-m {
            background-position: 0 -3030px;
        }
    }

    &.JO {
        background-position: 0 -2652px;

        &.icon-flag-m {
            background-position: 0 -3060px;
        }
    }

    &.JP {
        background-position: 0 -2678px;

        &.icon-flag-m {
            background-position: 0 -3090px;
        }
    }

    &.KE {
        background-position: 0 -2704px;

        &.icon-flag-m {
            background-position: 0 -3120px;
        }
    }

    &.KG {
        background-position: 0 -2730px;

        &.icon-flag-m {
            background-position: 0 -3150px;
        }
    }

    &.KH {
        background-position: 0 -2756px;

        &.icon-flag-m {
            background-position: 0 -3180px;
        }
    }

    &.KM {
        background-position: 0 -2782px;

        &.icon-flag-m {
            background-position: 0 -3210px;
        }
    }

    &.KN {
        background-position: 0 -2808px;

        &.icon-flag-m {
            background-position: 0 -3240px;
        }
    }

    &.KP {
        background-position: 0 -2834px;

        &.icon-flag-m {
            background-position: 0 -3270px;
        }
    }

    &.KR {
        background-position: 0 -2860px;

        &.icon-flag-m {
            background-position: 0 -3300px;
        }
    }

    &.KW {
        background-position: 0 -2886px;

        &.icon-flag-m {
            background-position: 0 -3330px;
        }
    }

    &.KY {
        background-position: 0 -2912px;

        &.icon-flag-m {
            background-position: 0 -3360px;
        }
    }

    &.KZ {
        background-position: 0 -2938px;

        &.icon-flag-m {
            background-position: 0 -3390px;
        }
    }

    &.LA {
        background-position: 0 -2964px;

        &.icon-flag-m {
            background-position: 0 -3420px;
        }
    }

    &.LB {
        background-position: 0 -2990px;

        &.icon-flag-m {
            background-position: 0 -3450px;
        }
    }

    &.LC {
        background-position: 0 -3016px;

        &.icon-flag-m {
            background-position: 0 -3480px;
        }
    }

    &.LI {
        background-position: 0 -3042px;

        &.icon-flag-m {
            background-position: 0 -3510px;
        }
    }

    &.LK {
        background-position: 0 -3068px;

        &.icon-flag-m {
            background-position: 0 -3540px;
        }
    }

    &.LR {
        background-position: 0 -3094px;

        &.icon-flag-m {
            background-position: 0 -3570px;
        }
    }

    &.LS {
        background-position: 0 -3120px;

        &.icon-flag-m {
            background-position: 0 -3600px;
        }
    }

    &.LT {
        background-position: 0 -3146px;

        &.icon-flag-m {
            background-position: 0 -3630px;
        }
    }

    &.LU {
        background-position: 0 -3172px;

        &.icon-flag-m {
            background-position: 0 -3660px;
        }
    }

    &.LV {
        background-position: 0 -3198px;

        &.icon-flag-m {
            background-position: 0 -3690px;
        }
    }

    &.LY {
        background-position: 0 -3224px;

        &.icon-flag-m {
            background-position: 0 -3720px;
        }
    }

    &.MA {
        background-position: 0 -3250px;

        &.icon-flag-m {
            background-position: 0 -3750px;
        }
    }

    &.MC {
        background-position: 0 -3276px;

        &.icon-flag-m {
            background-position: 0 -3780px;
        }
    }

    &.MD {
        background-position: 0 -3302px;

        &.icon-flag-m {
            background-position: 0 -3810px;
        }
    }

    &.ME {
        background-position: 0 -3328px;

        &.icon-flag-m {
            background-position: 0 -3840px;
        }
    }

    &.MG {
        background-position: 0 -3354px;

        &.icon-flag-m {
            background-position: 0 -3870px;
        }
    }

    &.MK {
        background-position: 0 -3380px;

        &.icon-flag-m {
            background-position: 0 -3900px;
        }
    }

    &.ML {
        background-position: 0 -3406px;

        &.icon-flag-m {
            background-position: 0 -3930px;
        }
    }

    &.MM {
        background-position: 0 -3432px;

        &.icon-flag-m {
            background-position: 0 -3960px;
        }
    }

    &.MN {
        background-position: 0 -3458px;

        &.icon-flag-m {
            background-position: 0 -3990px;
        }
    }

    &.MO {
        background-position: 0 -3484px;

        &.icon-flag-m {
            background-position: 0 -4020px;
        }
    }

    &.MR {
        background-position: 0 -3510px;

        &.icon-flag-m {
            background-position: 0 -4050px;
        }
    }

    &.MS {
        background-position: 0 -3536px;

        &.icon-flag-m {
            background-position: 0 -4080px;
        }
    }

    &.MT {
        background-position: 0 -3562px;

        &.icon-flag-m {
            background-position: 0 -4110px;
        }
    }

    &.MU {
        background-position: 0 -3588px;

        &.icon-flag-m {
            background-position: 0 -4140px;
        }
    }

    &.MV {
        background-position: 0 -3614px;

        &.icon-flag-m {
            background-position: 0 -4170px;
        }
    }

    &.MW {
        background-position: 0 -3640px;

        &.icon-flag-m {
            background-position: 0 -4200px;
        }
    }

    &.MX {
        background-position: 0 -3666px;

        &.icon-flag-m {
            background-position: 0 -4230px;
        }
    }

    &.MY {
        background-position: 0 -3692px;

        &.icon-flag-m {
            background-position: 0 -4260px;
        }
    }

    &.MZ {
        background-position: 0 -3718px;

        &.icon-flag-m {
            background-position: 0 -4290px;
        }
    }

    &.NA {
        background-position: 0 -3744px;

        &.icon-flag-m {
            background-position: 0 -4320px;
        }
    }

    &.NE {
        background-position: 0 -3770px;

        &.icon-flag-m {
            background-position: 0 -4350px;
        }
    }

    &.NG {
        background-position: 0 -3796px;

        &.icon-flag-m {
            background-position: 0 -4380px;
        }
    }

    &.NI {
        background-position: 0 -3822px;

        &.icon-flag-m {
            background-position: 0 -4410px;
        }
    }

    &.NL {
        background-position: 0 -3848px;

        &.icon-flag-m {
            background-position: 0 -4440px;
        }
    }

    &.NO {
        background-position: 0 -3874px;

        &.icon-flag-m {
            background-position: 0 -4470px;
        }
    }

    &.NP {
        background-position: 0 -3900px;

        &.icon-flag-m {
            background-position: 0 -4500px;
        }
    }

    &.NZ {
        background-position: 0 -3926px;

        &.icon-flag-m {
            background-position: 0 -4530px;
        }
    }

    &.OM {
        background-position: 0 -3952px;

        &.icon-flag-m {
            background-position: 0 -4560px;
        }
    }

    &.PA {
        background-position: 0 -3978px;

        &.icon-flag-m {
            background-position: 0 -4590px;
        }
    }

    &.PE {
        background-position: 0 -4004px;

        &.icon-flag-m {
            background-position: 0 -4620px;
        }
    }

    &.PF {
        background-position: 0 -4030px;

        &.icon-flag-m {
            background-position: 0 -4650px;
        }
    }

    &.PG {
        background-position: 0 -4056px;

        &.icon-flag-m {
            background-position: 0 -4680px;
        }
    }

    &.PH {
        background-position: 0 -4082px;

        &.icon-flag-m {
            background-position: 0 -4710px;
        }
    }

    &.PK {
        background-position: 0 -4108px;

        &.icon-flag-m {
            background-position: 0 -4740px;
        }
    }

    &.PL {
        background-position: 0 -4134px;

        &.icon-flag-m {
            background-position: 0 -4770px;
        }
    }

    &.PR {
        background-position: 0 -4160px;

        &.icon-flag-m {
            background-position: 0 -4800px;
        }
    }

    &.PT {
        background-position: 0 -4186px;

        &.icon-flag-m {
            background-position: 0 -4830px;
        }
    }

    &.PW {
        background-position: 0 -4212px;

        &.icon-flag-m {
            background-position: 0 -4860px;
        }
    }

    &.PY {
        background-position: 0 -4238px;

        &.icon-flag-m {
            background-position: 0 -4890px;
        }
    }

    &.QA {
        background-position: 0 -4264px;

        &.icon-flag-m {
            background-position: 0 -4920px;
        }
    }

    &.RO {
        background-position: 0 -4290px;

        &.icon-flag-m {
            background-position: 0 -4950px;
        }
    }

    &.RS {
        background-position: 0 -4316px;

        &.icon-flag-m {
            background-position: 0 -4980px;
        }
    }

    &.RU {
        background-position: 0 -4342px;

        &.icon-flag-m {
            background-position: 0 -5010px;
        }
    }

    &.RW {
        background-position: 0 -4368px;

        &.icon-flag-m {
            background-position: 0 -5040px;
        }
    }

    &.SA {
        background-position: 0 -4394px;

        &.icon-flag-m {
            background-position: 0 -5070px;
        }
    }

    &.SB {
        background-position: 0 -4420px;

        &.icon-flag-m {
            background-position: 0 -5100px;
        }
    }

    &.SC {
        background-position: 0 -4446px;

        &.icon-flag-m {
            background-position: 0 -5130px;
        }
    }

    &.SD {
        background-position: 0 -4472px;

        &.icon-flag-m {
            background-position: 0 -5160px;
        }
    }

    &.SE {
        background-position: 0 -4498px;

        &.icon-flag-m {
            background-position: 0 -5190px;
        }
    }

    &.SG {
        background-position: 0 -4524px;

        &.icon-flag-m {
            background-position: 0 -5220px;
        }
    }

    &.SH {
        background-position: 0 -4550px;

        &.icon-flag-m {
            background-position: 0 -5250px;
        }
    }

    &.SI {
        background-position: 0 -4576px;

        &.icon-flag-m {
            background-position: 0 -5280px;
        }
    }

    &.SK {
        background-position: 0 -4602px;

        &.icon-flag-m {
            background-position: 0 -5310px;
        }
    }

    &.SL {
        background-position: 0 -4628px;

        &.icon-flag-m {
            background-position: 0 -5340px;
        }
    }

    &.SM {
        background-position: 0 -4654px;

        &.icon-flag-m {
            background-position: 0 -5370px;
        }
    }

    &.SN {
        background-position: 0 -4680px;

        &.icon-flag-m {
            background-position: 0 -5400px;
        }
    }

    &.SO {
        background-position: 0 -4706px;

        &.icon-flag-m {
            background-position: 0 -5430px;
        }
    }

    &.SR {
        background-position: 0 -4732px;

        &.icon-flag-m {
            background-position: 0 -5460px;
        }
    }

    &.ST {
        background-position: 0 -4758px;

        &.icon-flag-m {
            background-position: 0 -5490px;
        }
    }

    &.SV {
        background-position: 0 -4784px;

        &.icon-flag-m {
            background-position: 0 -5520px;
        }
    }

    &.SY {
        background-position: 0 -4810px;

        &.icon-flag-m {
            background-position: 0 -5550px;
        }
    }

    &.SZ {
        background-position: 0 -4836px;

        &.icon-flag-m {
            background-position: 0 -5580px;
        }
    }

    &.TC {
        background-position: 0 -4862px;

        &.icon-flag-m {
            background-position: 0 -5610px;
        }
    }

    &.TD {
        background-position: 0 -4888px;

        &.icon-flag-m {
            background-position: 0 -5640px;
        }
    }

    &.TG {
        background-position: 0 -4914px;

        &.icon-flag-m {
            background-position: 0 -5670px;
        }
    }

    &.TH {
        background-position: 0 -4940px;

        &.icon-flag-m {
            background-position: 0 -5700px;
        }
    }

    &.TJ {
        background-position: 0 -4966px;

        &.icon-flag-m {
            background-position: 0 -5730px;
        }
    }

    &.TL {
        background-position: 0 -4992px;

        &.icon-flag-m {
            background-position: 0 -5760px;
        }
    }

    &.TM {
        background-position: 0 -5018px;

        &.icon-flag-m {
            background-position: 0 -5790px;
        }
    }

    &.TN {
        background-position: 0 -5044px;

        &.icon-flag-m {
            background-position: 0 -5820px;
        }
    }

    &.TO {
        background-position: 0 -5070px;

        &.icon-flag-m {
            background-position: 0 -5850px;
        }
    }

    &.TR {
        background-position: 0 -5096px;

        &.icon-flag-m {
            background-position: 0 -5880px;
        }
    }

    &.TT {
        background-position: 0 -5122px;

        &.icon-flag-m {
            background-position: 0 -5910px;
        }
    }

    &.TW {
        background-position: 0 -5148px;

        &.icon-flag-m {
            background-position: 0 -5940px;
        }
    }

    &.TZ {
        background-position: 0 -5174px;

        &.icon-flag-m {
            background-position: 0 -5970px;
        }
    }

    &.UA {
        background-position: 0 -5200px;

        &.icon-flag-m {
            background-position: 0 -6000px;
        }
    }

    &.UG {
        background-position: 0 -5226px;

        &.icon-flag-m {
            background-position: 0 -6030px;
        }
    }

    &.US {
        background-position: 0 -5252px;

        &.icon-flag-m {
            background-position: 0 -6060px;
        }
    }

    &.UY {
        background-position: 0 -5278px;

        &.icon-flag-m {
            background-position: 0 -6090px;
        }
    }

    &.UZ {
        background-position: 0 -5304px;

        &.icon-flag-m {
            background-position: 0 -6120px;
        }
    }

    &.VC {
        background-position: 0 -5330px;

        &.icon-flag-m {
            background-position: 0 -6150px;
        }
    }

    &.VE {
        background-position: 0 -5356px;

        &.icon-flag-m {
            background-position: 0 -6180px;
        }
    }

    &.VG {
        background-position: 0 -5382px;

        &.icon-flag-m {
            background-position: 0 -6210px;
        }
    }

    &.VN {
        background-position: 0 -5408px;

        &.icon-flag-m {
            background-position: 0 -6240px;
        }
    }

    &.VU {
        background-position: 0 -5434px;

        &.icon-flag-m {
            background-position: 0 -6270px;
        }
    }

    &.WS {
        background-position: 0 -5460px;

        &.icon-flag-m {
            background-position: 0 -6300px;
        }
    }

    &.WW {
        background-position: 0 -5486px;

        &.icon-flag-m {
            background-position: 0 -6330px;
        }
    }

    &.YE {
        background-position: 0 -5512px;

        &.icon-flag-m {
            background-position: 0 -6360px;
        }
    }

    &.ZA {
        background-position: 0 -5538px;

        &.icon-flag-m {
            background-position: 0 -6390px;
        }
    }

    &.ZM {
        background-position: 0 -5564px;

        &.icon-flag-m {
            background-position: 0 -6420px;
        }
    }

    &.ZW {
        background-position: 0 -5590px;

        &.icon-flag-m {
            background-position: 0 -6450px;
        }
    }
}