@import "./src/styles/variables.scss";

.reviews-page {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-top: 24px;
    padding-bottom: 24px;
    @media (max-width: $mobile) {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }

  &__filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    @media (max-width: $mobile) {
      padding-top: 12px;
      display: none;
    }
  }

  &__filter__mobile {
    display: none;

    @media (max-width: $mobile) {
      display: flex;
    }
  }

  &__header {
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;

    @media (max-width: $mobile) {
      grid-template-columns: max-content max-content;
      justify-content: space-between;
      gap: 16px;
    }

    @media (max-width: $mobileExtraSmall) {
      grid-template-columns: 1fr 1fr;
      justify-content: space-between;
      gap: 16px;
    }
  }
}
