.upload__zone {
  position: relative;
  display: flex;
  border-radius: 12px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border: 2px dashed var(--green-600);
  width: 100%;
  min-height: 200px;
  cursor: pointer;

  &:hover {
    border-color: var(--green-main);

    input {
      pointer-events: all;
      cursor: pointer;
    }
  }
  &.drag {
    border-color: var(--green-main);
  }

  input {
    pointer-events: none;
    opacity: 0;
    width: 100%;
    height: 100%;
  }

  &__main {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;
    padding: 20px;
    // color: var(--green-main);
  }
}

.inputDisplay {
  display: none;
}
