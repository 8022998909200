@import "./src/styles/variables.scss";

.create-page {
  &__main {
    display: flex;
    flex-direction: column;
    max-width: 1040px;
    width: 100%;
    gap: 24px;
    @media (max-width: $tabletSmall) {
      max-width: 100%;
      margin-left: unset;
    }

    .create-page__main--title {
      font-size: 22px;
      color: var(--white-main);
      margin-bottom: 8px;
    }

    
  }

  &__city-block {
    &._error {
      margin-bottom: 16px;
    }
    position: relative;
    grid-column: 1;

    @media (max-width: $mobile) {
      grid-column: span 2 / span 2;
    }
  }

  &__form {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 26px 16px;
    width: 100%;

    .select {
      .d-flex {
        .checkbox {
          background-color: var(--color-300);

          label {
            padding: 12px 26px 12px 40px;
          }
          input {
            left: 16px;
            pointer-events: none;
          }
        }
      }
    }

    @media (max-width: $mobile) {
      gap: 12px 8px;
      grid-template-columns: repeat(2, 1fr);

      &.main {
        .input-label:nth-of-type(1),
        .input-label:nth-of-type(2) {
          grid-column: span 2 / span 2;
        }
        // .input-label:nth-of-type(n + 3) {
        //   grid-column: span 1 / span 1;
        // }
      }
    }
    &.photo {
      display: flex;
      flex-direction: column;
      // gap: 16px;
    }

    .error {
      color: var(--red-800);
      font-size: 13px;
    }

    .input-label {
      border-radius: 12px;
      height: 60px;
    }

    .create-nations {
      .select__body {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        .select__search {
          grid-column: span 2 / span 2;
          margin-bottom: 16px;
        }

        .dropdown__search-select {
          width: max-content;
        }
      }
    }

    ._required {
      &::after {
        position: absolute;
        content: "*";
        color: var(--green-main);
        top: 5px;
        right: 9px;
        font-size: 14px;
      }
    }

    .select {
      height: 60px;
      .select__arrow {
        transition: all 0.3s;
        right: 16px;
        top: 20px;
        position: absolute;
      }
    }

    .create-lang {
      position: relative;
      justify-content: flex-start;
      height: 60px;
      background: var(--black-light);
      transition: background-color var(--switch-theme-time);
      border-radius: 12px;
      color: var(--color-600);

      .select__arrow {
        transition: all 0.3s;
        position: absolute;
        right: 16px;
        top: 20px;
      }

      &:hover {
        background: var(--color-400);
      }
    }
    .create-lang--current {
      grid-column: span 2 / span 3;
      display: flex;
      flex-wrap: wrap;
      gap: 12px;

      .select__item {
        width: fit-content;
        gap: 6px;
        border-radius: 12px;
        height: 60px;
        padding: 20px 18px;
        background: var(--black-light);
        transition: background-color var(--switch-theme-time);
        color: var(--color-600);
        display: flex;
        justify-content: center;
        align-items: center;

        .icon--close {
          cursor: pointer;
          order: -1;
        }
      }
    }

    .select-city {
      grid-column: span 1;
      grid-column-start: 1;

      font-size: 13px;
      line-height: 16px;
      padding: 8px 16px;

      background: var(--black-light);
      transition: background-color var(--switch-theme-time);
      color: var(--color-600);
      border: none;
      border-radius: 12px;

      &:hover {
        background: var(--color-400);
      }
      .select__arrow {
        transition: all 0.3s;
        position: absolute;
        right: 16px;
        top: 20px;
      }
      .select__flag {
        width: 22px;
        height: 16px;
      }
    }

    .search {
      height: 60px;
      grid-column: span 2 / span 3;
      &._disabled {
        .search__label {
          pointer-events: none;
          opacity: 0.6;
        }
      }
      .search__label {
        border-radius: 12px;
      }
    }

    @media (max-width: $mobile) {
      .select-city,
      .search {
        grid-column: span 2 / span 2;
      }
      .header__search-input {
        padding-top: unset;
      }
    }
  }
  &__noted {
    grid-column: span 3 / span 3;
    font-weight: 500;
    line-height: 22px;
    &-colored {
      color: var(--green-main);
    }

    @media (max-width: $mobile) {
      grid-column: span 2 / span 2;
    }
  }
  &__map {
    grid-column: span 3 / span 3;
    height: 300px;
    @media (max-width: $mobile) {
      grid-column: span 2 / span 2;
    }
  }
}

._required {
  &::after {
    position: absolute;
    content: "*";
    color: var(--green-main);
    top: 5px;
    right: 9px;
    font-size: 14px;
  }
}

._error {
  margin-bottom: 16px;
}
