@import "src/styles/variables";

[data-counter] {
  position: relative;
  &:after {
    content: attr(data-counter);
    position: absolute;
    top: -2px;
    right: -2px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 14px;
    padding: 0 2px;
    aspect-ratio: 1 / 1;
    line-height: 1;
    border-radius: 50%;
    background-color: var(--color-main);
    color: var(--color-main-reverse);
    transition: color var(--switch-theme-time),
      background-color var(--switch-theme-time);
    font-size: 10px;
    &:empty {
      display: none;
    }
    @media (max-width: $tablet) {
      top: -4px;
      right: -6px;
    }
  }

  &.green {
    &:after{
    color: var(--white-main);
    background-color: var(--green-main);
    }
  }

  &.empty {
    &:after {
      display: none;
    }
  }
}

.button {
  border-radius: 12px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  transition: all var(--switch-theme-time);
  font-weight: 600;
  font-size: 16px;
  @media (max-width: $mobile) {
    font-size: 15px;
  }

  &-l {
    padding: 8px 16px;
    min-height: 60px;
    @media (max-width: $mobile) {
      min-height: 40px;
    }
  }

  &-m {
    padding: 10px 16px;
    min-height: 44px;
  }

  &-s,
  &-xs {
    font-size: 14px;
    @media (max-width: $mobile) {
      font-size: 13px;
    }
  }

  &-s {
    padding: 10px 12px;
    min-height: 40px;
  }

  &-xs {
    padding: 6px 8px;
    font-weight: 500;
    min-height: 32px;
  }

  &--black {
    background-color: var(--bg-main);
    color: var(--white-main);

    i:after {
      background-color: var(--white-main);
    }
  }

  &--primary {
    background-color: var(--white-800);
    color: var(--color-main-reverse);

    i:after {
      background-color: var(--color-main-reverse);
    }

    &:hover,
    &:focus-within {
      background-color: var(--white-main);
    }

    .light & {
      background-color: var(--black-700);

      &:hover,
      &:focus-within {
        background-color: var(--black-main);
      }
    }
  }

  &--error {
    background-color: var(--red-300);
    color: var(--red-800);

    &:hover,
    &:focus-within {
      background-color: var(--red-500);
    }
  }

  &--secondary {
    background-color: var(--color-400);
    color: var(--color-main);

    &:hover,
    &:focus-within {
      background-color: var(--black-light);
    }
  }

  &--tetriary {
    background-color: var(--color-300);
    color: var(--color-main);

    &:hover,
    &:focus-within {
      background-color: var(--color-400);
    }
  }

  &--green {
    color: var(--white-main);
    background-color: var(--green-main);

    i:after {
      background-color: var(--white-main);
    }

    &:hover,
    &:focus-within {
      background-color: var(--green-700);
    }
  }

  &--green__secondary {
    color: var(--white-main);
    background-color: var(--green-400);

    i:after {
      background-color: var(--white-main);
    }

    &:hover,
    &:focus-within {
      background-color: var(--green-700);
    }
  }

  &--green-accent {
    color: var(--white-main);
    background-color: var(--green-accent);

    i:after {
      background-color: var(--white-main);
    }

    &:hover,
    &:focus-within {
      opacity: 0.7;
    }
  }

  &_outline {
    &--black {
      border: 1px solid var(--white-400);

      &:hover,
      &:focus-within {
        background-color: var(--white-400);
      }

      .light & {
        border: 1px solid var(--black-400);

        &:hover,
        &:focus-within {
          background-color: var(--black-400);
        }
      }
    }

    &--green {
      color: var(--green-main);
      border: 1px solid var(--green-main);
      background-color: transparent;
      i:after {
        background-color: var(--green-main);
      }

      &:hover,
      &:focus-within {
        border: 1px solid var(--green-700);
        background-color: var(--green-200);
      }
    }

    &--green-accent {
      background-color: transparent;
      color: var(--green-main);
      border: 1px solid var(--green-main);
      &:hover,
      &:focus-within {
        background-color: var(--green-main);
        color: var(--white-main);
      }
    }

    &--spec-green {
      background: transparent;
      color: var(--green-main);
      border: 1px solid var(--green-main);
      background-blend-mode: color, normal;
      &:hover,
      &:focus-within {
        background: linear-gradient(0deg, #00a480, #00a480),
          linear-gradient(
            76.82deg,
            #546265 11.6%,
            #9da1a1 25.31%,
            #828b8a 48.06%,
            #546265 55.72%,
            #546265 77.23%,
            #747a7b 85.34%,
            #546265 91.31%
          );
        color: var(--white-main);
      }
    }

    &--spec-yellow {
      background: transparent;
      color: var(--gold-main);
      border: 1px solid var(--gold-main);
      background-blend-mode: color, normal;
      &:hover,
      &:focus-within {
        background: linear-gradient(
          49.58deg,
          #b07d20 39.69%,
          #b07d20 59.3%,
          #ffda44 84.29%,
          #ffda44 104.7%,
          #b07d20 122.24%,
          #b07d20 148.51%
        );
        color: var(--white-main);
      }
    }
  }

  &_no-fill {
    background-color: transparent;
    border-radius: 0;

    &__hover {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &._disabled,
  &[disabled] {
    filter: grayscale(0.5);
    opacity: 0.4;
    pointer-events: none;
  }

  &._no-underline {
    text-decoration: none;
  }
}

.button-square {
  border-radius: 12px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all var(--switch-theme-time);
  aspect-ratio: 1/1;
  text-decoration: none !important;
  &:hover{
    text-decoration: none;
  }
  svg,
  i {
    width: 28px;
    height: 28px;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &-l {
    min-width: 60px;
    width: 60px;
    @media (max-width: $mobile) {
      min-width: 40px;
      width: 40px;
    }
  }

  &-m {
    min-width: 44px;
    width: 44px;
    svg,
    i {
      width: 28px;
      height: 28px;
    }
  }

  &-s {
    min-width: 40px;
    width: 40px;
    svg,
    i {
      width: 24px;
      height: 24px;
      background-size: 24px;
    }
  }

  &-xs {
    min-width: 32px;
    width: 32px;
    svg,
    i {
      width: 20px;
      height: 20px;
      background-size: 20px;
    }
  }

  &-2xs {
    min-width: 24px;
    width: 24px;
    border-radius: 8px;
    svg,
    i {
      width: 20px;
      height: 20px;
      background-size: 20px;
    }
  }

  &._disabled,
  &[disabled] {
    filter: grayscale(0.5);
    opacity: 0.4;
    pointer-events: none;
  }
}

.w-160 {
  width: 160px;
}
