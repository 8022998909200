@import "./src/styles/variables";

.root {
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
  padding: 12px 24px;
  width: 100%;
  background-color: var(--white-200);
  align-items: center;
  line-height: 28px;
  border-radius: 12px;
  color: var(--white-main);
  gap: 8px;
  font-size: 24px;

  @media (max-width: $mobile) {
    line-height: 24px;
  }

  &:hover {
    outline: 2px solid var(--green-main);
  }

  .light & {
    background-color: rgba(31, 33, 33, 0.06);
    color: rgba(31, 33, 33, 1);
  }
}
