@import "src/styles/variables";

.girl-adcard {
  //height: 100%;
  position: relative;
  transition: all var(--switch-theme-time);
  background-color: transparent;
  padding: 2px;
  //margin: -2px;
  border-radius: 24px;
  @media (max-width: $mobile) {
    width: 100%;
  }

  &.not_active {
    opacity: 40%;
  }

  &:hover {
    background-color: var(--color-500);
  }

  &__wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    font-weight: 500;
    color: var(--color-main);

    a {
      text-decoration: none;
    }
  }

  .checkbox {
    position: absolute;
    left: 20px;
    top: 20px;
    z-index: 2;
    width: 24px;
    height: 24px;
    display: inline-block;
    @media (max-width: $mobile) {
      width: 20px;
      height: 20px;
    }
    input {
      position: relative;
      width: 100%;
      height: 100%;
      transform: unset;
      left: unset;
      top: unset;
      border-color: var(--white-500);
      &:checked {
        background-color: var(--color-main);
        border-color: var(--color-main);
        &:after {
          background-color: var(--color-main-reverse);
        }
      }
      &:after {
        width: 14px;
        height: 14px;
      }
    }
  }

  &__top {
    display: flex;
    gap: 12px;
    @media (max-width: $mobile) {
      gap: 8px;
    }
    @media (min-width: $mobile) {
      .girl-adcard__group {
        display: none;
      }
    }
  }

  &__slider {
    aspect-ratio: 3/4;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    background-color: var(--color-main-reverse);

    &-wrapper {
      position: relative;
      min-width: 156px;
      height: fit-content;
      width: 100%;
      @media (max-width: $mobile) {
        .girl-adcard__tags-wrapper {
          display: none;
        }
      }
    }

    .swiper-slide {
      width: 100% !important;
    }
  }

  &__favorite {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 24px;
    height: 24px;
    z-index: 2;
    i:not(.icon--favorite-fill):after {
      background-color: var(--white-main);
    }
    @media (max-width: $mobile) {
      width: 20px;
      height: 20px;
      top: 14px;
      right: 14px;
    }
  }

  &__edit {
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;

    background-color: var(--white-400);
    color: var(--white-main);

    i:after {
      background-color: var(--white-main);
    }

    &:hover,
    &:focus-within {
      background-color: #1f1f1f;
    }
  }

  &__edit--mobile {
    position: absolute;
    top: 85%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;

    background-color: var(--white-400);
    color: var(--white-main);

    i:after {
      background-color: var(--white-main);
    }

    &:hover,
    &:focus-within {
      background-color: #1f1f1f;
    }
  }

  &__slide {
    cursor: pointer;

    &._hover,
    &:hover,
    &:focus-within {
      img {
        transform: scale(1.1);
      }
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      transition: all 0.3s ease-in-out 0s;
      transform: scale(1);
      @media (max-width: $mobile) {
        position: absolute;
      }
    }

    &:before,
    &:after {
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      z-index: 1;
    }

    &:before {
      top: -36px;
      height: 100px;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.9) 20%,
        rgba(0, 0, 0, 0) 100%
      );
    }

    &:after {
      bottom: -1px;
      background: linear-gradient(
        0deg,
        rgba(31, 31, 31, 1) 0%,
        rgba(31, 31, 31, 0.9) 20%,
        rgba(31, 31, 31, 0) 100%
      );
      height: 140px;
      transition: all var(--switch-theme-time);

      .light & {
        background: linear-gradient(
          0deg,
          rgba(237, 237, 237, 1) 0%,
          rgba(237, 237, 237, 0.9) 20%,
          rgba(237, 237, 237, 0) 100%
        );
        height: 160px;
      }
    }

    &:last-of-type {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: unset;
      background: url("/assets/img/girl-card-bg--dark.webp") no-repeat center
        center/ cover;
      .light & {
        background: url("/assets/img/girl-card-bg--light.webp") no-repeat center
          center/ cover;
      }
    }
  }

  &__box {
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    width: 212px;
    padding: 16px;
    border: 1px solid var(--color-300);
    position: relative;
    z-index: 2;
    @media (max-width: $mobileSmall) {
      border: 0;
      padding: 0;
      width: 140px;
    }

    .button {
      justify-content: center;
    }
  }

  &__info-box {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
    @media (max-width: $mobileSmall) {
      gap: 2px;
    }

    &:not(:last-of-type) {
      margin-bottom: 12px;
      @media (max-width: $mobile) {
        margin-bottom: 8px;
      }
    }

    &:last-of-type {
      margin-bottom: 20px;
      @media (max-width: $mobile) {
        margin-bottom: 8px;
      }
    }

    span {
      font-size: 16px;
      font-weight: 500;
      @media (max-width: $mobileSmall) {
        font-size: 13px;
      }
    }
  }

  &__descr {
    position: absolute;
    padding: 0 12px 4px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: 8px;
    @media (max-width: $mobile) {
      padding: 0 8px 4px;
    }
    @media (max-width: $mobile) {
      .girl-adcard__info-wrapper {
        display: none;
      }
    }
  }

  &__info {
    @media (max-width: $tablet) {
      margin-right: 4px;
    }

    &-wrapper {
      display: flex;
      justify-content: space-between;
      @media (max-width: $mobile) {
        justify-content: unset;
        gap: 8px;
      }
    }

    a,
    span {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      word-break: break-all;

      i {
        vertical-align: text-top;
      }
    }

    a {
      &:hover,
      &:focus-within {
        text-decoration: underline;
        text-decoration-thickness: 1px;
        text-underline-offset: 4px;
      }
    }
  }

  &__tags-wrapper {
    display: flex;
    justify-content: space-between;
    &:empty {
      display: none;
    }
  }

  &__icons-wrapper:empty {
    display: none;
  }
  &__tags,
  &__icons {
    display: flex;
    gap: 4px;
    height: 28px;
    @media (max-width: $mobile) {
      height: 20px;
    }

    span {
      line-height: 1;
      display: flex;
      gap: 8px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      cursor: help;
      background-color: var(--black-600);
      color: var(--white-main);
      min-width: 28px;
      .light & {
        background-color: var(--black-500);
      }
      @media (max-width: $mobile) {
        border-radius: 6px;
        min-width: 20px;
      }
    }
  }

  &__tags {
    span {
      padding: 8px;
      font-size: 10px;
      text-transform: uppercase;
      display: none;
      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(3),
      &:nth-of-type(4) {
        display: flex;
      }

      @media (max-width: $mobile) {
        padding: 4px;
        &:nth-of-type(4) {
          display: none;
        }
      }
    }
  }

  &__icon {
    span {
      padding: 2px;
    }
    i:after {
      background-color: var(--green-main);
    }
  }

  &__name {
    font-family: "TT Ramillas";
    font-size: 22px;
    font-weight: 300;
    line-height: 130%;

    &._hover {
      text-decoration: underline;
      text-decoration-thickness: 1px;
      text-underline-offset: 4px;
    }

    i {
      height: 14px;
      display: inline-block;

      &:after {
        width: 14px;
        height: 15px;
        margin-right: 8px;
        position: static;
        display: inline-block;
      }
    }
  }

  &__address {
    color: var(--color-700);
    padding-bottom: 2px;
    margin-bottom: -2px;

    i {
      width: 12px;
      height: 12px;
      margin: 0 6px 0 -1px;
    }
  }

  &__age {
    font-size: 18px;
    padding: 8px 9px 6px;
    display: flex;
    height: 100%;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    color: var(--color-main);
    background-color: var(--black-400);
    border-radius: 8px;

    span {
      font-size: 12px;
      color: var(--color-700);
    }
  }

  &__bottom {
    padding: 12px 20px 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: 100%;
    background-color: var(--black-light);
    overflow: hidden;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    @media (max-width: $pc) {
      padding: 12px 12px 20px;
    }

    .girl-adcard__info-wrapper {
      display: none;
    }

    @media (max-width: $mobile) {
      .girl-adcard__group,
      .girl-adcard__prices {
        display: none;
      }

      .girl-adcard__info-wrapper {
        display: flex;
      }
    }
  }

  &__group {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    @media (max-width: $mobile) {
      gap: 10px;

      .girl-adcard__modes {
        span,
        div {
          display: none;
          &:nth-of-type(1),
          &:nth-of-type(2),
          &:nth-of-type(3),
          &:nth-of-type(4) {
            display: flex;
          }
        }
      }
    }
  }

  &__params {
    display: flex;
    gap: 16px;
    @media (max-width: $mobile) {
      gap: 8px;
      flex-wrap: wrap;
    }

    div {
      display: flex;
      flex-direction: column;
      gap: 4px;
      font-size: 16px;
      white-space: nowrap;
      @media (max-width: $mobile) {
        font-size: 13px;
        div {
          gap: 2px;
        }
        span {
          font-size: 12px;
        }
      }
    }

    span {
      font-size: 14px;
      color: var(--color-600);
      font-weight: 500;
    }
  }

  &__modes {
    display: flex;
    gap: 4px;
    flex-wrap: wrap;

    div,
    span {
      border-radius: var(--border-radius-primary);
      height: 32px;
      @media (max-width: $mobile) {
        height: 28px;
        border-radius: 8px;
      }
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      background-color: var(--color-300);
      cursor: help;
      @media (max-width: $mobile) {
        width: 28px;
        i {
          width: 20px;
          height: 20px;
        }
      }
    }

    span {
      background-color: var(--color-400);
      padding: 8px;
      font-size: 13px;
      line-height: 1;
      display: flex;
      align-items: center;
      @media (max-width: $mobile) {
        font-size: 12px;
        padding: 6px;
      }
    }

    .icon-green {
      i:after {
        background-color: var(--green-main);
      }
    }
  }

  &__advantages {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: auto;
    &:empty {
      display: none;
    }
  }

  &__speak {
    display: flex;
    align-items: center;
    gap: 4px;
    height: 16px;
    margin-left: auto;

    span {
      display: inline-block;
      margin-right: 4px;
      color: var(--color-600);
    }

    div {
      height: inherit;
    }

    svg {
      width: 22px;
      height: 16px;
      cursor: help;
    }
  }

  &__prices {
    display: flex;
    gap: 20px;
    margin: auto 0 4px;
    &:empty {
      display: none;
    }
    @media (max-width: $pc) {
      gap: 18px;
    }
    @media (max-width: $mobile) {
      gap: 12px;
      margin: auto 0 -4px;
    }

    div {
      display: flex;
      flex-direction: column;
      gap: 8px;
      font-size: 22px;
      line-height: 120%;
      @media (max-width: $mobile) {
        font-size: 18px;
        gap: 2px;
      }
    }

    span {
      line-height: 110%;
      display: inline-block;
      color: var(--color-600);
      font-size: 14px;
      @media (max-width: $mobile) {
        font-size: 12px;
      }
    }

    .girl-adcard__price--accompaniment {
      @media (max-width: $mobile) {
        display: none;
      }
    }
  }

  &__buttons {
    display: flex;
    gap: 4px;

    a[href*="tel:"] {
      &:hover,
      &:focus-within {
        text-decoration: underline;
        text-decoration-thickness: 1px;
        text-underline-offset: 4px;
      }
    }

    .button {
      width: 100%;
      justify-content: center;
      order: -1;
    }
  }

  &--elite {
    background: linear-gradient(
      180deg,
      rgba(186, 161, 99, 1) 30%,
      rgba(186, 161, 99, 0) 94%
    );

    &:hover {
      background: linear-gradient(
        180deg,
        rgba(186, 161, 99, 1) 30%,
        rgba(186, 161, 99, 1) 94%
      );
    }

    .girl-adcard__slider-wrapper {
      &:after {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        width: 168px;
        height: 50px;
        background: url("/assets/img/status/elite.webp") no-repeat center
          center/ cover;
        content: "";

        @media (max-width: $mobile) {
          width: 102px;
          height: 30px;
          transform: translate(-50%, -75%);
          background: url("/assets/img/status/elite-mob.webp") no-repeat center
            center/ cover;
        }
      }
    }
  }

  &--top {
    background: linear-gradient(
      180deg,
      rgb(28, 161, 130, 1) 0%,
      rgba(28, 161, 130, 0) 94%
    );

    &:hover {
      background: var(--green-main);
    }

    .girl-adcard__wrapper {
      color: var(--white-main);
    }

    .girl-adcard__slider-wrapper {
      &:after {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        width: 169px;
        height: 59px;
        content: "";
        background: url("/assets/img/status/top.webp") no-repeat center center/
          contain;

        .light & {
          background: url("/assets/img/status/top-light.webp") no-repeat center
            center/ contain;
        }

        @media (max-width: $mobile) {
          width: 106px;
          height: 36px;
          transform: translate(-50%, -75%);
          background: url("/assets/img/status/top-mob.webp") no-repeat center
            center/ contain;

          .light & {
            background: url("/assets/img/status/top-mob-light.webp") no-repeat
              center center/ contain;
          }
        }
      }
    }

    .girl-adcard__group {
      .girl-adcard__icon,
      .girl-adcard__tag {
        i:after {
          background-color: var(--white-main);
        }
      }
    }

    .girl-adcard__age,
    .girl-adcard__age span {
      color: var(--white-main);
    }

    .girl-adcard__address {
      color: var(--white-main);
    }

    .girl-adcard__slide:after {
      background: linear-gradient(
        0deg,
        var(--green-main) 0%,
        var(--green-800) 20%,
        rgba(28, 161, 130, 0) 100%
      );
    }

    .girl-adcard__bottom {
      background-color: var(--green-main);
    }

    .girl-adcard__modes {
      div {
        background-color: var(--white-300);
        i:after {
          background-color: var(--white-main);
        }
      }

      span {
        background-color: var(--white-400);
      }
    }

    .girl-adcard__params,
    .girl-adcard__speak,
    .girl-adcard__prices {
      span {
        color: var(--white-600);
      }
    }

    .swiper-slide:last-of-type {
      background-color: var(--color-main-reverse);
    }

    .girl-adcard__info-box {
      color: var(--color-main);
    }

    .swiper-scrollbar__bar,
    .swiper-scrollbar span {
      background-color: var(--white-500);
    }

    .swiper-scrollbar-drag {
      background: var(--white-main);
    }

    .button-square {
      background-color: var(--white-main);
      color: var(--black-main);

      .light & {
        &:hover,
        &:focus-within {
          background-color: var(--white-700);
        }
      }
    }

    .button--green {
      background-color: var(--green-accent);

      &:hover,
      &:focus-within {
        opacity: 0.7;
      }
    }

    .button-square {
      background-color: var(--white-800);
      color: var(--black-main);
      i:after {
        background-color: var(--black-main);
      }
      &:hover,
      &:focus-within {
        background-color: var(--white-main);
      }
    }
  }

  &_size-s {
    border-radius: 16px;

    &.girl-adcard--top {
      &:hover {
        background-color: var(--green-main);
      }

      .girl-adcard__wrapper {
        background-color: var(--green-main);
      }

      .girl-adcard__tag {
        color: var(--white-main);
      }
    }

    .girl-adcard__wrapper {
      padding: 6px;
      margin: 0;
      gap: 10px;
      border-radius: 16px;
      background-color: var(--black-light);
      @media (max-width: $mobile) {
        gap: 8px;
      }
    }

    .girl-adcard__top {
      .girl-adcard__info-wrapper {
        display: none;
      }

      .girl-adcard__box {
        border: 0;
        padding: 0;
        @media (max-width: $mobileSmall) {
          width: 140px;
        }
      }

      .girl-adcard__info-box {
        gap: 2px;

        &:not(:last-of-type) {
          margin-bottom: 8px;
        }

        &:last-of-type {
          margin-bottom: 8px;
        }

        span {
          font-size: 13px;
        }

        i {
          width: 20px;
          height: 20px;
        }
      }

      .girl-adcard__group {
        display: flex;
      }

      .girl-adcard__prices {
        gap: 12px;
        margin: auto 0 -4px;

        > div {
          font-size: 18px;

          span {
            font-size: 13px;
          }
        }
      }
    }

    .girl-adcard__bottom {
      padding: 0;
      background-color: transparent;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      .girl-adcard__info-wrapper {
        display: flex;
        justify-content: unset;
        gap: 8px;
      }

      .girl-adcard__group,
      .girl-adcard__prices {
        display: none;
      }
    }

    .girl-adcard__slider-wrapper {
      &:after {
        transform: translate(-50%, -75%);
        width: 106px;
        height: 36px;
      }
    }

    .girl-adcard__slider {
      &-wrapper {
        @media (min-width: $mobile) {
          height: 208px;
          width: 156px;
        }

        .girl-adcard__tags-wrapper {
          display: none;
        }
      }

      width: 100%;
      border-radius: 12px;
      @media (max-width: $mobile) {
        border-radius: 12px;
      }
    }

    .girl-adcard__modes div,
    .girl-adcard__modes span {
      height: 28px;
      border-radius: 8px;
    }

    .girl-adcard__modes span {
      font-size: 12px;
      padding: 5px;
    }

    .girl-adcard__price--accompaniment {
      @media (max-width: $mobile) {
        display: none;
      }
    }

    .girl-adcard__descr {
      padding: 0 8px 4px;
    }

    .girl-adcard__favorite {
      width: 20px;
      height: 20px;
      top: 14px;
      right: 14px;
    }

    .girl-adcard__params div {
      font-size: 13px;
    }

    .girl-adcard__tags-wrapper {
      justify-content: unset;
      gap: 4px;
      flex-wrap: wrap;
      margin-bottom: 4px;
      height: 28px;
      overflow: hidden;
    }

    .girl-adcard__icon {
      color: var(--green-main);
    }
    .girl-adcard__tag,
    .girl-adcard__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 28px;
      font-size: 12px;
      background-color: var(--color-300);
      min-width: 28px;
      border-radius: 6px;
      line-height: 1;

      i {
        width: 20px;
        height: 20px;
      }
    }

    .girl-adcard__tag {
      padding: 4px;
      color: var(--color-main);
    }

    .girl-adcard__age {
      padding: 5px 8px 3px;
    }

    .girl-adcard__slide:before,
    .girl-adcard__slide:after {
      content: unset;
    }
  }
}
